import { request } from '@/utils/http'
import { ApiResponse, ApiRequestParams } from '@/utils/types'
import { ListingFaqItem } from '@/utils/typings/models'

export const ListingFaqItemInputs: string[] = [
  'listingId',
  'order',
  'title',
  'description',
]

class ListingFaqModel {
  item: ListingFaqItem = {} as any

  constructor(item?: Partial<ListingFaqItem>) {
    if (item) this.item = { ...this.item, ...item }
  }

  updateLocalItem(item: Partial<ListingFaqItem>) {
    this.item = { ...this.item, ...item }
  }

  prepareDataForSaving(): ApiRequestParams {
    const params: ApiRequestParams = {}
    ListingFaqItemInputs.forEach((key) => {
      const val = this.item[key as keyof ListingFaqItem]
      if (
        typeof val === 'string' ||
        typeof val === 'number' ||
        typeof val === 'boolean'
      )
        params[key] = val
    })
    return params
  }

  async save(): Promise<ListingFaqModel | null> {
    if (this.item?.id) {
      return this.update()
    }
    return this.create()
  }

  async create(): Promise<ListingFaqModel | null> {
    return ListingFaqModel.returnApiSingle(
      await request({
        url: `/api/listings/${this.item.listingId}/faq/create`,
        method: 'POST',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async update(): Promise<ListingFaqModel | null> {
    return ListingFaqModel.returnApiSingle(
      await request({
        url: `/api/listings/${this.item.listingId}/faq/${this.item.id}/update`,
        method: 'PUT',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async delete(): Promise<boolean> {
    const res = await request<boolean>({
      url: `/api/listings/${this.item.listingId}/faq/${this.item.id}/delete`,
      method: 'DELETE',
    })
    return !res.error
  }

  static returnApiSingle(
    res: ApiResponse<ListingFaqItem>
  ): ListingFaqModel | null {
    if (!res.error && res.data) {
      const item: ListingFaqItem = res.data
      return new ListingFaqModel(item)
    }
    return null
  }

  static returnApiMany(res: ApiResponse<ListingFaqItem[]>): ListingFaqModel[] {
    if (!res.error && res.data) {
      const listings: ListingFaqItem[] = res.data
      return listings.map((item: ListingFaqItem) => new ListingFaqModel(item))
    }
    return []
  }

  static async getListingFaq(listingId: string): Promise<ListingFaqModel[]> {
    return ListingFaqModel.returnApiMany(
      await request({
        url: `/api/listings/${listingId}/faq/list`,
        method: 'GET',
        params: {},
      })
    )
  }

  static async getListingFaqRaq(listingId: string): Promise<ListingFaqItem[]> {
    const res = await request<ListingFaqItem[]>({
      url: `/api/listings/${listingId}/faq/list`,
      method: 'GET',
      params: {},
    })
    if (res.data) return res.data
    return []
  }

  static async getListingFaqItem(
    listingId: string,
    listingFaqId: string
  ): Promise<ListingFaqModel | null> {
    return ListingFaqModel.returnApiSingle(
      await request({
        url: `/api/listings/${listingId}/faq/${listingFaqId}`,
        method: 'GET',
        params: {},
      })
    )
  }
}
export { ListingFaqModel }
