import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

type Props = {
  icon: IconDefinition
  className?: string
  containerBackground?: string
  containerRounded?: string
  containerPadding?: string
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const AwesomeIcon = ({
  icon,
  className = 'text-gray-300',
  containerBackground,
  containerRounded = 'rounded-lg',
  containerPadding = 'p-3',
}: Props) => {
  if (containerBackground) {
    return (
      <span
        className={classNames(
          'text-teal-700',
          containerBackground,
          containerRounded,
          containerPadding,
          'inline-flex focus:outline-none focus:ring-0'
        )}
      >
        <FontAwesomeIcon icon={icon} className={className} aria-hidden />
      </span>
    )
  } else {
    return <FontAwesomeIcon icon={icon} className={className} aria-hidden />
  }
}

export { AwesomeIcon }
