import { useEffect, useState } from 'react'
import type { AppProps } from 'next/app'
import Layout from '@/components/layout/Layout'
import { Notification } from '@/components/ui'
import { ConnectWallet } from '@/components/web3'
import { SessionProvider, AppProvider } from '@/context'
import { supabase } from '@/utils/supabaseClient'
import { SessionProvider as NextAuthSessionProvider } from 'next-auth/react'
import '../styles/globals.css'

const MyApp = ({ Component, pageProps }: AppProps) => {
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(false)
  }, [])

  return (
    <AppProvider>
      <SessionProvider supabaseClient={supabase}>
        <NextAuthSessionProvider basePath="/api/auth/providers">
          {loading ? (
            <></>
          ) : (
            <>
              <Layout>
                <Component {...pageProps} />
              </Layout>
              <Notification />
              <ConnectWallet />
            </>
          )}
        </NextAuthSessionProvider>
      </SessionProvider>
    </AppProvider>
  )
}

export default MyApp
