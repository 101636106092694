import Head from 'next/head'
import { useState, useEffect } from 'react'
import { PageMetaProps } from '@/utils/typings/app'

const Meta = ({
  title = 'Floor3',
  keywords = 'crypto,blockchain,nft',
  description = 'A better way to invest in the brands you love.',
  ogImageUrl = '',
}: PageMetaProps) => {
  const [cleanedTitle, setCleanedTitle] = useState<string>('')

  useEffect(() => {
    if (title === 'Floor3') setCleanedTitle(title)
    else setCleanedTitle(`${title} | Floor3`)
  }, [title])

  return (
    <Head>
      <meta charSet="utf8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>{cleanedTitle}</title>
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />

      <meta property="og:title" content={cleanedTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImageUrl} />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
    </Head>
  )
}

export default Meta
