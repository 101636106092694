import { useState, useRef } from 'react'
import { useAsyncEffect } from 'use-async-effect'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { supabase } from '@/utils/supabaseClient'
import { concatFolderFilename } from '@/utils/image'
import { useApp } from '@/context'
import { Button, AwesomeIcon, Tooltip } from '@/components/ui'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

type ImageInputProps = {
  label?: string
  helpText?: string
  helpTextPosition?: 'LABEL_RIGHT' | 'BOTTOM'
  bucket: string
  folder?: string
  url?: string
  size?: { width: number; height: number } | null
  buttonClassName?: string
  mode?: 'normal' | 'overlay' | 'image-button' | 'button-only'
  placeholderIcon?: IconDefinition
  buttonText?: string
  buttonIcon?: IconDefinition
  buttonIconClassName?: string
  buttonSize?: 'XS' | 'SM' | 'MD' | 'LG' | 'XL'
  buttonStyle?:
    | 'PRIMARY'
    | 'SECONDARY'
    | 'WHITE'
    | 'WHITE_MUTED'
    | 'DANGER'
    | 'OVERLAY'
    | 'TEXT'
  autoUpload?: boolean
  onChange?: (file: File) => void
  onUpload?: (filePath: string) => void
  onUploadStarted?: () => void
  onUploadCompleted?: () => void
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const ImageInput = ({
  label,
  helpText,
  helpTextPosition = 'LABEL_RIGHT',
  bucket,
  folder = '',
  url = '',
  size = { width: 12, height: 12 },
  buttonClassName = 'rounded-full',
  mode = 'normal',
  placeholderIcon = regular('image'),
  buttonText,
  buttonIcon,
  buttonIconClassName,
  buttonSize,
  buttonStyle,
  autoUpload = true,
  onChange,
  onUpload,
  onUploadStarted,
  onUploadCompleted,
}: ImageInputProps) => {
  const { showError } = useApp()
  const [imageUrl, setImageUrl] = useState<string>('')
  const [originalImageUrl, setOriginalImageUrl] = useState<string>('')
  const [uploading, setUploading] = useState<boolean>(false)
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    if (hiddenFileInput.current !== null) {
      if (onUploadStarted) onUploadStarted()
      hiddenFileInput.current.click()
    }
  }

  const downloadImage = async (path: string) => {
    if (path && (path.includes('https://') || path.includes('http://'))) {
      setImageUrl(path)
    } else {
      try {
        const { data, error } = await supabase.storage
          .from(bucket)
          .download(concatFolderFilename(folder, path))
        if (error) {
          throw error
        }
        const u = URL.createObjectURL(data as Blob)
        setImageUrl(u)
      } catch (error) {
        console.log('Error downloading image:', error)
      }
    }
  }

  const uploadImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      throw new Error('You must select an image to upload.')
    }

    const file = event.target.files[0]
    const fileExt = file.name.split('.').pop()
    const fileName = `${Math.random()}.${fileExt}`

    if (autoUpload) {
      try {
        setUploading(true)
        if (onUploadStarted) onUploadStarted()

        // Are we replacing the image? If so we can delete the old one
        if (originalImageUrl) {
          await supabase.storage
            .from(bucket)
            .remove([concatFolderFilename(folder, originalImageUrl)])
        }

        const { error } = await supabase.storage
          .from(bucket)
          .upload(concatFolderFilename(folder, fileName), file)

        if (error) {
          throw error
        }
        await downloadImage(fileName)
        if (onUpload) onUpload(fileName)
      } catch (error: any) {
        if (error instanceof Error) {
          showError({
            title: 'Error uploading image',
            description: error.message
              ? error.message
              : 'There was a problem uploading this image. Please try again or contact support.',
          })
        }
        console.error(error)
      } finally {
        setUploading(false)
        if (onUploadCompleted) onUploadCompleted()
      }
    } else {
      const previewUrl = URL.createObjectURL(file)
      setImageUrl(previewUrl)
    }

    if (onChange) onChange(file)
  }

  useAsyncEffect(async () => {
    if (url) {
      await downloadImage(url)
      if (!originalImageUrl) setOriginalImageUrl(url)
    }
  }, [url])

  return (
    <div>
      <label
        htmlFor={hiddenFileInput.current?.id ? hiddenFileInput.current.id : ''}
        className="block text-sm font-medium text-gray-900 flex items-center"
      >
        {label ||
          (helpText && helpTextPosition === 'LABEL_RIGHT' && (
            <>
              <span className="flex-1 flex items-center">{label}</span>

              {helpText && (
                <Tooltip text={helpText} position="left" className="">
                  <AwesomeIcon
                    icon={solid('circle-info')}
                    className="text-indigo-600"
                  />
                </Tooltip>
              )}
            </>
          ))}
      </label>
      <div
        className={classNames(
          'relative group h-full',
          label || helpText ? 'mt-1' : '',
          size && mode !== 'overlay' ? 'flex items-center' : ''
        )}
      >
        {mode !== 'button-only' && (
          <div
            className={classNames(
              'overflow-hidden h-full flex items-center justify-center align-middle bg-indigo-25 hover:bg-indigo-50',
              size && mode !== 'overlay'
                ? `h-${size.height} w-${size.width}`
                : 'aspect-w-4 aspect-h-2',
              buttonClassName
            )}
          >
            {imageUrl && mode !== 'image-button' ? (
              <img
                src={imageUrl}
                className={classNames(
                  'inline-block object-center object-cover',
                  size && mode !== 'overlay' ? `w-${size.width}` : ''
                )}
                alt={label}
              />
            ) : (
              <>
                {placeholderIcon && (
                  <div className="flex items-center justify-center">
                    <AwesomeIcon
                      icon={placeholderIcon}
                      className="text-indigo-200 h-6"
                    />
                  </div>
                )}
              </>
            )}

            {mode === 'overlay' ? (
              <>
                <Button
                  className="w-full"
                  text={
                    uploading
                      ? 'Uploading ...'
                      : imageUrl
                      ? 'Change'
                      : 'Choose Image'
                  }
                  size="MD"
                  style="OVERLAY"
                  forceShowButton={uploading}
                  onClick={handleClick}
                />
              </>
            ) : mode === 'image-button' ? (
              <>
                <button
                  type="button"
                  className="absolute inset-0 focus:outline-none text-xs grid gap-1 justify-items-center place-content-center"
                  onClick={handleClick}
                >
                  {buttonIcon && (
                    <AwesomeIcon
                      icon={buttonIcon}
                      className="h-4 w-4 text-gray-600"
                    />
                  )}
                  <span className="text-gray-600 font-medium">
                    {buttonText}
                  </span>
                </button>
              </>
            ) : (
              <></>
            )}
          </div>
        )}

        {helpText && helpTextPosition === 'BOTTOM' && (
          <div className="flex items-center justify-left mt-2">
            <AwesomeIcon
              icon={solid('circle-info')}
              className="text-gray-300 h-3"
            />
            <span className="ml-1 text-xs text-gray-300">{helpText}</span>
          </div>
        )}
        <input
          style={{
            visibility: 'hidden',
            position: 'absolute',
            width: '20px',
          }}
          type="file"
          ref={hiddenFileInput}
          accept="image/*"
          onChange={uploadImage}
          disabled={uploading}
        />
        {mode === 'normal' ? (
          <Button
            className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-indigo-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            text={uploading ? 'Uploading ...' : 'Change'}
            size="MD"
            style="WHITE"
            onClick={handleClick}
          />
        ) : mode === 'button-only' ? (
          <Button
            text={uploading && buttonText ? 'Uploading ...' : buttonText}
            showloadingIcon={uploading}
            icon={uploading ? undefined : buttonIcon}
            iconClassName={buttonIconClassName}
            size={buttonSize ? buttonSize : 'MD'}
            style={buttonStyle ? buttonStyle : 'WHITE'}
            onClick={handleClick}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
export { ImageInput }
