import { createRef, useEffect, useState } from 'react'

type TooltipProps = {
  children: React.ReactNode
  text: string
  position?: 'top' | 'right' | 'bottom' | 'left'
  className?: string
}

const defaultContainerStyle = {
  opacity: 0,
  top: '',
  right: '',
  bottom: '',
  left: '',
}

const defaultTipStyle = {
  transform: 'rotate(45deg)',
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const Tooltip = ({
  children,
  text,
  position = 'top',
  className = '',
}: TooltipProps) => {
  const tipRef = createRef<HTMLInputElement>()
  const [containerStyle, setContainerStyle] = useState(defaultContainerStyle)
  const [tipStyle, setTipStyle] = useState(defaultTipStyle)

  useEffect(() => {
    const initStyle = () => {
      if (position === 'top') {
        setContainerStyle({ ...defaultContainerStyle, ...{ bottom: '100%' } })
        setTipStyle({ ...defaultTipStyle, ...{ bottom: '-6px' } })
      } else if (position === 'right') {
        setContainerStyle({ ...defaultContainerStyle, ...{ left: '100%' } })
        setTipStyle({ ...defaultTipStyle, ...{ left: '-6px' } })
      } else if (position === 'bottom') {
        setContainerStyle({ ...defaultContainerStyle, ...{ top: '100%' } })
        setTipStyle({ ...defaultTipStyle, ...{ top: '-6px' } })
      } else if (position === 'left') {
        setContainerStyle({ ...defaultContainerStyle, ...{ right: '100%' } })
        setTipStyle({ ...defaultTipStyle, ...{ right: '-6px' } })
      }
    }
    initStyle()
  }, [position])

  const handleMouseEnter = () => {
    if (tipRef.current) {
      tipRef.current.style.display = ''

      setTimeout(() => {
        if (tipRef.current) {
          tipRef.current.style.opacity = '0.9'
          if (position === 'top') tipRef.current.style.marginBottom = '14px'
          else if (position === 'right')
            tipRef.current.style.marginLeft = '14px'
          else if (position === 'bottom')
            tipRef.current.style.marginTop = '14px'
          else if (position === 'left')
            tipRef.current.style.marginRight = '14px'
        }
      }, 50)
    }
  }

  const handleMouseLeave = () => {
    if (tipRef.current) {
      tipRef.current.style.opacity = '0'
      if (position === 'top') tipRef.current.style.marginBottom = '4px'
      else if (position === 'right') tipRef.current.style.marginLeft = '4px'
      else if (position === 'bottom') tipRef.current.style.marginTop = '4px'
      else if (position === 'left') tipRef.current.style.marginRight = '4px'

      setTimeout(() => {
        if (tipRef.current) {
          tipRef.current.style.display = 'none'
        }
      }, 150)
    }
  }

  return (
    <div
      className={classNames('relative flex items-center z-30', className)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className="absolute flex min-w-[120px] w-auto items-center bg-indigo-700 opacity-90 text-white text-xs px-3 py-2 rounded-lg transition-all duration-150"
        style={containerStyle}
        ref={tipRef}
      >
        <div className="bg-indigo-700 h-3 w-3 absolute" style={tipStyle} />
        {text}
      </div>
      {children}
    </div>
  )
}
export { Tooltip }
