import { Fragment, forwardRef, useImperativeHandle, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button } from './Button'

type ConfirmModalProps = {
  title?: string
  description?: string
  buttonStyle?: 'PRIMARY' | 'SECONDARY' | 'WHITE' | 'DANGER'
  onCancel?: () => void
  onConfirm?: (props: any) => void // eslint-disable-line @typescript-eslint/no-explicit-any
}

export type ConfirmModalRef = {
  showModal: (props: any) => void // eslint-disable-line @typescript-eslint/no-explicit-any
  closeModal: () => void
}

const ConfirmModal = forwardRef<ConfirmModalRef, ConfirmModalProps>(
  (props, ref) => {
    const [open, setOpen] = useState<boolean>(false)
    const [callerProps, setCallerProps] = useState({})
    const {
      title = 'Are you sure?',
      description = 'To proceed, please press the button below.',
      buttonStyle = 'PRIMARY',
      onCancel,
      onConfirm,
    } = props

    useImperativeHandle(ref, () => ({
      showModal(_props) {
        setCallerProps(_props) // eslint-disable-line @typescript-eslint/no-unsafe-argument
        setOpen(true)
      },
      closeModal() {
        setCallerProps({})
        setOpen(false)
      },
    }))

    const onNoClicked = () => {
      setOpen(false)
      if (onCancel) onCancel()
    }

    const onYesClicked = () => {
      setOpen(false)
      if (onConfirm) onConfirm(callerProps)
    }

    return (
      <>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-50 inset-0 overflow-y-auto"
            onClose={setOpen}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        {title}
                      </Dialog.Title>
                      {description && (
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">{description}</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <Button
                      text="No"
                      size="MD"
                      style="WHITE"
                      className="w-full justify-center"
                      onClick={() => onNoClicked()}
                    />
                    <Button
                      text="Yes"
                      size="MD"
                      style={buttonStyle}
                      className="w-full justify-center"
                      onClick={() => onYesClicked()}
                    />
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    )
  }
)
export { ConfirmModal }
