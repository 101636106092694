type DividerProps = {
  children: React.ReactNode
  contentRight?: React.ReactNode
  index: number
  totalRecords: number
  customPadding?: boolean
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const ListItem = ({
  children,
  contentRight,
  index,
  totalRecords,
  customPadding,
}: DividerProps) => {
  return (
    <li
      className={classNames(
        'block hover:bg-indigo-10',
        !customPadding ? 'px-4 py-4 sm:px-5' : '',
        index === 0
          ? 'sm:rounded-t-md'
          : index === totalRecords - 1
          ? 'sm:rounded-b-md'
          : ''
      )}
    >
      <div className="relative flex items-center">
        <div className="min-w-0 space-x-3 flex-1 flex items-center">
          {children}
        </div>
        {contentRight && <div>{contentRight}</div>}
      </div>
    </li>
  )
}

export { ListItem }
