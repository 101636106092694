import { request } from '@/utils/http'
import { ethers } from 'ethers'
import { VoucherMeta, NFTVoucher, getLazyMinter } from '@/utils/web3/lazyMinter'
import { ApiResponse, ApiRequestParams } from '@/utils/types'
import { getWallet } from '@/utils/web3/wallet'
import dayjs from 'dayjs'
import { NftItem } from '@/utils/typings/models'
import { NFTDiscountLogModel } from './NFTDiscountLogModel'

export const NftItemInputs: string[] = [
  'tokenId',
  'price',
  'forSale',
  'soldAt',
  'soldFor',
  'owner',
  'checkedOutBy',
  'checkedOutAt',
  'purchaseProcessingAt',
  'blockHash',
  'txId',
]

class NFTModel {
  item: NftItem = {} as NftItem

  constructor(item?: Partial<NftItem>) {
    if (item) this.item = { ...this.item, ...item }
  }

  public get voucherMeta() {
    const meta: VoucherMeta = {
      floor3Id: this.item.id,
      uri: this.metaURL,
    }
    return meta
  }

  public get metaURL() {
    return `${process.env.NEXT_PUBLIC_HTTP_PROTOCOL}${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/nfts/${this.item.id}/meta`
  }

  public get nftImageURL() {
    return `${process.env.NEXT_PUBLIC_SUPABASE_PUBLIC_STORAGE_URL}/nfts/${this.item.id}/${this.item.nftImage}`
  }

  public get etherscanDomain() {
    if (process.env.NODE_ENV === 'production') return 'etherscan.io'
    else return 'ropsten.etherscan.io'
  }

  public get relativeURL() {
    if (this.item && this.item.listing) {
      return `/${this.item.listing.brand.slug}/${this.item.listing.slug}/${this.item.id}`
    }
    return ''
  }

  public get absoluteURL() {
    return `${process.env.NEXT_PUBLIC_HTTP_PROTOCOL}${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/${this.item.listing.brand.slug}/${this.item.listing.slug}/${this.item.id}`
  }

  public get timeUntilReservedCheckoutEnds(): number {
    // Returns in milliseconds
    if (this.item.checkedOutAt) {
      const checkedOutAt =
        typeof this.item.checkedOutAt === 'string'
          ? new Date(this.item.checkedOutAt)
          : this.item.checkedOutAt
      const tenMinsAgo = dayjs()
        .subtract(Number(process.env.PURCHASE_RESERVE_WINDOW), 'minute')
        .toDate()
        .getTime()
      return checkedOutAt.getTime() - tenMinsAgo
    }
    return 0
  }

  public get voucherRaw(): NFTVoucher {
    if (this.item && this.item.price && this.item.tokenId) {
      const price = ethers.utils
        .parseUnits(this.item.price.toString(), 'ether')
        .toString()
      return {
        tokenId: this.item.tokenId,
        listingId: this.item.listingId,
        nftId: this.item.id,
        minPrice: price,
      }
    }
    return { tokenId: '', listingId: '', nftId: '', minPrice: null }
  }

  async createTokenVoucherSignature(listingPrice: number): Promise<string> {
    try {
      const { signer } = await getWallet()
      if (!signer) throw new Error("Signer doesn't exist")
      const lazyMinter = getLazyMinter(signer)

      // Create the raw voucher
      const _voucherRaw = this.voucherRaw

      // Override the voucher with the price it WILL be selling for
      _voucherRaw.minPrice = ethers.utils
        .parseUnits(listingPrice.toString(), 'ether')
        .toString()

      // Now create the voucher and ask the user to sign it
      const lazyVoucher = await lazyMinter.createNFTVoucher(_voucherRaw)
      return lazyVoucher.signature
    } catch (err) {
      console.error(err)
      return ''
    }
  }

  // Analytics
  async analyticsViewsLog(): Promise<{ views: number }> {
    const res = await request<{ views: number }>({
      url: `/api/analytics/views/log`,
      method: 'POST',
      params: { entity: 'nft', entityId: this.item.id },
    })
    if (res.data) return res.data
    return { views: 0 }
  }
  async analyticsViewsStatus(): Promise<{ views: number }> {
    const res = await request<{ views: number }>({
      url: `/api/analytics/views/status`,
      method: 'GET',
      params: { entity: 'nft', entityId: this.item.id },
    })
    if (res.data) return res.data
    return { views: 0 }
  }

  updateLocalItem(item: Partial<NftItem>) {
    this.item = { ...this.item, ...item }
  }

  prepareDataForSaving(): ApiRequestParams {
    const params: ApiRequestParams = {}
    NftItemInputs.forEach((key) => {
      const val = this.item[key as keyof NftItem]
      if (
        typeof val === 'string' ||
        typeof val === 'number' ||
        typeof val === 'boolean'
      )
        params[key] = val
    })
    return params
  }

  async save(): Promise<NFTModel | null> {
    if (this.item?.id) {
      return this.update()
    }
    return this.create()
  }

  async create(): Promise<NFTModel | null> {
    return NFTModel.returnApiSingle(
      await request({
        url: '/api/nfts/create',
        method: 'POST',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async update(): Promise<NFTModel | null> {
    return NFTModel.returnApiSingle(
      await request({
        url: `/api/nfts/${this.item.id}/update`,
        method: 'PUT',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async completePurchase(
    blockHash: string,
    txId: string
  ): Promise<NFTModel | null> {
    // Reserves the NFT passed for the wallet currently active
    return NFTModel.returnApiSingle(
      await request<NftItem>({
        url: `/api/nfts/${this.item.id}/complete-purchase`,
        method: 'POST',
        params: { blockHash, txId },
      })
    )
  }

  async redeemDiscount(
    discountId: string,
    redeemEmail: string
  ): Promise<NFTDiscountLogModel | null> {
    return NFTDiscountLogModel.returnApiSingle(
      await request({
        url: `/api/nfts/${this.item.id}/redeem-discount`,
        method: 'POST',
        params: { discountId, redeemEmail },
      })
    )
  }

  static returnApiSingle(res: ApiResponse<NftItem>): NFTModel | null {
    if (!res.error && res.data) {
      const item: NftItem = res.data
      return new NFTModel(item)
    }
    return null
  }

  static returnApiMany(res: ApiResponse<NftItem[]>): NFTModel[] {
    if (!res.error && res.data) {
      const items: NftItem[] = res.data
      return items.map((item: NftItem) => new NFTModel(item))
    }
    return []
  }

  static async getPurchasedNFTs(owner: string): Promise<NFTModel[]> {
    return NFTModel.returnApiMany(
      await request({
        url: '/api/nfts/list',
        method: 'GET',
        params: { owner },
      })
    )
  }

  static async getNFTsForSale(listingId: string): Promise<NFTModel[]> {
    return NFTModel.returnApiMany(
      await request({
        url: '/api/nfts/list',
        method: 'GET',
        params: { listingId, minted: true },
      })
    )
  }

  static async getNFT(
    nftId: string,
    listingId?: string
  ): Promise<NFTModel | null> {
    return this.returnApiSingle(
      await request<NftItem>({
        url: `/api/nfts/${nftId}`,
        method: 'GET',
        params: { listingId: listingId ? listingId : '' },
      })
    )
  }

  static async getNFTRaw(
    nftId: string,
    listingId?: string
  ): Promise<NftItem | null> {
    const res = await request<NftItem>({
      url: `/api/nfts/${nftId}`,
      method: 'GET',
      params: { listingId: listingId ? listingId : '' },
    })
    if (!res.error && res.data) {
      return res.data
    }
    return null
  }

  static async reserveNftForCheckout(nft: NFTModel): Promise<NFTModel | null> {
    // Reserves the NFT passed for the wallet currently active
    return NFTModel.returnApiSingle(
      await request<NftItem>({
        url: `/api/nfts/${nft.item.id}/reserve`,
        method: 'POST',
        params: {},
      })
    )
  }
}
export { NFTModel }
