import { useState, useEffect, useCallback } from 'react'
import { RadioGroup } from '@headlessui/react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { useFormContext, get, Controller } from 'react-hook-form'
import { AwesomeIcon, HeroIcon } from '@/components/ui'
import {
  FieldErrors,
  FieldError,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form/dist/types'

type RadioChoiceType = {
  id: any
  title?: string
  description?: string
  footerText?: string
  imageUrl?: string
  icon?: IconDefinition
  iconClass?: string
}

interface RadioCardsProps<T extends FieldValues> {
  name: Path<T>
  label: PathValue<T, Path<T>>
  choices: RadioChoiceType[]
  style?: 'columns' | 'full-width'
  columns?: number
  value?: any
  control: any
  errors: FieldErrors<T>
  required?: boolean
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const RadioCards = <T extends FieldValues>({
  name,
  label,
  choices,
  style = 'columns',
  columns = 2,
  value,
  control,
  errors,
  required,
}: RadioCardsProps<T>) => {
  const [selected, setSelected] = useState<RadioChoiceType | null>()
  const [fieldErrorMessage, setfieldErrorMessage] = useState<string>('')
  const methods = useFormContext()
  const error: FieldError = get(errors || methods.formState.errors, name)

  const checkError = useCallback(() => {
    switch (error?.type) {
      case 'required':
        setfieldErrorMessage('Please select one of these options')
        break
      default:
        if (error?.message) setfieldErrorMessage(error?.message)
        else {
          setfieldErrorMessage('')
        }
        break
    }
  }, [error])

  useEffect(() => {
    if (
      (!selected && choices) ||
      (selected && value && value !== selected?.id)
    ) {
      const selectedItem = choices.find((obj) => {
        return obj.id === value
      })
      if (selectedItem) setSelected(selectedItem)
    } else if (!value && selected) {
      setSelected(null)
    }
  }, [value, selected, choices])

  useEffect(() => {
    checkError()
  }, [error, checkError])

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={value}
        rules={{ required: required }}
        render={({ field: { onChange } }) => (
          <RadioGroup
            value={selected}
            onChange={(e) => {
              onChange(e?.id)
              setSelected(e)
            }}
          >
            <RadioGroup.Label className="text-sm font-medium text-gray-900 flex-1">
              {label}
            </RadioGroup.Label>

            <div
              className={classNames(
                'mt-3',
                style === 'full-width'
                  ? 'space-y-4'
                  : 'grid grid-cols-1 gap-y-6 sm:gap-x-4',
                columns === 3 ? 'sm:grid-cols-3' : 'sm:grid-cols-2'
              )}
            >
              {choices.map((choice) => (
                <RadioGroup.Option
                  key={choice.id}
                  value={choice}
                  className={({ active }) =>
                    classNames(
                      fieldErrorMessage
                        ? 'border-red-300'
                        : choice.id === selected?.id
                        ? 'border-transparent'
                        : 'border-gray-300',
                      active ? 'ring-1 ring-indigo-500' : '',
                      'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none hover:shadow hover:border-indigo-400'
                    )
                  }
                >
                  {({ active }) => (
                    <>
                      <div className="flex-1 flex">
                        <div
                          className={`flex items-center  ${
                            choice.imageUrl
                              ? 'py-2 justify-center content-center'
                              : ''
                          }`}
                        >
                          {choice.imageUrl && (
                            <img
                              src={choice.imageUrl}
                              className="max-h-8"
                              alt={choice.title}
                            />
                          )}

                          {choice.icon && (
                            <AwesomeIcon
                              icon={choice.icon}
                              className={classNames(
                                'mr-4',
                                choice.iconClass ? choice.iconClass : ''
                              )}
                            />
                          )}

                          <div>
                            {choice.title && (
                              <RadioGroup.Label
                                as="span"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {choice.title}
                              </RadioGroup.Label>
                            )}

                            {choice.description && (
                              <RadioGroup.Description
                                as="span"
                                className="mt-1 flex items-center text-xs text-gray-500"
                              >
                                {choice.description}
                              </RadioGroup.Description>
                            )}

                            {choice.footerText && (
                              <RadioGroup.Description
                                as="span"
                                className="mt-6 text-xs font-medium text-gray-900"
                              >
                                {choice.footerText}
                              </RadioGroup.Description>
                            )}
                          </div>
                        </div>
                      </div>
                      {fieldErrorMessage ? (
                        <HeroIcon
                          name="ExclamationCircleIcon"
                          className="h-5 w-5 text-red-500 absolute right-1 top-1"
                        />
                      ) : (
                        <HeroIcon
                          name="CheckCircleIcon"
                          className={classNames(
                            choice.id !== selected?.id ? 'invisible' : '',
                            'h-5 w-5 text-indigo-600 absolute right-1 top-1'
                          )}
                        />
                      )}

                      <div
                        className={classNames(
                          active ? 'border' : 'border-2',
                          choice.id === selected?.id
                            ? 'border-indigo-500'
                            : 'border-transparent',
                          'absolute -inset-px rounded-lg pointer-events-none'
                        )}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
            {fieldErrorMessage && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {fieldErrorMessage}
              </p>
            )}
          </RadioGroup>
        )}
      />
    </>
  )
}

export { RadioCards }
