import { Fragment, useState, useEffect, useCallback } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { useFormContext, get, Controller } from 'react-hook-form'
import {
  FieldErrors,
  FieldValues,
  FieldError,
  Path,
  PathValue,
} from 'react-hook-form/dist/types'
import {
  CheckIcon,
  SelectorIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/solid'
import { Tooltip, HeroIcon } from '@/components/ui'

export type SelectChoiceType = {
  id: string | number
  name: string
  avatarUrl?: string
  avatarUrlStyle?: 'rounded' | 'rounded-md' | 'rounded-lg' | 'rounded-full'
}

interface SelectInputProps<T extends FieldValues> {
  name: Path<T>
  label: string
  choices: SelectChoiceType[]
  helpText?: string
  className?: string
  size?: 'md' | 'sm'
  style?: 'normal' | 'muted'
  value?: PathValue<T, Path<T>>
  control?: any
  errors?: FieldErrors<T>
  required?: boolean
  onValueChange?: (item: SelectChoiceType) => void
}

const INPUT_CLASS_NORMAL =
  'border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
const INPUT_CLASS_ERROR =
  'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const SelectInput = <T extends FieldValues>({
  name,
  label,
  size = 'md',
  style = 'normal',
  className = '',
  choices = [],
  helpText = '',
  value,
  control,
  errors,
  required,
  onValueChange,
}: SelectInputProps<T>) => {
  const [fieldErrorMessage, setfieldErrorMessage] = useState<string>('')
  const [inputClass, setInputClass] = useState<string>(INPUT_CLASS_NORMAL)
  const [selected, setSelected] = useState<SelectChoiceType>()
  const methods = useFormContext()
  const error: FieldError | null =
    errors !== undefined ? get(errors || methods.formState.errors, name) : null
  const [query, setQuery] = useState('')

  const filteredChoices =
    query === ''
      ? choices
      : choices.filter((item) => {
          return item.name.toLowerCase().includes(query.toLowerCase())
        })

  const checkError = useCallback(() => {
    if (error) {
      switch (error?.type) {
        case 'required':
          setfieldErrorMessage('This field is required')
          break
        default:
          if (error?.message) setfieldErrorMessage(error?.message)
          else {
            setfieldErrorMessage('')
          }
          break
      }
    }
  }, [error])

  const buildSelect = (onChange?: (...event: any[]) => void) => {
    return (
      <Listbox
        value={selected}
        onChange={(e) => {
          if (onChange) onChange(e?.id)
          else if (onValueChange) onValueChange(e as SelectChoiceType)
          setSelected(e)
        }}
      >
        {({ open }) => (
          <div
            className={classNames(
              'block text-sm font-medium text-gray-700',
              className
            )}
          >
            <div className="flex">
              <Listbox.Label className="flex-1 text-sm font-medium text-gray-700">
                {label}
              </Listbox.Label>

              {helpText && (
                <Tooltip
                  text={helpText}
                  position="left"
                  className="sm:col-span-2"
                >
                  <HeroIcon
                    name="ExclamationCircleIcon"
                    className="h-4 w-4 text-indigo-400 hover:text-indigo-700"
                  />
                </Tooltip>
              )}
            </div>

            <div className="mt-1 relative">
              <Listbox.Button
                className={classNames(
                  'relative w-full bg-white text-left cursor-default focus:outline-none',
                  inputClass,
                  style === 'muted' ? '' : 'border focus:ring-1',
                  size === 'md'
                    ? 'pl-3 pr-10 py-2 sm:text-sm rounded-md'
                    : size === 'sm'
                    ? 'pl-2 pr-10 py-1 text-xs rounded-sm'
                    : ''
                )}
              >
                <span className="flex items-center">
                  {selected?.id ? (
                    <>
                      {selected?.avatarUrl && (
                        <img
                          src={selected?.avatarUrl}
                          alt=""
                          className={`flex-shrink-0 h-5 w-5 ${
                            selected.avatarUrlStyle
                              ? selected.avatarUrlStyle
                              : 'rounded-full'
                          }`}
                        />
                      )}

                      <span
                        className={`${
                          selected?.avatarUrl ? 'ml-3' : ''
                        } block truncate`}
                      >
                        {selected?.name}
                      </span>
                    </>
                  ) : (
                    <span className="block truncate text-gray-400">
                      Please select a {label}
                    </span>
                  )}
                </span>
                <span
                  className={classNames(
                    'ml-3 absolute inset-y-0 right-0 flex items-center pointer-events-none',
                    size === 'md' ? 'pr-2' : size === 'sm' ? 'pr-1' : ''
                  )}
                >
                  {fieldErrorMessage ? (
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <SelectorIcon
                      className={classNames(
                        'text-gray-400',
                        size === 'md'
                          ? 'h-5 w-5'
                          : size === 'sm'
                          ? 'h-3 w-3'
                          : ''
                      )}
                      aria-hidden="true"
                    />
                  )}
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className={classNames(
                    'absolute z-40 mt-1 w-full bg-white shadow-lg max-h-56 py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none',
                    size === 'md'
                      ? 'text-base sm:text-sm rounded-md'
                      : size === 'sm'
                      ? 'text-sm rounded-sm'
                      : ''
                  )}
                >
                  {choices.map((choice) => (
                    <Listbox.Option
                      key={choice.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-indigo-600' : 'text-gray-900',
                          'cursor-default select-none relative',
                          size === 'md'
                            ? 'py-2 pl-3 pr-9'
                            : size === 'sm'
                            ? 'py-2 pl-2 pr-9 text-xs'
                            : ''
                        )
                      }
                      value={choice}
                    >
                      {({ selected: _selected, active }) => (
                        <>
                          <div className="flex items-center">
                            {choice.avatarUrl && (
                              <img
                                src={choice.avatarUrl}
                                alt=""
                                className={`flex-shrink-0 h-5 w-5 ${
                                  choice.avatarUrlStyle
                                    ? choice.avatarUrlStyle
                                    : 'rounded-full'
                                }`}
                              />
                            )}

                            <span
                              className={classNames(
                                _selected ? 'font-semibold' : 'font-normal',
                                'block truncate',
                                choice.avatarUrl ? 'ml-3' : ''
                              )}
                            >
                              {choice.name}
                            </span>
                          </div>

                          {_selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
            {fieldErrorMessage && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {fieldErrorMessage}
              </p>
            )}
          </div>
        )}
      </Listbox>
    )
  }

  useEffect(() => {
    checkError()
  }, [error, checkError])

  useEffect(() => {
    if (fieldErrorMessage) setInputClass(INPUT_CLASS_ERROR)
    else setInputClass(INPUT_CLASS_NORMAL)
  }, [fieldErrorMessage])

  useEffect(() => {
    if (!selected && choices) {
      const selectedItem = choices.find((obj) => {
        return obj.id === value
      })
      setSelected(selectedItem)
    }
  }, [value, selected, choices])

  if (control) {
    return (
      <>
        <Controller
          name={name}
          control={control}
          defaultValue={value}
          rules={{ required: required }}
          render={({ field: { onChange } }) => buildSelect(onChange)}
        />
      </>
    )
  }
  return <>{buildSelect()}</>
}
export { SelectInput }
