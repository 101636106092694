import { NewsletterSubscriber } from '@prisma/client'
import { request } from '@/utils/http'
import { ApiResponse, ApiRequestParams } from '@/utils/types'

export interface NewsletterSubscriberItem extends NewsletterSubscriber {}
export const NewsletterSubscriberItemInputs: string[] = ['name', 'email']

class NewsletterSubscriberModel {
  item: NewsletterSubscriberItem = {} as NewsletterSubscriberItem

  constructor(item?: Partial<NewsletterSubscriberItem>) {
    if (item) this.item = { ...this.item, ...item }
  }

  updateLocalItem(item: Partial<NewsletterSubscriberItem>) {
    this.item = { ...this.item, ...item }
  }

  prepareDataForSaving(): ApiRequestParams {
    const params: ApiRequestParams = {}
    NewsletterSubscriberItemInputs.forEach((key) => {
      const val = this.item[key as keyof NewsletterSubscriberItem]
      if (
        typeof val === 'string' ||
        typeof val === 'number' ||
        typeof val === 'boolean'
      )
        params[key] = val
    })
    return params
  }

  async save(): Promise<NewsletterSubscriberModel | null> {
    if (this.item?.id) {
      return this.update()
    }
    return this.create()
  }

  async create(): Promise<NewsletterSubscriberModel | null> {
    return NewsletterSubscriberModel.returnApiSingle(
      await request({
        url: '/api/emailsubscribers/create',
        method: 'POST',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async update(): Promise<NewsletterSubscriberModel | null> {
    return NewsletterSubscriberModel.returnApiSingle(
      await request({
        url: `/api/emailsubscribers/${this.item.id}/update`,
        method: 'PUT',
        params: this.prepareDataForSaving(),
      })
    )
  }

  static returnApiSingle(
    res: ApiResponse<NewsletterSubscriberItem>
  ): NewsletterSubscriberModel | null {
    if (!res.error && res.data) {
      const item: NewsletterSubscriberItem = res.data
      return new NewsletterSubscriberModel(item)
    }
    return null
  }

  static returnApiMany(
    res: ApiResponse<NewsletterSubscriberItem[]>
  ): NewsletterSubscriberModel[] {
    if (!res.error && res.data) {
      const listings: NewsletterSubscriberItem[] = res.data
      return listings.map(
        (item: NewsletterSubscriberItem) => new NewsletterSubscriberModel(item)
      )
    }
    return []
  }

  static async createNewsletterSubscriber(
    email: string
  ): Promise<NewsletterSubscriberModel | null> {
    const subscriber = new NewsletterSubscriberModel()
    subscriber.item.email = email
    return subscriber.save()
  }
}
export { NewsletterSubscriberModel }
