import { Menu, Transition } from '@headlessui/react'
import { useState, useEffect, Fragment } from 'react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { AwesomeIcon } from './AwesomeIcon'

export type ButtonItem = {
  text: string
  id?: string
  icon?: IconDefinition
  iconClassName?: string
  primary?: boolean
  href?: string
  hrefTarget?: '_blank' | '_parent' | '_self' | '_top'
  hidden?: boolean
  onClick?: (e: any, item: any, buttonItem: ButtonItem) => void
}

type ButtonDropdownProps = {
  item?: any
  text?: string
  buttons: ButtonItem[]
  size?: 'XS' | 'SM' | 'MD' | 'LG' | 'XL'
  style?:
    | 'PRIMARY'
    | 'SECONDARY'
    | 'SECONDARY_ON_GRAY'
    | 'WHITE'
    | 'DANGER'
    | 'OVERLAY'
  className?: string
  activeButtonId?: string
  [key: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const ButtonDropdown = ({
  item = null,
  text,
  buttons = [],
  size = 'SM',
  style = 'PRIMARY',
  className,
  activeButtonId,
  ...args
}: ButtonDropdownProps) => {
  const [sizeClass, setSizeClass] = useState<string>('')
  const [borderClass, setBorderClass] = useState<string>('')
  const [ddBorderClass, setDdBorderClass] = useState<string>('')
  const [styleClass, setStyleClass] = useState<string>('')
  const baseClass = 'relative inline-flex items-center focus:outline-none'
  const [primaryButton, setPrimaryButton] = useState<ButtonItem>()
  const [secondaryButtons, setSecondaryButtons] = useState<ButtonItem[]>()

  useEffect(() => {
    // Set the size
    switch (size) {
      case 'XS':
        setSizeClass('px-2.5 py-1.5 text-xs font-medium')
        setBorderClass('rounded-l')
        setDdBorderClass(text ? 'rounded' : 'rounded-r')
        break
      case 'SM':
        setSizeClass('px-3 py-2 text-sm leading-4 font-medium')
        setBorderClass('rounded-l-md')
        setDdBorderClass(text ? 'rounded-md' : 'rounded-r-md')
        break
      case 'MD':
        setSizeClass('px-4 py-2 text-sm font-medium')
        setBorderClass('rounded-l-md')
        setDdBorderClass(text ? 'rounded-md' : 'rounded-r-md')
        break
      case 'LG':
        setSizeClass('px-4 py-2 text-base font-medium')
        setBorderClass('rounded-l-md')
        setDdBorderClass(text ? 'rounded-md' : 'rounded-r-md')
        break
      case 'XL':
        setSizeClass('px-6 py-3 text-base font-medium')
        setBorderClass('rounded-l-md')
        setDdBorderClass(text ? 'rounded-md' : 'rounded-r-md')
        break
      default:
        setSizeClass('px-4 py-2 text-sm font-medium')
        setBorderClass('rounded-l-md')
        setDdBorderClass(text ? 'rounded-md' : 'rounded-r-md')
        break
    }

    // Set the style
    switch (style) {
      case 'PRIMARY':
        setStyleClass(
          'text-white bg-indigo-600 hover:bg-indigo-700 border-transparent border shadow-sm'
        )
        break
      case 'SECONDARY':
        setStyleClass(
          'text-indigo-500 bg-indigo-10 hover:bg-indigo-25 border-transparent border shadow-sm'
        )
        break
      case 'SECONDARY_ON_GRAY':
        setStyleClass(
          'text-indigo-500 bg-white hover:bg-indigo-25 border-transparent border shadow-sm'
        )
        break
      case 'WHITE':
        setStyleClass(
          'text-gray-700 bg-white hover:bg-indigo-25 border-gray-300 border shadow-sm'
        )
        break
      case 'DANGER':
        setStyleClass(
          'text-white bg-red-600 hover:bg-red-700 border-transparent border shadow-sm'
        )
        break
      case 'OVERLAY':
        setStyleClass(
          'bg-white bg-opacity-75 backdrop-filter backdrop-blur text-gray-900 text-center'
        )
        break
      default:
        setStyleClass(
          'text-white bg-indigo-600 hover:bg-indigo-700 border-transparent border shadow-sm'
        )
        break
    }
  }, [size, style, text])

  useEffect(() => {
    setPrimaryButton(buttons.find((i: ButtonItem) => i.primary && !i.hidden))
    setSecondaryButtons(
      buttons.filter((i: ButtonItem) => !i.primary && !i.hidden)
    )
  }, [buttons])

  const buttonClicked = (
    e: React.MouseEvent<HTMLElement>,
    button?: ButtonItem
  ) => {
    if (button?.onClick) button.onClick(e, item, button)
    else if (button?.href) window.location.href = button?.href
  }

  if ((!primaryButton && !text) || !secondaryButtons) return <></>

  return (
    <span className="relative inline-flex">
      {primaryButton && (
        <button
          type="button"
          className={`${baseClass} ${sizeClass} ${styleClass} ${borderClass} ${className}`}
          onClick={(e: React.MouseEvent<HTMLElement>) =>
            buttonClicked(e, primaryButton)
          }
          {...args}
        >
          {primaryButton.icon && (
            <AwesomeIcon
              icon={primaryButton.icon}
              className="h-5 mr-2 text-gray-300"
            />
          )}
          {primaryButton.text}
        </button>
      )}

      <Menu as="span" className="-ml-px relative block">
        <Menu.Button
          className={classNames(
            baseClass,
            sizeClass,
            styleClass,
            ddBorderClass,
            className ? className : '',
            'shadow-sm',
            primaryButton ? '' : 'rounded-md'
          )}
        >
          <span className="sr-only">Open options</span>
          {text && <span className="mr-2">{text}</span>}
          <div className="flex items-center">
            <AwesomeIcon
              icon={solid('angle-down')}
              className="h-4 text-indigo-500"
            />
          </div>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right z-30 absolute right-0 mt-2 -mr-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="p-2">
              {secondaryButtons.map((button) => (
                <Menu.Item key={button.text}>
                  {({ active }) => (
                    <a
                      href={button.href ? button.href : '#'}
                      target={button.hrefTarget ? button.hrefTarget : ''}
                      className={classNames(
                        active && activeButtonId !== button.id
                          ? 'bg-indigo-10 text-gray-900'
                          : button.id && activeButtonId === button.id
                          ? 'bg-indigo-50 text-gray-900'
                          : 'text-gray-700 flex items-center hover:bg-indigo-10',
                        'block w-full px-3 py-1.5 text-sm flex items-center rounded-md whitespace-nowrap'
                      )}
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        if (button?.onClick) buttonClicked(e, button)
                      }}
                      {...args}
                    >
                      {button.icon && (
                        <AwesomeIcon
                          icon={button.icon}
                          className={classNames(
                            'mr-3',
                            button.iconClassName
                              ? button.iconClassName
                              : 'text-indigo-300'
                          )}
                        />
                      )}
                      {button.text}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </span>
  )
}
export { ButtonDropdown }
