import { Fragment, useState, useEffect, FC } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { MenuAlt2Icon, XIcon } from '@heroicons/react/outline'
import { InfoModal } from '@/components/ui'
import { useApp } from '@/context'
import NavHeaderPublic from './NavHeaderPublic'
import NavHeaderDashboard from './NavHeaderDashboard'
import NavSidebarDashboard from './NavSidebarDashboard'
import Meta from './Meta'
import Footer from './Footer'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const Layout: FC = ({ children }) => {
  const { layout, pageTitle, pageMeta } = useApp()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (layout.mode === 'PUBLIC') {
    return (
      <div className="min-h-full flex flex-col h-screen justify-between">
        <Meta {...pageMeta} />
        <header
          className={classNames(
            'z-40',
            layout.headerBgColour === 'GRAY'
              ? 'bg-gray-800'
              : layout.headerBgColour === 'INDIGO'
              ? 'bg-indigo-500'
              : 'backdrop-blur-md bg-white/90',
            layout.headerShadow ? 'shadow-lg' : 'shadow-sm',
            layout.nonStickyHeader ? '' : 'sticky top-0'
          )}
        >
          <NavHeaderPublic />
        </header>
        <div
          className={classNames(
            'mb-auto bg-white',
            !layout.disablePaddingTop ? 'pt-10' : ''
          )}
        >
          <main>
            {pageTitle && (
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold leading-tight text-gray-900">
                  {pageTitle}
                </h1>
              </div>
            )}
            <div
              className={classNames(
                !layout.allowContentFullWidth ? 'max-w-7xl' : '',
                'mx-auto'
              )}
            >
              {children}
            </div>
          </main>
        </div>
        <Footer />
        <InfoModal />
      </div>
    )
  }
  if (layout.mode === 'DASHBOARD') {
    return (
      <>
        <div>
          <Meta {...pageMeta} />
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 flex z-40 md:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 bg-indigo-700">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img
                      className="h-5 w-auto"
                      src="/img/floor3-white-colour-mark.png"
                      alt="Floor3"
                    />
                  </div>
                  <NavSidebarDashboard mode="COLLAPSE" />
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col flex-grow pt-5 bg-indigo-700 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img
                  className="h-5 w-auto"
                  src="/img/floor3-white-colour-mark.png"
                  alt="Floor3"
                />
              </div>
              <NavSidebarDashboard />
            </div>
          </div>
          <div className="md:pl-64 flex flex-col flex-1">
            <main>
              <div className="py-6">
                {pageTitle && (
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <h1 className="text-2xl font-semibold text-gray-900">
                      {pageTitle}
                    </h1>
                  </div>
                )}
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                  {children}
                </div>
              </div>
            </main>
          </div>
        </div>
        <InfoModal />
      </>
    )
  }
  return <></>
}
export default Layout
