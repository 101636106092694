import { Fragment, useState, forwardRef, useImperativeHandle } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Button } from './Button'

type SlideOverProps = {
  title: string
  description?: string
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  children: React.ReactNode
  buttons?: React.ReactNode
  headerContent?: React.ReactNode
  headerContentSecondary?: React.ReactNode
  onSubmit?: () => void
  submitBtnText?: string
  hideSubmitButton?: boolean
}

export type SlideOverRef = {
  setSavingState: (saving: boolean) => void
  openSlideOver: () => void
  closeSlideOver: () => void
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const SlideOver = forwardRef<SlideOverRef, SlideOverProps>((props, ref) => {
  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const {
    title,
    description,
    maxWidth = 'md',
    children,
    buttons,
    headerContent,
    headerContentSecondary,
    onSubmit,
    submitBtnText,
    hideSubmitButton,
  } = props

  useImperativeHandle(ref, () => ({
    setSavingState(_saving: boolean) {
      setSaving(_saving)
    },
    openSlideOver() {
      setOpen(true)
    },
    closeSlideOver() {
      setOpen(false)
    },
  }))

  const getButtons = () => {
    if (buttons) return <div className="px-4 py-4">{buttons}</div>
    return (
      <>
        {onSubmit && !hideSubmitButton ? (
          <div className="flex-shrink-0 px-4 py-4 flex justify-end">
            <Button
              text="Cancel"
              size="MD"
              style="WHITE"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault()
                setOpen(false)
              }}
            />
            <Button
              text={
                saving ? 'Saving...' : submitBtnText ? submitBtnText : 'Save'
              }
              size="MD"
              className="ml-4"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault()
                onSubmit()
              }}
              disabled={saving}
            />
          </div>
        ) : (
          <div className="flex-shrink-0 px-4 py-4 flex justify-end">
            <Button
              text="Close"
              size="MD"
              style="WHITE"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault()
                setOpen(false)
              }}
            />
          </div>
        )}
      </>
    )
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-40 inset-0 overflow-hidden"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                className={classNames(
                  'w-screen',
                  maxWidth === 'xs'
                    ? 'max-w-xs'
                    : maxWidth === 'sm'
                    ? 'max-w-sm'
                    : maxWidth === 'md'
                    ? 'max-w-md'
                    : maxWidth === 'lg'
                    ? 'max-w-lg'
                    : maxWidth === 'xl'
                    ? 'max-w-xl'
                    : 'max-w-md'
                )}
              >
                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  <div className="flex-1 h-0 overflow-y-auto">
                    <div className="py-6 px-4 bg-indigo-700 sm:px-6">
                      <div className="flex items-center justify-between">
                        {headerContent ? (
                          headerContent
                        ) : (
                          <Dialog.Title className="text-lg font-medium text-white">
                            {title}
                          </Dialog.Title>
                        )}

                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      {headerContentSecondary && <>{headerContentSecondary}</>}
                      {description && (
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            {description}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="flex-1 flex flex-col justify-between">
                      <div className="px-4 sm:px-6">{children}</div>
                    </div>
                  </div>
                  {getButtons()}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
})
export { SlideOver }
