import { Fragment, forwardRef, useState, useImperativeHandle } from 'react'
import { Dialog, Transition } from '@headlessui/react'

type ModalBaseProps = {
  children: React.ReactNode
  onClose?: () => void
}

export type ModalBaseRef = {
  show: () => void
  hide: () => void
}

const ModalBase = forwardRef<ModalBaseRef, ModalBaseProps>((props, ref) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const { children, onClose } = props

  const onCloseModal = () => {
    setShowModal(false)
    if (onClose) onClose()
  }

  useImperativeHandle(ref, () => ({
    show() {
      setShowModal(true)
    },
    hide() {
      setShowModal(false)
    },
  }))

  return (
    <>
      <Transition.Root show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          onClose={onCloseModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                {children}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
})

export { ModalBase }
