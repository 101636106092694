import { AxiosError } from 'axios'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://57c378095a5c4b55a6c381e7615f2ee4@o1295894.ingest.sentry.io/6522006',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1,
})

export const handleError = (err: any | AxiosError | Error) => {
  if (process.env.NODE_ENV === 'development') console.log(err)
  else Sentry.captureException(err)
}
