import { forwardRef, useImperativeHandle, useState, useRef } from 'react'
import { Dialog } from '@headlessui/react'
import { SelectChoiceType } from '@/components/form'
import { Button } from './Button'
import { ModalBase, ModalBaseRef } from './ModalBase'
import { Alert } from './Alert'
import { SlideOver, SlideOverRef } from './SlideOver'

type ActionButton = {
  title: string
  active: boolean
  href?: string
  target?: '_blank' | '_parent' | '_self' | '_top'
  style?: 'PRIMARY' | 'SECONDARY' | 'WHITE' | 'DANGER' | 'OVERLAY'
  action?: () => void
}

type MultiStepItem = {
  title: string
  id: string
  content: React.ReactNode
  hideBackBtn?: boolean
  actionButtons: ActionButton[]
}

export type MultiStepError = {
  title: string
  message: string
  tryAgainStep?: string
}

type MultiStepProps = {
  title?: string
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  container: 'Modal' | 'SlideOver'
  allowTabNavigation?: boolean
  steps: MultiStepItem[]
}

export type MultiStepRef = {
  show: () => void
  hide: () => void
  nextStep: () => void
  showError: (err: MultiStepError) => void
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const MultiStep = forwardRef<MultiStepRef, MultiStepProps>((props, ref) => {
  const [activeStep, setActiveStep] = useState<number>(0)
  const [error, setError] = useState<MultiStepError | null>()
  const slideOverRef = useRef<SlideOverRef>(null)
  const modalBaseRef = useRef<ModalBaseRef>(null)
  const {
    title,
    maxWidth = 'md',
    container,
    allowTabNavigation = false,
    steps,
  } = props

  const goToStep = (stepId: string) => {
    const stepIndex = steps.findIndex((item) => item.id === stepId)
    setError(null)
    setActiveStep(stepIndex || 0)
  }

  const goToNextStep = () => {
    setActiveStep(activeStep + 1)
  }

  const closeMultiStep = () => {
    if (container === 'Modal' && modalBaseRef.current) {
      modalBaseRef.current.hide()
    } else if (container === 'SlideOver' && slideOverRef.current) {
      slideOverRef.current.closeSlideOver()
    }
  }

  useImperativeHandle(ref, () => ({
    show() {
      setActiveStep(0)
      setError(null)
      if (container === 'Modal' && modalBaseRef.current) {
        modalBaseRef.current.show()
      } else if (container === 'SlideOver' && slideOverRef.current) {
        slideOverRef.current.openSlideOver()
      }
    },
    hide() {
      closeMultiStep()
    },
    nextStep() {
      goToNextStep()
    },
    showError(err: MultiStepError) {
      setError(err)
    },
  }))

  const stepButtons = (step: MultiStepItem, stepIndex: number) => {
    return (
      <>
        <div
          className={`sm:grid ${
            step.actionButtons.find((i) => {
              return i.active
            }) &&
            (!step.hideBackBtn || stepIndex === steps.length - 1)
              ? 'sm:grid-cols-2'
              : ''
          } sm:gap-3 sm:grid-flow-row-dense`}
        >
          {!step.hideBackBtn ? (
            <Button
              text={stepIndex === 0 ? 'Cancel' : 'Back'}
              size="MD"
              style="WHITE"
              className="w-full justify-center text-center"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault()
                if (stepIndex === 0) closeMultiStep()
                else {
                  const backTo = steps[activeStep - 1].id
                  goToStep(backTo)
                }
              }}
            />
          ) : (
            <Button
              text="Close"
              size="MD"
              style="WHITE"
              className="w-full justify-center text-center"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault()
                closeMultiStep()
              }}
            />
          )}

          {step.actionButtons
            .filter((item) => {
              return item.active
            })
            .map((actionButton) => (
              <div key={actionButton.title}>
                <Button
                  text={actionButton.title}
                  href={actionButton.href}
                  hrefTarget={actionButton.target}
                  size="MD"
                  style={actionButton.style ? actionButton.style : 'PRIMARY'}
                  className="w-full justify-center text-center truncate"
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    if (actionButton.action) {
                      e.preventDefault()
                      actionButton.action()
                    } else if (!actionButton.href) {
                      e.preventDefault()
                      goToNextStep()
                    }
                  }}
                />
              </div>
            ))}
        </div>
      </>
    )
  }

  const stepError = () => {
    return (
      <>
        {error && (
          <>
            {container === 'Modal' && (
              <Dialog.Title
                as="h3"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                {error.title}
              </Dialog.Title>
            )}

            <div className="space-y-6 pt-6 pb-5">
              <Alert body={error.message} style="DANGER" />
            </div>

            {container === 'Modal' && (
              <div
                className={`mt-5 sm:mt-6 sm:grid ${
                  error.tryAgainStep ? 'sm:grid-cols-2' : ''
                } sm:gap-3 sm:grid-flow-row-dense`}
              >
                <Button
                  text="Close"
                  size="MD"
                  style="WHITE"
                  className="w-full justify-center text-center"
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.preventDefault()
                    closeMultiStep()
                  }}
                />
                {error.tryAgainStep && (
                  <Button
                    text="Try Again"
                    size="MD"
                    style="PRIMARY"
                    className="w-full justify-center text-center"
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      e.preventDefault()
                      if (error.tryAgainStep) {
                        goToStep(error.tryAgainStep)
                      }
                    }}
                  />
                )}
              </div>
            )}
          </>
        )}
      </>
    )
  }

  const stepTabs = () => {
    return (
      <>
        <div>
          <div className="hidden sm:block">
            <nav className="flex space-x-4" aria-label="Tabs">
              {steps.map((step, stepIndex) => (
                <button
                  type="button"
                  key={step.title}
                  className={classNames(
                    activeStep === stepIndex
                      ? 'bg-indigo-100 text-indigo-800'
                      : 'text-indigo-300',
                    'px-3 py-2 font-medium text-sm rounded-md'
                  )}
                  aria-current={activeStep === stepIndex ? 'page' : undefined}
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.preventDefault()
                    if (allowTabNavigation) goToStep(step.id)
                  }}
                >
                  {step.title}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </>
    )
  }

  const stepSelector = () => {
    const stepChoices: SelectChoiceType[] = []
    steps.map((step) =>
      stepChoices.push({
        id: step.id,
        name: step.title,
      })
    )

    let gridColsClass = 'grid-cols-2'
    switch (steps.length) {
      case 3:
        gridColsClass = 'grid-cols-3'
        break
      case 4:
        gridColsClass = 'grid-cols-4'
        break
      case 5:
        gridColsClass = 'grid-cols-5'
        break
      case 6:
        gridColsClass = 'grid-cols-6'
        break
      case 7:
        gridColsClass = 'grid-cols-7'
        break
      default:
        gridColsClass = 'grid-cols-2'
        break
    }

    return (
      <div>
        <div className="mt-5" aria-hidden="true">
          <div className="bg-indigo-600 rounded-full overflow-hidden">
            <div
              className="h-2 bg-indigo-400 rounded-full transition-all duration-700 ease-in-out"
              style={{
                width: `${
                  ((activeStep + 1) / steps.length) * 100 -
                  ((1 / steps.length) * 100) / 2
                }%`,
              }}
            />
          </div>
          <div
            className={classNames('hidden sm:grid text-xs mt-2', gridColsClass)}
          >
            {steps.map((step, stepIndex) => (
              <button
                key={step.id}
                className={classNames(
                  stepIndex === activeStep ? 'text-white' : 'text-indigo-400',
                  stepIndex > 0 && stepIndex + 1 < steps.length
                    ? 'text-center'
                    : stepIndex + 1 === steps.length
                    ? 'text-right'
                    : 'text-left'
                )}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.preventDefault()
                  if (allowTabNavigation) goToStep(step.id)
                }}
              >
                {step.title}
              </button>
            ))}
          </div>
        </div>
      </div>
    )
  }

  if (container === 'Modal') {
    return (
      <ModalBase ref={modalBaseRef}>
        {error
          ? stepError()
          : steps.map((step, stepIndex) => (
              <div key={step.id}>
                {activeStep === stepIndex && (
                  <>
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {step.title}
                    </Dialog.Title>
                    <div className="space-y-6 pt-6 pb-5">{step.content}</div>
                    <div className="mt-5 sm:mt-6">
                      {stepButtons(step, stepIndex)}
                    </div>
                  </>
                )}
              </div>
            ))}
      </ModalBase>
    )
  }
  if (container === 'SlideOver') {
    return (
      <SlideOver
        ref={slideOverRef}
        title={error ? error.title : title ? title : ''}
        headerContentSecondary={
          error ? '' : steps.length <= 5 ? stepTabs() : stepSelector()
        }
        maxWidth={maxWidth}
        buttons={
          !error &&
          steps.map((step, stepIndex) => (
            <div key={step.id}>
              {activeStep === stepIndex && <>{stepButtons(step, stepIndex)}</>}
            </div>
          ))
        }
      >
        <>
          {error
            ? stepError()
            : steps.map((step, stepIndex) => (
                <div key={step.id}>
                  {activeStep === stepIndex && (
                    <div className="space-y-6 pb-5">{step.content}</div>
                  )}
                </div>
              ))}
        </>
      </SlideOver>
    )
  }
  return <></>
})
export { MultiStep }
