import { ApiResponse } from '@/utils/types'
import Web3Modal from 'web3modal'
import { ethers, ContractTransaction, ContractReceipt, Event } from 'ethers'
import {
  TokenCreatedEvent,
  TokenTransferEvent,
  EthersError,
} from '@/utils/typings/web3'
import { request } from '@/utils/http'
import { getProvider, getWallet } from '@/utils/web3/wallet'
import { ListingItem } from '@/utils/typings/models'
import { Floor3NFT, Floor3NFT__factory } from '@/contracts/typings'
import { NFTModel } from '../../models/NFTModel'
import { ListingModel } from '../../models/ListingModel'

export const getContract = async (signer?: ethers.Signer) => {
  if (signer) {
    return Floor3NFT__factory.connect(
      process.env.NEXT_PUBLIC_FLOOR3_CONTRACT,
      signer
    )
  }
  const { signer: newSigner } = await getWallet()
  if (!newSigner) return null

  const address = await newSigner.getAddress()
  if (!address) return null
  return Floor3NFT__factory.connect(
    process.env.NEXT_PUBLIC_FLOOR3_CONTRACT,
    newSigner
  )
}

export const getContractWithSignerAddress = (walletAddress: string) => {
  const provider = new ethers.providers.JsonRpcProvider(
    process.env.NEXT_PUBLIC_ETHERS_PROVIDER_URL
  )
  // 'http://127.0.0.1:8545'
  const signer = provider.getSigner(walletAddress)

  return Floor3NFT__factory.connect(
    process.env.NEXT_PUBLIC_FLOOR3_CONTRACT,
    signer
  )
}

// If verified, it will return the wallet address that was verified.
export const verifyAccess = async (nft: NFTModel): Promise<string> => {
  const { signer } = await getWallet()
  if (!signer) return ''

  const address = await signer.getAddress()
  if (!address) return ''

  // A wallet is connected, so now let's make sure they can access this NFT
  if (!nft || nft.item.owner !== address) return ''

  // Local DB sees them as owner of this NFT, lets verify on the blockchain
  const contract = await getContract(signer)
  if (contract && nft.item.tokenId) {
    const nftToken: Floor3NFT.TokenStructOutput = await contract.getToken(
      nft.item.tokenId
    )
    if (!nftToken || (nftToken && nftToken.owner !== address)) return ''
    return address
  }
  return ''
}

export const confirmPayableTx = async (
  transaction: ContractTransaction
): Promise<boolean> => {
  const receipt: ContractReceipt = await transaction.wait()
  if (receipt && receipt.blockNumber > 0 && receipt.confirmations > 0) {
    return true
  }
  return false
}

export const getCleanEvent = (
  event: Event,
  eventType: 'TokenCreated' | 'TransferSingle',
  blockHash: string,
  txId: string
): TokenCreatedEvent | TokenTransferEvent | null => {
  if (event?.args && eventType === 'TokenCreated') {
    const resTokenCreated: TokenCreatedEvent = {
      id: event.args[0] as string,
      floor3Id: event.args[1],
      creator: event.args[2],
      owner: event.args[3],
      price: Number(
        ethers.utils.formatUnits(event.args[4] as ethers.BigNumber, 'ether')
      ),
      uri: event.args[5],
      blockHash: blockHash,
      txId: txId,
    }
    return resTokenCreated
  }
  if (event?.args && eventType === 'TransferSingle') {
    const resTokenCreated: TokenTransferEvent = {
      id: event.args[3] as string,
      seller: event.args[1],
      owner: event.args[2],
      blockHash: blockHash,
      txId: txId,
    }
    return resTokenCreated
  }
  return null
}

export const getTxEvent = async (
  transaction: ContractTransaction,
  eventType: 'TokenCreated' | 'TransferSingle'
) => {
  const receipt: ContractReceipt = await transaction.wait()
  if (receipt?.events) {
    const event: Event | undefined = receipt.events.find((e) => {
      return e.event === eventType
    })
    if (event)
      return getCleanEvent(
        event,
        eventType,
        receipt.blockHash,
        receipt.transactionHash
      )
  }
  return null
}

export const getEvent = async (
  walletAddress: string,
  eventType: 'TokenCreated' | 'TransferSingle',
  blockHash: string,
  txId: string
) => {
  const contract = getContractWithSignerAddress(walletAddress)
  const events = await contract.queryFilter(
    eventType === 'TokenCreated'
      ? contract.filters.TokenCreated()
      : contract.filters.TransferSingle(),
    blockHash
  )
  const event: Event | undefined = events.find((e) => {
    return e.transactionHash === txId
  })
  if (event) return getCleanEvent(event, eventType, blockHash, txId)
  return null
}

export const getAffiliateFromContractEvent = async (
  contract: Floor3NFT,
  event: TokenCreatedEvent | TokenTransferEvent
): Promise<string | null> => {
  const token: Floor3NFT.TokenStructOutput = await contract.getToken(event.id)
  if (
    token.affiliate &&
    token.affiliate !== '0x0000000000000000000000000000000000000000'
  )
    return token.affiliate
  return null
}

export const setupCreator = async (
  listing: ListingModel
): Promise<ApiResponse<string>> => {
  const web3Modal = new Web3Modal()
  const connection: ethers.providers.JsonRpcFetchFunc =
    await web3Modal.connect()
  const provider = new ethers.providers.Web3Provider(connection)
  const signer = provider.getSigner()
  const signerAddress = await signer.getAddress()
  let errMessage = ''

  if (listing.item.walletAddress !== signerAddress) {
    return {
      data: null,
      error: {
        title: "Wallet address doesn't match",
        description:
          'The wallet address you are trying to publish with does not match the address linked to this listing.',
      },
    }
  }

  if (listing && listing.item.walletAddress) {
    try {
      const contract = await getContract(signer)
      if (contract) {
        const listingFeeParsed = ethers.utils.parseUnits(
          (process.env.LISTING_FEE ? process.env.LISTING_FEE : 0.25).toString(),
          'ether'
        )
        const tx: ContractTransaction = await contract.setupCreator(
          process.env.NEXT_PUBLIC_FLOOR3_CONTRACT_ADMIN,
          listing.item.walletAddress,
          listing.item.id,
          {
            value: listingFeeParsed,
          }
        )

        return { data: tx.hash, error: {} }
      }
    } catch (error_) {
      const error = error_ as EthersError
      if (error.data && error.data.message.includes('Creator already setup')) {
        // Safe to proceed with publishing
        errMessage = 'Creator already setup'
      }
    }
  }
  if (!errMessage)
    errMessage =
      'There was a problem authorising you as a creator. Please contact support for assistance.'

  return { data: null, error: { title: errMessage } }
}

export const confirmListingSetup = async (
  listingId: string,
  txId: string
): Promise<ApiResponse<ListingItem>> => {
  // Save the TX hash in case we need to refer to it later
  const { provider } = await getProvider()
  if (provider) {
    const tx: ContractTransaction = await provider.getTransaction(txId)
    const listing = await ListingModel.getListing(listingId)

    if (listing) {
      listing.item.publishTxHash = txId
      await listing.save()

      // Now attempt to confirm whether the transaction was successful
      if (await confirmPayableTx(tx)) {
        // Create the NFT's based on total supply
        return request<ListingItem>({
          url: `/api/listings/${listing.item.id}/publish`,
          method: 'PUT',
        })
      }
    }
  }

  return {
    data: null,
    error: {
      title: 'There was a problem publishing',
      description:
        'There was a problem confirming the publish transaction. Please contact support for assistance.',
    },
  }
}

export const completeNftResaleListing = async (
  nftId: string,
  listingPrice: number,
  signature: string
): Promise<ApiResponse<ListingItem>> => {
  return request<ListingItem>({
    url: `/api/nfts/${nftId}/complete-resale-listing`,
    method: 'PUT',
    params: { signature, listingPrice },
  })
}

export const keccak256ToBigNumber = (str: string): ethers.BigNumber => {
  // To return the proper string version of this BigNumber, do:
  // const x = keccak256ToBigNumber('121212121212').toString()
  const keccak256 = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(str))
  return ethers.BigNumber.from(keccak256)
}
