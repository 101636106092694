import {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { AnalyticsPeriodData, AnalyticsSeriesItem } from '@/utils/typings/app'
import type { ChartData, ChartDataset, Plugin } from 'chart.js'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
  ChartOptions,
} from 'chart.js'
import { Chart as ChartJsComponent } from 'react-chartjs-2'
import { LoadingSpinner } from './LoadingSpinner'

let multiply: Plugin = {
  id: 'multiply',
  beforeDatasetsDraw(chart: ChartJS, args: any, options: ChartOptions) {
    chart.ctx.globalCompositeOperation = 'multiply'
  },
  afterDatasetsDraw(chart: ChartJS, args: any, options: ChartOptions) {
    chart.ctx.globalCompositeOperation = 'source-over'
  },
}

function gradientFill(
  ctx: CanvasRenderingContext2D,
  start: string,
  stop: string
) {
  let gradient = ctx.createLinearGradient(0, 0, 0, 150)
  gradient.addColorStop(0, start)
  gradient.addColorStop(1, stop)
  return gradient
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
  multiply
)

export type ChartProps = {
  title: string
  subTitle?: string
  chartData?: AnalyticsPeriodData | null
}

export type ChartRef = {}

// const Chart = ({ data }: ChartProps) => {
const Chart = forwardRef<ChartRef, ChartProps>((props, ref) => {
  const chartJsRef = useRef<ChartJS>(null)
  const [chartJsData, setChartJsData] = useState<ChartData<'line'>>({
    datasets: [],
  })
  const [chartJsOptions, setChartJsOptions] = useState<ChartOptions>()
  const { title, subTitle, chartData } = props

  useEffect(() => {
    const chart = chartJsRef.current

    if (!chart || !chartData) {
      return
    }

    const options: ChartOptions = {
      elements: {
        point: {
          radius: 0,
          hitRadius: 5,
          hoverRadius: 5,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
          beginAtZero: true,
        },
      },
      plugins: { legend: { display: false } },
    }
    const data: ChartData<'line'> = {
      labels: chartData.labels,
      datasets: [],
    }

    chartData.series.forEach((item: AnalyticsSeriesItem, index: number) => {
      const dataset: ChartDataset<'line'> = {
        label: item.title ? item.title : '',
        data: item.data,
        backgroundColor:
          index === 0
            ? gradientFill(chart.ctx, '#5555FF', '#9787FF')
            : index === 1
            ? gradientFill(chart.ctx, '#FF55B8', '#FF8787')
            : gradientFill(chart.ctx, '#5555FF', '#9787FF'),
        borderColor: 'transparent',
        pointBackgroundColor: '#FFFFFF',
        pointBorderColor: '#FFFFFF',
        tension: 0.4,
        fill: true,
      }
      data.datasets.push(dataset)
    })

    setChartJsData(data)
    setChartJsOptions(options)
  }, [chartData])

  useImperativeHandle(ref, () => ({}))

  return (
    <div className="relative group bg-white shadow rounded-lg overflow-hidden">
      <div className="pl-3 pt-3 font-medium text-sm text-gray-500">{title}</div>
      {subTitle && (
        <div className="pl-3 mb-3 font-medium text-xs text-gray-400">
          {subTitle}
        </div>
      )}
      <div className={!chartData ? 'opacity-25' : ''}>
        <ChartJsComponent
          ref={chartJsRef}
          type="line"
          data={chartJsData}
          options={chartJsOptions}
          height="80"
        />
      </div>
      {!chartData && (
        <div className="absolute inset-0 flex items-center justify-center">
          <LoadingSpinner colour="indigo" height={6} width={6} />
        </div>
      )}
    </div>
  )
})

export { Chart }
