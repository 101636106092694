import { useEffect, useState } from 'react'
import { InformationCircleIcon } from '@heroicons/react/solid'

type AlertProps = {
  body: string
  link?: string
  onLinkClick?: (e: any) => void
  linkText?: string
  style?: 'STANDARD' | 'DANGER' | 'MUTED'
  className?: string
}

const Alert = ({
  body,
  link = '',
  onLinkClick,
  linkText = 'Details',
  style = 'STANDARD',
  className,
}: AlertProps) => {
  const [bgColour, setBgColour] = useState<string>('bg-indigo-50')
  const [textColour, setTextColour] = useState<string>('text-indigo-700')
  const [iconColour, setIconColour] = useState<string>('text-indigo-400')
  const [linkColour, setLinkColour] = useState<string>(
    'text-indigo-700 hover:text-indigo-600'
  )

  useEffect(() => {
    if (style === 'DANGER') {
      setBgColour('bg-red-50')
      setTextColour('text-red-700')
      setIconColour('text-red-400')
      setLinkColour('text-red-900 hover:text-red-600')
    } else if (style === 'MUTED') {
      setBgColour('bg-gray-50')
      setTextColour('text-gray-700')
      setIconColour('text-gray-400')
      setLinkColour('text-gray-900 hover:text-gray-600')
    }
  }, [style])

  const linkClicked = (e: React.MouseEvent<HTMLElement>) => {
    if (onLinkClick) onLinkClick(e)
  }

  return (
    <div className={`rounded-md ${bgColour} p-4 ${className}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            className={`h-5 w-5 ${iconColour}`}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className={`text-sm ${textColour}`}>{body}</p>
          {(link || onLinkClick) && (
            <p className="mt-3 text-sm md:mt-0 md:ml-6">
              {link ? (
                <a
                  href={link}
                  className={`whitespace-nowrap font-medium ${linkColour} outline-none`}
                >
                  {linkText} <span aria-hidden="true">&rarr;</span>
                </a>
              ) : (
                <button
                  onClick={(e: React.MouseEvent<HTMLElement>) => linkClicked(e)}
                  className={`whitespace-nowrap font-medium ${linkColour} outline-none`}
                  type="button"
                >
                  {linkText} <span aria-hidden="true">&rarr;</span>
                </button>
              )}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
export { Alert }
