import {
  useState,
  useMemo,
  useContext,
  useCallback,
  createContext,
} from 'react'
import { useAsyncEffect } from 'use-async-effect'
import { NotificationType } from '@/utils/types'
import { FxRates } from '@prisma/client'
import { request } from '@/utils/http'
import { LayoutProps, PageMetaProps } from '@/utils/typings/app'

type appContextType = {
  layout: LayoutProps
  pageTitle: string
  pageMeta: PageMetaProps
  notification: NotificationType
  showNotification: (props: NotificationType) => void
  showError: (props: NotificationType) => void
  setLayout: (layout: LayoutProps) => void
  setPageTitle: (pageTitle: string) => void
  setPageMeta: (meta: PageMetaProps) => void
  fxRate: FxRates | null
  getFxRates: () => void
  setFxRate: (symbol: string) => void
}

const appContextDefaultValues: appContextType = {
  layout: { mode: 'PUBLIC' },
  pageTitle: '',
  pageMeta: {},
  notification: {},
  showNotification: () => {},
  showError: () => {},
  setLayout: () => {},
  setPageTitle: () => {},
  setPageMeta: () => {},
  fxRate: null,
  getFxRates: () => {},
  setFxRate: () => {},
}
const AppContext = createContext<appContextType>(appContextDefaultValues)

type Props = { children: React.ReactNode }

export const AppProvider = ({ children }: Props) => {
  const [layout, setLayout] = useState<LayoutProps>({ mode: 'PUBLIC' })
  const [pageTitle, setPageTitle] = useState<string>('Home')
  const [pageMeta, setPageMeta] = useState<PageMetaProps>({})
  const [fxRate, _setFxRate] = useState<FxRates | null>(null)
  const [fxRates, setFxRates] = useState<FxRates[]>([])
  const [notification, setNotification] = useState<NotificationType>({})

  const showNotification = useCallback((props: NotificationType) => {
    setNotification(props)
  }, [])

  const showError = useCallback((_props: NotificationType) => {
    const props = { ..._props }
    props.icon = 'ERROR'
    setNotification(props)
  }, [])

  const getFxRates = useCallback(async (): Promise<FxRates[]> => {
    if (fxRates.length > 0) return fxRates

    const res = await request<FxRates[]>({
      url: '/api/fxrates/list',
      method: 'GET',
      params: {},
    })

    if (res.data) {
      setFxRates(res.data)
      return res.data
    }
    return []
  }, [fxRates])

  const setFxRate = useCallback(
    async (symbol: string) => {
      const rates = await getFxRates()
      if (rates) {
        const rate = rates.find((item: FxRates) => {
          return item.to === symbol && item.from === 'ETH'
        })
        if (rate) _setFxRate(rate)
      }
    },
    [getFxRates]
  )

  useAsyncEffect(async () => {
    await setFxRate('ETH')
  }, [])

  const value = useMemo(
    () => ({
      layout,
      pageTitle,
      pageMeta,
      notification,
      showNotification,
      showError,
      setLayout,
      setPageTitle,
      setPageMeta,
      getFxRates,
      setFxRate,
      fxRate,
    }),
    [
      layout,
      pageTitle,
      pageMeta,
      notification,
      showNotification,
      showError,
      setLayout,
      setPageTitle,
      setPageMeta,
      getFxRates,
      setFxRate,
      fxRate,
    ]
  )

  return (
    <>
      <AppContext.Provider value={value}>{children}</AppContext.Provider>
    </>
  )
}

export function useApp() {
  return useContext(AppContext)
}
