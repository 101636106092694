import { useState, useEffect, useCallback } from 'react'
import ReactSlider from 'react-slider'
import { useFormContext, get, Controller } from 'react-hook-form'
import {
  UseFormWatch,
  FieldErrors,
  FieldError,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form/dist/types'

interface RangeInputProps<T extends FieldValues> {
  name: Path<T>
  label: string
  value?: PathValue<T, Path<T>>
  min?: number
  max?: number
  step?: number
  control: any
  errors: FieldErrors<T>
  watch: UseFormWatch<T>
  required?: boolean
}

const RangeInput = <T extends FieldValues>({
  name,
  label,
  value,
  min,
  max,
  step,
  control,
  errors,
  required,
  watch,
}: RangeInputProps<T>) => {
  const [fieldErrorMessage, setfieldErrorMessage] = useState<string>('')
  const selectedValue = watch(name, value)
  const methods = useFormContext()
  const error: FieldError = get(errors || methods.formState.errors, name)

  const checkError = useCallback(() => {
    switch (error?.type) {
      case 'required':
        setfieldErrorMessage('Please select one of these options')
        break
      default:
        if (error?.message) setfieldErrorMessage(error?.message)
        else {
          setfieldErrorMessage('')
        }
        break
    }
  }, [error])

  useEffect(() => {
    checkError()
  }, [error, checkError])

  return (
    <>
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-900 flex justify-between"
      >
        <span>{label}</span>
        <span className="text-gray-400">{selectedValue}%</span>
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue={value}
        rules={{ required: required }}
        render={({ field: { onChange, value: val } }) => (
          <ReactSlider
            step={step}
            min={min}
            max={max}
            className="w-full h-3 pr-2 my-4 bg-gray-50 rounded-md cursor-grab"
            thumbClassName="absolute w-5 h-5 cursor-grab bg-indigo-500 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-1"
            value={Number(val)}
            onChange={(e) => {
              onChange(e)
            }}
          />
        )}
      />
    </>
  )
}

export { RangeInput }
