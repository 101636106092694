import { request } from '@/utils/http'
import { ApiResponse, ApiRequestParams } from '@/utils/types'
import { ListingMediaItem } from '@/utils/typings/models'

export const ListingMediaItemInputs: string[] = [
  'listingId',
  'order',
  'mediaType',
  'image',
  'youTubeId',
]

class ListingMediaModel {
  item: ListingMediaItem = {} as any

  constructor(item?: Partial<ListingMediaItem>) {
    if (item) this.item = { ...this.item, ...item }
  }

  updateLocalItem(item: Partial<ListingMediaItem>) {
    this.item = { ...this.item, ...item }
  }

  prepareDataForSaving(): ApiRequestParams {
    const params: ApiRequestParams = {}
    ListingMediaItemInputs.forEach((key) => {
      const val = this.item[key as keyof ListingMediaItem]
      if (
        typeof val === 'string' ||
        typeof val === 'number' ||
        typeof val === 'boolean'
      )
        params[key] = val
    })
    return params
  }

  async save(): Promise<ListingMediaModel | null> {
    if (this.item?.id) {
      return this.update()
    }
    return this.create()
  }

  async create(): Promise<ListingMediaModel | null> {
    return ListingMediaModel.returnApiSingle(
      await request({
        url: `/api/listings/${this.item.listingId}/media/create`,
        method: 'POST',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async update(): Promise<ListingMediaModel | null> {
    return ListingMediaModel.returnApiSingle(
      await request({
        url: `/api/listings/${this.item.listingId}/media/${this.item.id}/update`,
        method: 'PUT',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async delete(): Promise<boolean> {
    const res = await request<boolean>({
      url: `/api/listings/${this.item.listingId}/media/${this.item.id}/delete`,
      method: 'DELETE',
    })
    return !res.error
  }

  static returnApiSingle(
    res: ApiResponse<ListingMediaItem>
  ): ListingMediaModel | null {
    if (!res.error && res.data) {
      const item: ListingMediaItem = res.data
      return new ListingMediaModel(item)
    }
    return null
  }

  static returnApiMany(
    res: ApiResponse<ListingMediaItem[]>
  ): ListingMediaModel[] {
    if (!res.error && res.data) {
      const listings: ListingMediaItem[] = res.data
      return listings.map(
        (item: ListingMediaItem) => new ListingMediaModel(item)
      )
    }
    return []
  }

  static async getListingMedia(
    listingId: string
  ): Promise<ListingMediaModel[]> {
    return ListingMediaModel.returnApiMany(
      await request({
        url: `/api/listings/${listingId}/media/list`,
        method: 'GET',
        params: {},
      })
    )
  }

  static async getListingMediaRaw(
    listingId: string
  ): Promise<ListingMediaItem[]> {
    const res = await request<ListingMediaItem[]>({
      url: `/api/listings/${listingId}/media/list`,
      method: 'GET',
      params: {},
    })
    if (res.data) return res.data
    return []
  }

  static async getListingMediaItem(
    listingId: string,
    listingMediaId: string
  ): Promise<ListingMediaModel | null> {
    return ListingMediaModel.returnApiSingle(
      await request({
        url: `/api/listings/${listingId}/media/${listingMediaId}`,
        method: 'GET',
        params: {},
      })
    )
  }
}
export { ListingMediaModel }
