import { useState } from 'react'
import { Switch } from '@headlessui/react'
import {
  UseFormRegister,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form/dist/types'
import { Controller } from 'react-hook-form'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type ToggleProps<T extends FieldValues> = {
  name: Path<T>
  label: string
  helpText?: string
  value?: PathValue<T, Path<T>>
  register: UseFormRegister<T>
  control?: any
  errors: FieldErrors<T>
  required?: boolean
  disabled?: boolean
  hideErrorMessage?: boolean
}

const Toggle = <T extends FieldValues>({
  name,
  label,
  helpText = '',
  value,
  register,
  control,
  errors,
  required,
  disabled,
  hideErrorMessage = false,
}: ToggleProps<T>) => {
  const [enabled, setEnabled] = useState<boolean>(
    value === undefined ? false : value
  )

  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      <span className="flex-grow flex flex-col">
        <Switch.Label
          as="span"
          className="text-sm font-medium text-gray-900"
          passive
        >
          {label}
        </Switch.Label>
        <Switch.Description as="span" className="text-sm text-gray-500">
          {helpText}
        </Switch.Description>
      </span>
      <Controller
        name={name}
        control={control}
        defaultValue={value}
        rules={{ required: required }}
        render={({ field: { onChange } }) => (
          <Switch
            checked={enabled === undefined ? false : enabled}
            onChange={(e) => {
              onChange(e)
              setEnabled(e)
            }}
            className={classNames(
              enabled ? 'bg-indigo-600' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                enabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
              )}
            />
          </Switch>
        )}
      />
    </Switch.Group>
  )
}

export { Toggle }
