import Link from 'next/link'
import { useState, useEffect } from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { AwesomeIcon } from './AwesomeIcon'

type ButtonProps = {
  text: any
  href?: string
  hrefTarget?: '_blank' | '_parent' | '_self' | '_top'
  size?: 'XS' | 'SM' | 'MD' | 'LG' | 'XL'
  style?:
    | 'PRIMARY'
    | 'SECONDARY'
    | 'SECONDARY_ON_GRAY'
    | 'WHITE'
    | 'WHITE_MUTED'
    | 'DANGER'
    | 'OVERLAY'
    | 'TEXT'
  buttonType?: 'submit' | 'button' | 'reset'
  icon?: IconDefinition | null
  iconRight?: boolean
  iconClassName?: string
  showloadingIcon?: boolean
  forceShowButton?: boolean
  inline?: boolean
  disabled?: boolean
  className?: string
  onClick?: (e: any) => void
  [key: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const Button = ({
  text,
  href,
  hrefTarget,
  size = 'SM',
  style = 'PRIMARY',
  buttonType = 'button',
  icon,
  iconRight,
  iconClassName = '',
  showloadingIcon = false,
  forceShowButton = false,
  inline = false,
  disabled = false,
  className,
  onClick,
  ...args
}: ButtonProps) => {
  const [sizeClass, setSizeClass] = useState<string>('')
  const [styleClass, setStyleClass] = useState<string>('')
  const [iconSizeClass, setIconSizeClass] = useState<string>('')
  const [iconStyleClass, setIconStyleClass] = useState<string>('')

  useEffect(() => {
    // Set size
    switch (size) {
      case 'XS':
        setSizeClass('px-2.5 py-1.5 text-xs font-medium rounded')
        setIconSizeClass('mr-1 relative')
        break
      case 'SM':
        setSizeClass('px-3 py-2 text-sm leading-4 font-medium rounded-md')
        setIconSizeClass('mr-2 relative')
        break
      case 'MD':
        setSizeClass('px-4 py-2 text-sm font-medium rounded-md')
        setIconSizeClass('mr-2 relative')
        break
      case 'LG':
        setSizeClass('px-4 py-2 text-base font-medium rounded-md')
        setIconSizeClass('mr-3 relative')
        break
      case 'XL':
        setSizeClass('px-6 py-3 text-base font-medium rounded-md')
        setIconSizeClass('mr-3 relative')
        break
      default:
        setSizeClass('px-4 py-2 text-sm font-medium rounded-md')
        setIconSizeClass('mr-2 relative')
        break
    }

    // Set style
    switch (style) {
      case 'PRIMARY':
        setStyleClass(
          'text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 border-transparent border shadow-sm'
        )
        setIconStyleClass('text-white')
        break
      case 'SECONDARY':
        setStyleClass(
          'text-indigo-500 bg-indigo-10 hover:bg-indigo-25 focus:ring-indigo-500 border-transparent border shadow-sm'
        )
        setIconStyleClass('')
        break
      case 'SECONDARY_ON_GRAY':
        setStyleClass(
          'text-indigo-500 bg-white hover:bg-indigo-25 focus:ring-indigo-500 border-transparent border shadow-sm'
        )
        setIconStyleClass('')
        break
      case 'WHITE':
        setStyleClass(
          'text-gray-700 bg-white hover:bg-indigo-10 focus:ring-indigo-500 border-gray-300 border shadow-sm'
        )
        setIconStyleClass('text-indigo-600')
        break
      case 'WHITE_MUTED':
        setStyleClass('text-gray-500 bg-white hover:bg-gray-100 shadow-sm')
        setIconStyleClass('text-indigo-200')
        break
      case 'DANGER':
        setStyleClass(
          'text-white bg-red-600 hover:bg-red-700 focus:ring-red-500 border-transparent border shadow-sm'
        )
        setIconStyleClass('text-white')
        break
      case 'OVERLAY':
        setStyleClass(
          'bg-white bg-opacity-75 backdrop-filter backdrop-blur text-gray-900 text-center'
        )
        setIconStyleClass('')
        break
      case 'TEXT':
        setStyleClass('text-gray-700 focus:ring-indigo-500 hover:bg-indigo-50')
        setIconStyleClass('text-gray-700')
        break
      default:
        setStyleClass(
          'text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 border-transparent border shadow-sm'
        )
        setIconStyleClass('text-white')
        break
    }
  }, [size, style])

  const buttonClicked = (e: React.MouseEvent<HTMLElement>) => {
    if (onClick) onClick(e)
  }

  if (href) {
    return (
      <Link href={href}>
        <a
          className={`${sizeClass} ${styleClass} inline-block border focus:outline-none ${className}`}
          target={hrefTarget ? hrefTarget : '_self'}
          {...args}
        >
          {iconRight && <span>{text}</span>}
          {icon ? (
            <AwesomeIcon
              icon={icon}
              className={classNames(
                'relative',
                text && iconRight ? 'ml-2' : text ? 'mr-2' : '',
                iconStyleClass,
                iconClassName
              )}
            />
          ) : showloadingIcon ? (
            <AwesomeIcon
              icon={duotone('spinner-third')}
              className={classNames(
                'relative',
                text && iconRight ? 'ml-2' : text ? 'mr-2' : '',
                iconStyleClass,
                iconClassName,
                'fa-spin'
              )}
            />
          ) : (
            <></>
          )}
          {!iconRight && <span>{text}</span>}
        </a>
      </Link>
    )
  }

  return (
    <div
      className={classNames(
        style === 'OVERLAY'
          ? 'flex items-end p-4 group-hover:opacity-100'
          : inline
          ? 'inline-flex'
          : '',
        style === 'OVERLAY' && !forceShowButton ? 'opacity-0' : 'opacity-100'
      )}
      aria-hidden={style === 'OVERLAY' ? true : false}
    >
      <button
        type={buttonType}
        className={`${sizeClass} ${styleClass} ${
          style === 'OVERLAY'
            ? ''
            : icon || showloadingIcon
            ? 'inline-flex'
            : ''
        } items-center focus:outline-none ${className}`}
        onClick={(e: React.MouseEvent<HTMLElement>) => buttonClicked(e)}
        disabled={disabled}
        {...args}
      >
        {iconRight && <span>{text}</span>}
        {icon ? (
          <AwesomeIcon
            icon={icon}
            className={classNames(
              'relative',
              text && iconRight ? 'ml-2' : text ? 'mr-2' : '',
              iconStyleClass,
              iconClassName
            )}
          />
        ) : showloadingIcon ? (
          <AwesomeIcon
            icon={duotone('spinner-third')}
            className={classNames(
              'relative',
              text && iconRight ? 'ml-2' : text ? 'mr-2' : '',
              iconStyleClass,
              iconClassName,
              'fa-spin'
            )}
          />
        ) : (
          <></>
        )}
        {!iconRight && <span>{text}</span>}
      </button>
    </div>
  )
}
export { Button }
