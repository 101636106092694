import { useState, useImperativeHandle, forwardRef } from 'react'
import { useAsyncEffect } from 'use-async-effect'
import { HeroIcon } from './HeroIcon'
import { LoadingSpinner } from './LoadingSpinner'

type Step = {
  id: string
  title: string
  icon: string
  errorTitle: string
  showPleaseWaitIfTakingTooLong?: boolean
  task: () => Promise<boolean>
}

type AutoSequenceProps = {
  steps: Step[]
}

export type AutoSequenceRef = {
  restart: () => void
}

const AutoSequence = forwardRef<AutoSequenceRef, AutoSequenceProps>(
  (props, ref) => {
    const [stepsComplete, setStepsComplete] = useState<string[]>([])
    const [stepsWithError, setStepsWithError] = useState<string[]>([])
    const [activeStep, setActiveStep] = useState<number>(0)
    const [activeStepTakingTooLong, setActiveStepTakingTooLong] =
      useState<boolean>(false)
    const [stepsInitialised, setStepsInitialised] = useState<boolean>(false)
    const { steps } = props

    const stepIsProcessing = (index: number) => {
      return index === activeStep
    }
    const stepHasError = (id: string) => {
      return stepsWithError.includes(id)
    }
    const stepIsComplete = (id: string) => {
      return stepsComplete.includes(id)
    }

    const setStepAsComplete = (id: string) => {
      if (!stepIsComplete(id)) {
        setStepsComplete((_stepsComplete) => [..._stepsComplete, id])
      } else {
        setStepsComplete(stepsComplete)
      }
    }

    const setStepWithError = (id: string) => {
      if (!stepHasError(id)) {
        setStepsWithError((_stepsWithError) => [..._stepsWithError, id])
      } else {
        setStepsWithError(stepsWithError)
      }
    }

    const runSequence = async (forceStep?: number) => {
      let run: boolean = true
      let index: number = forceStep !== undefined ? forceStep : activeStep
      /* eslint-disable no-await-in-loop */
      while (run) {
        setActiveStepTakingTooLong(false)
        setActiveStep(index)

        let takingTooLongTimer: NodeJS.Timer | null = null
        if (steps[index].showPleaseWaitIfTakingTooLong) {
          let secondsRunning = 0
          takingTooLongTimer = setInterval(() => {
            if (secondsRunning > 30) setActiveStepTakingTooLong(true)
            secondsRunning += 1
          }, 1000)
        }

        const success = await steps[index].task()
        if (takingTooLongTimer && steps[index].showPleaseWaitIfTakingTooLong)
          clearInterval(takingTooLongTimer)

        if (success) {
          setStepAsComplete(steps[index].id)

          if (index >= steps.length - 1) {
            run = false
          } else index += 1
        } else {
          setStepWithError(steps[index].id)
          run = false
        }
      }
      /* eslint-enable no-await-in-loop */
    }

    useAsyncEffect(async () => {
      if (steps.length > 0 && !stepsInitialised) {
        setStepsInitialised(true)
        await runSequence()
      }
    }, [steps])

    useImperativeHandle(ref, () => ({
      async restart() {
        setStepsComplete([])
        setStepsWithError([])
        await runSequence(0)
      },
    }))

    return (
      <>
        <div className="relative rounded-lg border border-gray-100 bg-white px-6 py-5 space-y-5 shadow-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
          <div className="flow-root">
            <ul className="-mb-8">
              {steps.map((step, stepIndex) => (
                <li key={step.id}>
                  <div className="relative pb-8">
                    {stepIndex !== steps.length - 1 ? (
                      <span
                        className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="relative flex space-x-3">
                      <div>
                        {stepIsComplete(step.id) ? (
                          <span className="h-8 w-8 bg-green-400 rounded-full flex items-center justify-center ring-8 ring-white">
                            <HeroIcon
                              name={step.icon}
                              className="h-5 w-5 text-white"
                            />
                          </span>
                        ) : stepHasError(step.id) ? (
                          <span className="h-8 w-8 bg-red-400 rounded-full flex items-center justify-center ring-8 ring-white">
                            <HeroIcon
                              name="ExclamationIcon"
                              className="h-5 w-5 text-white"
                            />
                          </span>
                        ) : stepIsProcessing(stepIndex) ? (
                          <span className="h-8 w-8 bg-gray-100 rounded-full flex items-center justify-center ring-8 ring-white">
                            <LoadingSpinner
                              colour="indigo"
                              height={5}
                              width={5}
                            />
                          </span>
                        ) : (
                          <span className="h-8 w-8 bg-gray-100 rounded-full flex items-center justify-center ring-8 ring-white">
                            <HeroIcon
                              name={step.icon}
                              className="h-5 w-5 text-gray-300"
                            />
                          </span>
                        )}
                      </div>
                      <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                        <div>
                          <p
                            className={`text-sm ${
                              stepIsComplete(step.id) ||
                              stepHasError(step.id) ||
                              stepIsProcessing(stepIndex)
                                ? 'font-medium'
                                : ''
                            } text-gray-${
                              stepIsComplete(step.id) ||
                              stepHasError(step.id) ||
                              stepIsProcessing(stepIndex)
                                ? '900'
                                : '400'
                            }`}
                          >
                            {stepHasError(step.id)
                              ? step.errorTitle
                              : step.title}
                          </p>
                        </div>
                        <div
                          className={`text-right text-sm whitespace-nowrap text-gray-${
                            stepIsComplete(step.id) ||
                            stepHasError(step.id) ||
                            stepIsProcessing(stepIndex)
                              ? '500'
                              : '300'
                          }`}
                        >
                          {stepIsComplete(step.id)
                            ? 'Complete'
                            : stepHasError(step.id)
                            ? 'Error'
                            : stepIsProcessing(stepIndex)
                            ? activeStepTakingTooLong
                              ? 'Please wait'
                              : 'Processing'
                            : 'Pending'}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </>
    )
  }
)
export { AutoSequence }
