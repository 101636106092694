import { request } from '@/utils/http'
import { ApiResponse, ApiRequestParams } from '@/utils/types'
import { ListingPerkItem } from '@/utils/typings/models'

export const ListingPerkItemInputs: string[] = [
  'listingId',
  'title',
  'description',
]

class ListingPerkModel {
  item: ListingPerkItem = {} as any

  constructor(item?: Partial<ListingPerkItem>) {
    if (item) this.item = { ...this.item, ...item }
  }

  updateLocalItem(item: Partial<ListingPerkItem>) {
    this.item = { ...this.item, ...item }
  }

  prepareDataForSaving(): ApiRequestParams {
    const params: ApiRequestParams = {}
    ListingPerkItemInputs.forEach((key) => {
      const val = this.item[key as keyof ListingPerkItem]
      if (
        typeof val === 'string' ||
        typeof val === 'number' ||
        typeof val === 'boolean'
      )
        params[key] = val
    })
    return params
  }

  async save(): Promise<ListingPerkModel | null> {
    if (this.item?.id) {
      return this.update()
    }
    return this.create()
  }

  async create(): Promise<ListingPerkModel | null> {
    return ListingPerkModel.returnApiSingle(
      await request({
        url: `/api/listings/${this.item.listingId}/perks/create`,
        method: 'POST',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async update(): Promise<ListingPerkModel | null> {
    return ListingPerkModel.returnApiSingle(
      await request({
        url: `/api/listings/${this.item.listingId}/perks/${this.item.id}/update`,
        method: 'PUT',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async delete(): Promise<boolean> {
    const res = await request<boolean>({
      url: `/api/listings/${this.item.listingId}/perks/${this.item.id}/delete`,
      method: 'DELETE',
    })
    return !res.error
  }

  static returnApiSingle(
    res: ApiResponse<ListingPerkItem>
  ): ListingPerkModel | null {
    if (!res.error && res.data) {
      const item: ListingPerkItem = res.data
      return new ListingPerkModel(item)
    }
    return null
  }

  static returnApiMany(
    res: ApiResponse<ListingPerkItem[]>
  ): ListingPerkModel[] {
    if (!res.error && res.data) {
      const listings: ListingPerkItem[] = res.data
      return listings.map((item: ListingPerkItem) => new ListingPerkModel(item))
    }
    return []
  }

  static async getListingPerks(listingId: string): Promise<ListingPerkModel[]> {
    return ListingPerkModel.returnApiMany(
      await request({
        url: `/api/listings/${listingId}/perks/list`,
        method: 'GET',
        params: {},
      })
    )
  }

  static async getListingPerk(
    listingId: string,
    listingPerkId: string
  ): Promise<ListingPerkModel | null> {
    return ListingPerkModel.returnApiSingle(
      await request({
        url: `/api/listings/${listingId}/perks/${listingPerkId}`,
        method: 'GET',
        params: {},
      })
    )
  }
}
export { ListingPerkModel }
