import Link from 'next/link'
import { useState } from 'react'
import { useRouter } from 'next/router'
import { Disclosure } from '@headlessui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { useSession, useApp } from '@/context'
import { AwesomeIcon } from '@/components/ui'
import NavUserDropdown from './NavUserDropdown'

const navigation = [
  { name: 'Explore', href: '/explore', current: false },
  { name: 'Create', href: '/account/listings', current: false },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const NavHeaderPublic = () => {
  const { layout } = useApp()
  const { session } = useSession()
  const router = useRouter()
  const [searchQuery, setSearchQuery] = useState<string>('')

  const onSubmitSearch = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (searchQuery) await router.push(`/explore/?query=${searchQuery}`)
    else await router.push('/explore/')
  }

  return (
    <Disclosure as="nav" className="z-40">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex space-x-8">
                <Link href="/">
                  <a className="flex-shrink-0 flex items-center">
                    {layout.headerBgColour === 'GRAY' ||
                    layout.headerBgColour === 'INDIGO' ? (
                      <>
                        <img
                          className="block lg:hidden h-8 w-auto"
                          src="/img/floor3-mark-transparent.png"
                          alt="Floor3"
                        />
                        <img
                          className="hidden lg:block h-6 w-auto"
                          src="/img/floor3-white.png"
                          alt="Floor3"
                        />
                      </>
                    ) : (
                      <>
                        <img
                          className="block lg:hidden h-8 w-auto"
                          src="/img/floor3-mark.png"
                          alt="Floor3"
                        />
                        <img
                          className="hidden lg:block h-6 w-auto"
                          src="/img/floor3.png"
                          alt="Floor3"
                        />
                      </>
                    )}
                  </a>
                </Link>
                <div className="flex-1 px-2 flex justify-center">
                  <div className="max-w-lg w-full lg:max-w-xs">
                    <form onSubmit={onSubmitSearch}>
                      <label htmlFor="search" className="sr-only">
                        Search
                      </label>
                      <div className="relative text-gray-300 focus-within:text-indigo-300">
                        <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                          <AwesomeIcon
                            icon={regular('magnifying-glass')}
                            className=""
                          />
                        </div>
                        <input
                          id="search"
                          className="block w-full bg-indigo-10 py-2 pl-10 pr-3 border border-transparent rounded-lg leading-5 text-gray-900 placeholder-gray-300 focus:ring-transparent focus:border-transparent focus:bg-indigo-25 sm:text-sm"
                          placeholder="Search"
                          type="text"
                          name="search"
                          defaultValue={
                            router?.query?.query
                              ? router.query.query.toString()
                              : ''
                          }
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setSearchQuery(e.target.value)
                          }
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="hidden sm:-my-px sm:ml-8 sm:flex sm:space-x-8">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        layout.headerBgColour === 'GRAY'
                          ? 'text-gray-300 hover:text-white'
                          : layout.headerBgColour === 'INDIGO'
                          ? 'text-indigo-50 hover:text-indigo-10'
                          : 'text-gray-700 hover:text-indigo-500',
                        item.current ? 'text-gray-800' : '',
                        'inline-flex items-center px-1 pt-1 text-md font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                {session?.user?.id || session?.wallet?.address ? (
                  <NavUserDropdown mode="" />
                ) : (
                  <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                    <Link href="/account/sign-in">
                      <a className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                        Sign in
                      </a>
                    </Link>
                    <Link href="/account/sign-up">
                      <a className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">
                        Sign up
                      </a>
                    </Link>
                  </div>
                )}
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                      : 'border-transparent text-gray-600 hover:bg-indigo-10 hover:border-gray-300 hover:text-gray-800',
                    'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              {session?.user?.id || session?.wallet?.address ? (
                <NavUserDropdown mode="COLLAPSE" />
              ) : (
                <>
                  <div className="mt-3 space-y-1">
                    <Disclosure.Button
                      as="a"
                      href="/account/sign-in"
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                    >
                      Sign In
                    </Disclosure.Button>
                  </div>
                  <div className="mt-3 space-y-1">
                    <Disclosure.Button
                      as="a"
                      href="/account/sign-up"
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                    >
                      Sign Up
                    </Disclosure.Button>
                  </div>
                </>
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default NavHeaderPublic
