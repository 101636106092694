import { request } from '@/utils/http'
import { ApiResponse, ApiRequestParams } from '@/utils/types'
import { IntegrationProperty } from '@/utils/typings/app'
import { BrandDataIntegrationItem } from '@/utils/typings/models'
import { BrandModel } from './BrandModel'

export const BrandDataIntegrationItemInputs: string[] = [
  'propertyId',
  'propertyName',
  'propertySubId',
  'propertySubName',
]

class BrandDataIntegrationModel {
  item: BrandDataIntegrationItem = {} as any

  constructor(item?: Partial<BrandDataIntegrationItem>) {
    if (item) this.item = { ...this.item, ...item }
  }

  prepareDataForSaving(): ApiRequestParams {
    const params: ApiRequestParams = {}
    BrandDataIntegrationItemInputs.forEach((key) => {
      const val = this.item[key as keyof BrandDataIntegrationItem]
      if (
        typeof val === 'string' ||
        typeof val === 'number' ||
        typeof val === 'boolean'
      )
        params[key] = val
    })
    return params
  }

  updateLocalItem(item: Partial<BrandDataIntegrationItem>) {
    this.item = { ...this.item, ...item }
  }

  async save(): Promise<BrandDataIntegrationModel | null> {
    if (this.item?.id) {
      return this.update()
    }
    return this.create()
  }

  async create(): Promise<BrandDataIntegrationModel | null> {
    return BrandDataIntegrationModel.returnApiSingle(
      await request({
        url: `/api/brands/${this.item.brandId}/data-integrations/create`,
        method: 'POST',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async update(): Promise<BrandDataIntegrationModel | null> {
    return BrandDataIntegrationModel.returnApiSingle(
      await request({
        url: `/api/brands/${this.item.brandId}/data-integrations/${this.item.id}/update`,
        method: 'PUT',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async delete(): Promise<ApiResponse<boolean>> {
    return request<boolean>({
      url: `/api/brands/${this.item.brandId}/data-integrations/${this.item.id}/delete`,
      method: 'DELETE',
    })
  }

  // STATIC METHODS
  static returnApiSingle(
    res: ApiResponse<BrandDataIntegrationItem>
  ): BrandDataIntegrationModel | null {
    if (!res.error && res.data) {
      const item: BrandDataIntegrationItem = res.data
      return new BrandDataIntegrationModel(item)
    }
    return null
  }

  static returnApiMany(
    res: ApiResponse<BrandDataIntegrationItem[]>
  ): BrandDataIntegrationModel[] {
    if (!res.error && res.data) {
      const listings: BrandDataIntegrationItem[] = res.data
      return listings.map(
        (item: BrandDataIntegrationItem) => new BrandDataIntegrationModel(item)
      )
    }
    return []
  }

  static async getIntegrations(
    brand: BrandModel
  ): Promise<BrandDataIntegrationModel[]> {
    const res = await request<BrandDataIntegrationItem[]>({
      url: `/api/brands/${brand.item.id}/data-integrations/list`,
      method: 'GET',
      params: {},
    })
    if (!res.error && res.data) {
      const listings: BrandDataIntegrationItem[] = res.data
      return listings.map(
        (item: BrandDataIntegrationItem) => new BrandDataIntegrationModel(item)
      )
    }
    return []
  }

  static async getIntegrationProperties(
    brand: BrandModel,
    provider: string
  ): Promise<IntegrationProperty[]> {
    const res = await request<IntegrationProperty[]>({
      url: `/api/brands/${brand.item.id}/data-integrations/${provider}/properties`,
      method: 'GET',
      params: {},
    })
    if (!res.error && res.data) {
      return res.data
    }
    return []
  }

  static async getIntegrationSubProperties(
    brand: BrandModel,
    provider: string,
    accountId: string,
    propertyId: string
  ): Promise<IntegrationProperty[]> {
    const res = await request<IntegrationProperty[]>({
      url: `/api/brands/${brand.item.id}/data-integrations/${provider}/sub-properties`,
      method: 'GET',
      params: { accountId, propertyId },
    })
    if (!res.error && res.data) {
      return res.data
    }
    return []
  }
}
export { BrandDataIntegrationModel }
