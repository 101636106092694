import { NFTDiscountLog } from '@prisma/client'
import { request } from '@/utils/http'
import { ApiResponse } from '@/utils/types'
import { ListingDiscountItem } from '@/utils/typings/models'

export interface NftDiscountLogItem extends NFTDiscountLog {
  discount: ListingDiscountItem
}

class NFTDiscountLogModel {
  item: NftDiscountLogItem = {} as any

  constructor(item?: Partial<NftDiscountLogItem>) {
    if (item) this.item = { ...this.item, ...item }
  }

  updateLocalItem(item: Partial<NftDiscountLogItem>) {
    this.item = { ...this.item, ...item }
  }

  static returnApiSingle(
    res: ApiResponse<NftDiscountLogItem>
  ): NFTDiscountLogModel | null {
    if (!res.error && res.data) {
      const item: NftDiscountLogItem = res.data
      return new NFTDiscountLogModel(item)
    }
    return null
  }

  static returnApiMany(
    res: ApiResponse<NftDiscountLogItem[]>
  ): NFTDiscountLogModel[] {
    if (!res.error && res.data) {
      const listings: NftDiscountLogItem[] = res.data
      return listings.map(
        (item: NftDiscountLogItem) => new NFTDiscountLogModel(item)
      )
    }
    return []
  }

  static async getNFTDiscountLogs(
    nftId: string
  ): Promise<NFTDiscountLogModel[]> {
    return NFTDiscountLogModel.returnApiMany(
      await request({
        url: `/api/nfts/${nftId}/logs/discounts/list`,
        method: 'GET',
        params: {},
      })
    )
  }

  static async getNFTDiscountLog(
    nftId: string,
    discountId: string,
    owner: string
  ): Promise<NFTDiscountLogModel | null> {
    return NFTDiscountLogModel.returnApiSingle(
      await request({
        url: `/api/nfts/${nftId}/logs/discounts`,
        method: 'GET',
        params: { discountId, owner },
      })
    )
  }
}
export { NFTDiscountLogModel }
