import { useEffect, useRef, useState } from 'react'
import { getInjectedProvider, IProviderInfo } from 'web3modal'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Dialog } from '@headlessui/react'
import { ModalBase, ModalBaseRef, Button, AwesomeIcon } from '@/components/ui'
import { useSession } from '@/context'
import { getWallet } from '@/utils/web3/wallet'

const ConnectWallet = () => {
  const modalBaseRef = useRef<ModalBaseRef>(null)
  const [walletConnected, setWalletConnected] = useState<boolean>(false)
  const [walletError, setWalletError] = useState<string>('')
  const [injectedProvider, setInjectedProvider] =
    useState<IProviderInfo | null>(null)
  let { walletRequested, cancelRequestWallet, setActiveWallet } = useSession()

  const openModal = () => {
    if (modalBaseRef.current) modalBaseRef.current.show()
  }

  const onClose = () => {
    setTimeout(() => {
      setWalletConnected(false)
      cancelRequestWallet()
    }, 500)
  }

  const closeModal = () => {
    if (modalBaseRef.current) modalBaseRef.current.hide()
    onClose()
  }

  const connectProvider = async (providerId?: string) => {
    const { web3Modal } = await getWallet(providerId)
    if (web3Modal) {
      await setActiveWallet(web3Modal)
      setWalletConnected(true)

      if (walletRequested && walletRequested.autoCloseOnSuccess) closeModal()
      if (walletRequested && walletRequested.onSuccess)
        walletRequested.onSuccess()
    } else {
      setWalletError('ERROR')
      if (walletRequested && walletRequested.onError) walletRequested.onError()
    }
  }

  useEffect(() => {
    if (walletRequested) {
      setInjectedProvider(getInjectedProvider())
      openModal()
    }
  }, [walletRequested])

  if (!walletRequested) return <></>
  return (
    <ModalBase ref={modalBaseRef} onClose={onClose}>
      <>
        {walletConnected && !walletRequested.autoCloseOnSuccess ? (
          <>
            <Dialog.Title
              as="h3"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              Wallet Connected
            </Dialog.Title>
            <div className="pt-6 pb-5 flex items-center">
              <AwesomeIcon
                icon={solid('circle-check')}
                className="h-10 w-10 flex-initial text-green-500"
              />
              <span className="flex-1 ml-4">
                {walletRequested.descriptionSuccess
                  ? walletRequested.descriptionSuccess
                  : 'Your wallet is now connected. Press the close button to continue.'}
              </span>
            </div>
            <div className="mt-5 sm:mt-6">
              <div className="sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
                <Button
                  text="Close"
                  size="MD"
                  style="PRIMARY"
                  className="w-full justify-center"
                  onClick={() => {
                    closeModal()
                  }}
                />
              </div>
            </div>
          </>
        ) : walletError ? (
          <>
            <Dialog.Title
              as="h3"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              Could Not Connect
            </Dialog.Title>
            <div className="pt-6 pb-5 flex items-center">
              <AwesomeIcon
                icon={solid('circle-exclamation')}
                className="h-10 w-10 flex-initial text-red-500"
              />
              <span className="flex-1 ml-4">
                {walletRequested.descriptionError
                  ? walletRequested.descriptionError
                  : 'There was a problem connecting to your wallet, or you aborted the process. Please try again.'}
              </span>
            </div>
            <div className="mt-5 sm:mt-6">
              <div className="sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <Button
                  text="Close"
                  size="MD"
                  style="WHITE"
                  className="w-full justify-center"
                  onClick={() => {
                    closeModal()
                  }}
                />
                <Button
                  text="Try Again"
                  size="MD"
                  style="PRIMARY"
                  className="w-full justify-center"
                  onClick={() => {
                    setWalletError('')
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Dialog.Title
              as="h3"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              {walletRequested.title
                ? walletRequested.title
                : 'Connect Your Wallet'}
            </Dialog.Title>
            <div className="space-y-6 pt-6 pb-5">
              {walletRequested.description
                ? walletRequested.description
                : 'To connect your wallet now, please choose your preferred provider below.'}

              <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8 rounded-md overflow-hidden">
                {injectedProvider && (
                  <button
                    type="button"
                    className="col-span-1 flex justify-center py-6 px-6 bg-gray-50 hover:bg-indigo-50 focus:outline-none"
                    onClick={async () => {
                      await connectProvider(injectedProvider.id)
                    }}
                  >
                    {injectedProvider.name === 'MetaMask' ? (
                      <img
                        className="max-h-12"
                        src="/img/providers/metamask.svg"
                        alt={injectedProvider.name}
                      />
                    ) : (
                      <img
                        className="max-h-12"
                        src={injectedProvider.logo}
                        alt={injectedProvider.name}
                      />
                    )}
                  </button>
                )}
                <button
                  type="button"
                  className="col-span-1 flex justify-center py-6 px-6 bg-gray-50 hover:bg-indigo-50 focus:outline-none"
                  onClick={async () => {
                    await connectProvider('coinbase')
                  }}
                >
                  <img
                    className="max-h-12"
                    src="/img/providers/coinbase.svg"
                    alt="Coinbase Wallet"
                  />
                </button>
                <button
                  type="button"
                  className="col-span-1 flex justify-center py-6 px-6 bg-gray-50 hover:bg-indigo-50 focus:outline-none"
                  onClick={async () => {
                    await connectProvider('walletconnect')
                  }}
                >
                  <img
                    className="max-h-12"
                    src="/img/providers/walletconnect.svg"
                    alt="WalletConnect"
                  />
                </button>
                <button
                  type="button"
                  className="col-span-1 flex justify-center py-6 px-6 bg-gray-50 hover:bg-indigo-50 focus:outline-none"
                  onClick={async () => {
                    await connectProvider('fortmatic')
                  }}
                >
                  <img
                    className="max-h-12"
                    src="/img/providers/fortmatic.svg"
                    alt="Fortmatic"
                  />
                </button>
                <button
                  type="button"
                  className="col-span-1 flex justify-center py-6 px-6 bg-gray-50 hover:bg-indigo-50 focus:outline-none"
                  onClick={async () => {
                    await connectProvider('torus')
                  }}
                >
                  <img
                    className="max-h-12"
                    src="/img/providers/toruswallet.svg"
                    alt="Torus Wallet"
                  />
                </button>
                <button
                  type="button"
                  className="col-span-1 flex justify-center py-6 px-6 bg-gray-50 hover:bg-indigo-50 focus:outline-none"
                  onClick={async () => {
                    await connectProvider('authereum')
                  }}
                >
                  <img
                    className="max-h-12"
                    src="/img/providers/authereum.svg"
                    alt="Authereum"
                  />
                </button>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <div className="sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
                <Button
                  text="Cancel"
                  size="MD"
                  style="WHITE"
                  className="w-full justify-center"
                  onClick={() => {
                    closeModal()
                  }}
                />
              </div>
            </div>
          </>
        )}
      </>
    </ModalBase>
  )
}
export { ConnectWallet }
