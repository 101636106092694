import { useEffect, useState } from 'react'
import { HeroIcon, Alert } from '@/components/ui'
import { getWalletAddress, getWalletBalance } from '@/utils/web3/wallet'
import { formatNumberDP, numberWithCommas } from '@/utils/misc'
import { useSession } from '@/context'

type ConnectedWalletProps = {
  walletMustMatchAddress?: string | null
  walletDetectedText?: string
  hideWalletDetectedText?: boolean
  walletAddressDoesntMatchText?: string
  walletNotDetectedText?: string
  onWalletChange?: (walletAddress: string) => void
}

const ConnectedWallet = ({
  walletMustMatchAddress,
  walletDetectedText,
  hideWalletDetectedText,
  walletAddressDoesntMatchText,
  walletNotDetectedText,
  onWalletChange,
}: ConnectedWalletProps) => {
  const [walletAddress, setWalletAddress] = useState<string>('')
  const [walletBalance, setWalletBalance] = useState<number>(0)
  const { session, requestWallet, refreshSession } = useSession()

  const _getWalletAddress = async () => {
    setWalletAddress(await getWalletAddress())
  }
  const _getWalletBalance = async () => {
    setWalletBalance(await getWalletBalance())
  }

  useEffect(() => {
    async function refreshWallet() {
      if (session?.wallet) {
        await _getWalletAddress()
        await _getWalletBalance()
      }
    }
    if (onWalletChange) onWalletChange(walletAddress)
    if (!walletAddress) {
      refreshWallet()
        .then(() => {})
        .catch(() => {})
    }
  }, [walletAddress, onWalletChange, session?.wallet])

  return (
    <>
      {walletAddress &&
      !hideWalletDetectedText &&
      (!walletMustMatchAddress || walletMustMatchAddress === walletAddress) ? (
        <p>
          {walletDetectedText
            ? `${walletDetectedText}`
            : `We've detected the following wallet. To use a different wallet, please change it via the MetaMask browser plugin.`}
        </p>
      ) : walletAddress &&
        !hideWalletDetectedText &&
        walletMustMatchAddress &&
        walletMustMatchAddress !== walletAddress ? (
        <Alert
          body={
            walletAddressDoesntMatchText
              ? `${walletAddressDoesntMatchText}`
              : "The wallet connected doesn't match the one that was expected. Please choose the correct wallet via the MetaMask browser plugin."
          }
          style="DANGER"
        />
      ) : !walletAddress ? (
        <Alert
          body={
            walletNotDetectedText
              ? `${walletNotDetectedText}`
              : 'Wallet not connected'
          }
          onLinkClick={async () => {
            if (!session?.wallet) {
              requestWallet({
                description:
                  'To connect your wallet, choose your preferred provider below.',
                autoCloseOnSuccess: true,
                onSuccess: async () => {
                  await refreshSession()
                },
              })
            } else {
              await refreshSession()
            }
          }}
          linkText="Connect Wallet"
          style="DANGER"
        />
      ) : (
        <></>
      )}
      {walletAddress &&
        (!walletMustMatchAddress ||
          walletMustMatchAddress === walletAddress) && (
          <div className="grid grid-cols-1 gap-4">
            <div
              key="wallet"
              className="relative rounded-lg border border-gray-100 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
            >
              <div className="flex-shrink-0">
                <span className="bg-indigo-50 text-indigo-700 rounded-lg inline-flex p-3 ring-4 ring-white mr-2">
                  <HeroIcon name="CashIcon" className="h-6 w-6" />
                </span>
              </div>
              <div className="flex-1 min-w-0">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-medium text-gray-900 truncate">
                  {walletAddress}
                </p>
                <p className="text-sm text-gray-500 truncate">
                  Balance: {numberWithCommas(formatNumberDP(walletBalance, 2))}{' '}
                  ETH
                </p>
              </div>
            </div>
          </div>
        )}
    </>
  )
}
export { ConnectedWallet }
