import { NFTContentLog } from '@prisma/client'
import { request } from '@/utils/http'
import { ApiResponse } from '@/utils/types'
import { NftItem } from '@/utils/typings/models'

export interface NftContentLogItem extends NFTContentLog {
  nft: NftItem
}

class NFTContentLogModel {
  item: NftContentLogItem = {} as any

  constructor(item?: Partial<NftContentLogItem>) {
    if (item) this.item = { ...this.item, ...item }
  }

  updateLocalItem(item: Partial<NftContentLogItem>) {
    this.item = { ...this.item, ...item }
  }

  static returnApiSingle(
    res: ApiResponse<NftContentLogItem>
  ): NFTContentLogModel | null {
    if (!res.error && res.data) {
      const item: NftContentLogItem = res.data
      return new NFTContentLogModel(item)
    }
    return null
  }

  static returnApiMany(
    res: ApiResponse<NftContentLogItem[]>
  ): NFTContentLogModel[] {
    if (!res.error && res.data) {
      const listings: NftContentLogItem[] = res.data
      return listings.map(
        (item: NftContentLogItem) => new NFTContentLogModel(item)
      )
    }
    return []
  }

  static async getNFTContentLogs(
    nftId: string,
    address: string
  ): Promise<NFTContentLogModel[]> {
    return NFTContentLogModel.returnApiMany(
      await request({
        url: `/api/nfts/${nftId}/logs/content/list`,
        method: 'GET',
        params: { address },
      })
    )
  }

  static async getNFTContentLog(
    nftId: string,
    contentId: string,
    owner: string
  ): Promise<NFTContentLogModel | null> {
    return this.returnApiSingle(
      await request({
        url: `/api/nfts/${nftId}/logs/content`,
        method: 'GET',
        params: { contentId, owner },
      })
    )
  }

  static async saveNFTContentLog(
    nftId: string,
    contentId: string,
    owner: string
  ): Promise<NFTContentLogModel | null> {
    return this.returnApiSingle(
      await request({
        url: `/api/nfts/${nftId}/logs/content/create`,
        method: 'POST',
        params: { contentId, owner },
      })
    )
  }
}
export { NFTContentLogModel }
