import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useApp } from '@/context'
import { Button } from './Button'
import { AwesomeIcon } from './AwesomeIcon'

const InfoModal = () => {
  const { notification } = useApp()
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (notification && notification.title && notification.style === 'MODAL') {
      setShow(true)
    } else setShow(false)
  }, [notification])

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setShow}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="flex items-center">
                    {notification.iconCustom && (
                      <div className="flex items-center justify-center h-12 w-12 mr-4 rounded-full bg-indigo-100">
                        <AwesomeIcon
                          icon={notification.iconCustom}
                          className="h-6 w-6 text-indigo-600"
                        />
                      </div>
                    )}
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {notification.title}
                    </Dialog.Title>
                  </div>
                  {notification.description && (
                    <div className="mt-5 text-sm text-gray-500">
                      {notification.description}
                    </div>
                  )}
                </div>
                <div className="mt-5">
                  <Button
                    text="Close"
                    size="MD"
                    style="PRIMARY"
                    className="w-full justify-center"
                    onClick={() => setShow(false)}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
export { InfoModal }
