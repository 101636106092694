import { request } from '@/utils/http'
import { serialize } from '@/utils/misc'
import { ethers } from 'ethers'
import { ApiResponse, ApiRequestParams, NftImageTheme } from '@/utils/types'
import { Session } from '@/utils/typings/app'
import { getLazyMinter } from '@/utils/web3/lazyMinter'
import { getWallet } from '@/utils/web3/wallet'
import { ListingItem } from '@/utils/typings/models'
import { FilterOptions, PaginationOptions } from '@/utils/typings/db'
import { IncomingMessage } from 'node:http'
import { Floor3NFT } from '@/contracts/typings'

export const ListingItemInputs: string[] = [
  'name',
  'description',
  'strapline',
  'supply',
  'price',
  'royaltyPercentage',
  'affiliatePercentage',
  'affiliateUpFrontPercentage',
  'brandId',
  'walletAddress',
  'lazyMintingSignature',
  'publishTxHash',
  'publicCommunity',
  'privateCommunity',
]

class ListingModel {
  item: ListingItem = {} as ListingItem

  constructor(item?: Partial<ListingItem>) {
    if (item) this.item = { ...this.item, ...item }
  }

  public get relativeURL() {
    return `/${this.item.brand.slug}/${this.item.slug}`
  }

  public get absoluteURL() {
    return `${process.env.NEXT_PUBLIC_HTTP_PROTOCOL}${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/${this.item.brand.slug}/${this.item.slug}/`
  }

  public get nftImageUrl() {
    return `${process.env.NEXT_PUBLIC_SUPABASE_PUBLIC_STORAGE_URL}/listings/${this.item.id}/${this.item.nftImage}`
  }

  public get voucherRaw(): Floor3NFT.ListingVoucherStruct | null {
    if (!this.item.price || !this.item.brand) return null
    const price = ethers.utils
      .parseUnits(this.item.price.toString(), 'ether')
      .toString()
    return {
      brandId: this.item.brand.id,
      listingId: this.item.id,
      supply: this.item.supply,
      minPrice: price,
      adminFeePerc: Number(process.env.ADMIN_FEE) * 100,
      royaltyPercentage: Number(this.item.royaltyPercentage) * 100,
      affiliatePercentage: Number(this.item.affiliatePercentage) * 100,
      affiliateUpFrontPercentage:
        Number(this.item.affiliateUpFrontPercentage) * 100,
    }
  }

  async createTokenVoucherSignature(): Promise<string> {
    try {
      const { signer } = await getWallet()
      if (!signer) throw new Error("Signer doesn't exist")
      const lazyMinter = getLazyMinter(signer)
      const _voucherRaw = this.voucherRaw
      if (!_voucherRaw) throw new Error("Couldn't create voucher")

      const lazyVoucher = await lazyMinter.createListingVoucher(_voucherRaw)
      this.item.lazyMintingSignature = lazyVoucher.signature
      await this.save()
      return this.item.lazyMintingSignature
    } catch {
      return ''
    }
  }

  // Analytics
  async analyticsViewsLog(): Promise<{ views: number }> {
    const res = await request<{ views: number }>({
      url: `/api/analytics/views/log`,
      method: 'POST',
      params: { entity: 'listing', entityId: this.item.id },
    })
    if (res.data) return res.data
    return { views: 0 }
  }
  async analyticsViewsStatus(): Promise<{ views: number }> {
    const res = await request<{ views: number }>({
      url: `/api/analytics/views/status`,
      method: 'GET',
      params: { entity: 'listing', entityId: this.item.id },
    })
    if (res.data) return res.data
    return { views: 0 }
  }

  // Voting
  async analyticsVotesLog(
    session: Session,
    downVote?: boolean
  ): Promise<number | null> {
    if (session.wallet?.address) {
      const res = await request<{ totalVotes: number }>({
        url: `/api/analytics/votes/log`,
        method: 'POST',
        params: {
          entity: 'listing',
          entityId: this.item.id,
          downVote: downVote !== undefined ? downVote : false,
        },
      })
      if (res.data) return res.data.totalVotes
    }
    return null
  }

  async analyticsVotesStatus(): Promise<{ voted: boolean }> {
    const res = await request<{ voted: boolean }>({
      url: `/api/analytics/votes/status`,
      method: 'GET',
      params: { entity: 'listing', entityId: this.item.id },
    })
    if (res.data) return res.data
    return { voted: false }
  }

  // Image methods
  async getImagePreview(): Promise<ApiResponse<{ dataUrl: string }>> {
    // Returns a data URL string representing the preview of the generated image
    return request<{ dataUrl: string }>({
      url: `/api/listings/${this.item.id}/image/generate`,
      method: 'POST',
      params: { preview: '1' },
    })
  }

  async updateImage(
    theme: NftImageTheme,
    saveImage?: boolean
  ): Promise<ApiResponse<{ listing: ListingItem; dataUrl?: string }>> {
    // Returns a data URL string representing the preview of the generated image
    return request<{ listing: ListingItem; dataUrl?: string }>({
      url: `/api/listings/${this.item.id}/image/update`,
      method: 'PUT',
      params: {
        theme: serialize(theme),
        saveImage: saveImage ? saveImage : false,
      },
    })
  }

  updateLocalItem(item: Partial<ListingItem>) {
    this.item = { ...this.item, ...item }
  }

  prepareDataForSaving(): ApiRequestParams {
    const params: ApiRequestParams = {}
    ListingItemInputs.forEach((key) => {
      const val = this.item[key as keyof ListingItem]
      if (
        typeof val === 'string' ||
        typeof val === 'number' ||
        typeof val === 'boolean'
      )
        params[key] = val
    })
    return params
  }

  async save(): Promise<ListingModel | null> {
    if (this.item?.id) {
      return this.update()
    }
    return this.create()
  }

  async create(): Promise<ListingModel | null> {
    return ListingModel.returnApiSingle(
      await request<ListingItem>({
        url: '/api/listings/create',
        method: 'POST',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async update(): Promise<ListingModel | null> {
    return ListingModel.returnApiSingle(
      await request({
        url: `/api/listings/${this.item.id}/update`,
        method: 'PUT',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async updatePage(formData: FormData): Promise<ListingModel | null> {
    return ListingModel.returnApiSingle(
      await request({
        url: `/api/listings/${this.item.id}/page/update`,
        method: 'PUT',
        params: formData,
        useFormData: true,
      })
    )
  }

  async delete(): Promise<boolean> {
    const res = await request<boolean>({
      url: `/api/listings/${this.item.id}/delete`,
      method: 'DELETE',
    })
    return !res.error
  }

  static async getPublishedListings(
    filters?: FilterOptions,
    query?: string,
    orderBy?: string,
    pagination?: PaginationOptions
  ): Promise<{
    items: ListingModel[]
    pagination?: PaginationOptions
  }> {
    const params = {
      filters: '',
      query: '',
      orderBy: '',
      pagination: '',
    }
    if (filters) params.filters = JSON.stringify(filters)
    if (query) params.query = query
    if (orderBy) params.orderBy = orderBy
    if (pagination) params.pagination = JSON.stringify(pagination)

    const res = await request<{
      items: ListingItem[]
      pagination: string
    }>({
      url: '/api/listings/list',
      method: 'GET',
      params: params,
    })
    if (!res.error && res.data) {
      const listings: ListingItem[] = res.data.items
      return {
        items: listings.map((item: ListingItem) => new ListingModel(item)),
        pagination: JSON.parse(res.data.pagination),
      }
    }
    return { items: [] }
  }

  static async getPublishedListingsRaw(
    filters?: FilterOptions,
    orderBy?: string
  ): Promise<{
    items: ListingItem[]
  }> {
    const params = {
      filters: '',
      orderBy: '',
      pagination: '',
    }
    if (filters) params.filters = JSON.stringify(filters)
    if (orderBy) params.orderBy = orderBy

    const res = await request<{
      items: ListingItem[]
      pagination: string
    }>({
      url: '/api/listings/list',
      method: 'GET',
      params: params,
    })
    if (!res.error && res.data) {
      const listings: ListingItem[] = res.data.items
      return { items: listings }
    }
    return { items: [] }
  }

  static returnApiSingle(res: ApiResponse<ListingItem>): ListingModel | null {
    if (!res.error && res.data) {
      const item: ListingItem = res.data
      return new ListingModel(item)
    }
    return null
  }

  static returnApiMany(res: ApiResponse<ListingItem[]>): ListingModel[] {
    if (!res.error && res.data) {
      const items: ListingItem[] = res.data
      return items.map((item: ListingItem) => new ListingModel(item))
    }
    return []
  }

  static async getListings(session: Session): Promise<ListingModel[]> {
    if (session.user) {
      const res = await request<{
        items: ListingItem[]
        pagination: PaginationOptions
      }>({
        url: '/api/listings/list',
        method: 'GET',
        params: { ownerId: session.user.id },
      })
      if (!res.error && res.data) {
        return res.data.items.map((item: ListingItem) => new ListingModel(item))
      }
    }
    return []
  }

  static async getListingsRaw(
    session: Session,
    req?: IncomingMessage
  ): Promise<ListingItem[]> {
    if (session.user) {
      const res = await request<{
        items: ListingItem[]
        pagination: PaginationOptions
      }>({
        url: '/api/listings/list',
        method: 'GET',
        params: { ownerId: session.user.id },
        req: req,
      })
      if (!res.error && res.data) {
        return res.data.items
      }
    }
    return []
  }

  static async getListing(listingId: string): Promise<ListingModel | null> {
    return ListingModel.returnApiSingle(
      await request<ListingItem>({
        url: `/api/listings/${listingId}`,
        method: 'GET',
        params: {},
      })
    )
  }

  // Gets the listing by the slug. Brand slug is also required since the listing
  // slug can only be gauranteed to be unique within a brand
  static async getListingBySlug(
    brandSlug: string,
    listingSlug: string
  ): Promise<ListingModel | null> {
    return ListingModel.returnApiSingle(
      await request<ListingItem>({
        url: `/api/listings/by-slug/${listingSlug}`,
        method: 'GET',
        params: { brandSlug },
      })
    )
  }

  static async getListingBySlugRaw(
    brandSlug: string,
    listingSlug: string
  ): Promise<ListingItem | null> {
    const res = await request<ListingItem>({
      url: `/api/listings/by-slug/${listingSlug}`,
      method: 'GET',
      params: { brandSlug },
    })
    if (!res.error && res.data) {
      return res.data
    }
    return null
  }
}
export { ListingModel }
