import { request } from '@/utils/http'
import { ApiResponse, ApiRequestParams } from '@/utils/types'
import { BrandUpdateItem } from '@/utils/typings/models'

export const BrandUpdateItemInputs: string[] = ['brandId', 'title', 'body']

class BrandUpdateModel {
  item: BrandUpdateItem = {} as any

  constructor(item?: Partial<BrandUpdateItem>) {
    if (item) this.item = { ...this.item, ...item }
  }

  updateLocalItem(item: Partial<BrandUpdateItem>) {
    this.item = { ...this.item, ...item }
  }

  prepareDataForSaving(): ApiRequestParams {
    const params: ApiRequestParams = {}
    BrandUpdateItemInputs.forEach((key) => {
      const val = this.item[key as keyof BrandUpdateItem]
      if (
        typeof val === 'string' ||
        typeof val === 'number' ||
        typeof val === 'boolean'
      )
        params[key] = val
    })
    return params
  }

  async save(): Promise<BrandUpdateModel | null> {
    if (this.item?.id) {
      return this.update()
    }
    return this.create()
  }

  async create(): Promise<BrandUpdateModel | null> {
    return BrandUpdateModel.returnApiSingle(
      await request({
        url: `/api/brands/${this.item.brandId}/updates/create`,
        method: 'POST',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async update(): Promise<BrandUpdateModel | null> {
    return BrandUpdateModel.returnApiSingle(
      await request({
        url: `/api/brands/${this.item.brandId}/updates/${this.item.id}/update`,
        method: 'PUT',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async delete(): Promise<boolean> {
    const res = await request<boolean>({
      url: `/api/brands/${this.item.brandId}/updates/${this.item.id}/delete`,
      method: 'DELETE',
    })
    return !res.error
  }

  static returnApiSingle(
    res: ApiResponse<BrandUpdateItem>
  ): BrandUpdateModel | null {
    if (!res.error && res.data) {
      const item: BrandUpdateItem = res.data
      return new BrandUpdateModel(item)
    }
    return null
  }

  static returnApiMany(
    res: ApiResponse<BrandUpdateItem[]>
  ): BrandUpdateModel[] {
    if (!res.error && res.data) {
      const items: BrandUpdateItem[] = res.data
      return items.map((item: BrandUpdateItem) => new BrandUpdateModel(item))
    }
    return []
  }

  static async getBrandUpdate(brandId: string): Promise<BrandUpdateModel[]> {
    return BrandUpdateModel.returnApiMany(
      await request({
        url: `/api/brands/${brandId}/updates/list`,
        method: 'GET',
        params: {},
      })
    )
  }

  static async getBrandUpdates(brandId: string): Promise<BrandUpdateItem[]> {
    const res = await request<BrandUpdateItem[]>({
      url: `/api/brands/${brandId}/updates/list`,
      method: 'GET',
      params: {},
    })
    if (res.data) return res.data
    return []
  }

  static async getBrandUpdateItem(
    brandId: string,
    brandUpdateId: string
  ): Promise<BrandUpdateModel | null> {
    return BrandUpdateModel.returnApiSingle(
      await request({
        url: `/api/brands/${brandId}/updates/${brandUpdateId}`,
        method: 'GET',
        params: {},
      })
    )
  }
}
export { BrandUpdateModel }
