import dynamic from 'next/dynamic'
import { ComponentType } from 'react'

type Props = {
  name: string
  className?: string
  outline?: boolean
  containerBackground?: string
  containerRounded?: string
  containerPadding?: string
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const HeroIcon = ({
  name,
  className = 'h-5 w-5 text-gray-300',
  outline = false,
  containerBackground,
  containerRounded = 'rounded-lg',
  containerPadding = 'p-3',
}: Props) => {
  const Icon: ComponentType<{ className: string }> = outline
    ? dynamic(
        () => import('@heroicons/react/outline').then((mod: any) => mod[name]) // eslint-disable-line @typescript-eslint/no-explicit-any,  @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
      )
    : dynamic(
        () => import('@heroicons/react/solid').then((mod: any) => mod[name]) // eslint-disable-line @typescript-eslint/no-explicit-any,  @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
      )

  if (containerBackground) {
    return (
      <span
        className={classNames(
          'text-teal-700',
          containerBackground,
          containerRounded,
          containerPadding,
          'inline-flex focus:outline-none focus:ring-0'
        )}
      >
        <Icon className={classNames(className)} aria-hidden />
      </span>
    )
  } else {
    return <Icon className={className} aria-hidden />
  }
}

export { HeroIcon }
