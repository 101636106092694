import { request } from '@/utils/http'
import { ApiResponse, ApiRequestParams } from '@/utils/types'
import { ListingDiscountItem } from '@/utils/typings/models'

export const ListingDiscountItemInputs: string[] = [
  'listingId',
  'discountType',
  'percentageOff',
  'fixedAmount',
  'fixedAmountCurrency',
  'durationType',
  'durationMonths',
  'redeemType',
  'redeemProvider',
  'redeemProviderKey',
]

class ListingDiscountModel {
  item: ListingDiscountItem = {} as any

  constructor(item?: Partial<ListingDiscountItem>) {
    if (item) this.item = { ...this.item, ...item }
  }

  get discountSummary() {
    if (this.item.discountType === 'PercentageOff') {
      return `${this.item.percentageOff}% ${this.discountDurationSummary}`
    }
    return `${this.item.fixedAmount} ${this.item.fixedAmountCurrency} ${this.discountDurationSummary}`
  }

  get discountDurationSummary() {
    if (this.item.durationType === 'Forever') {
      return 'lifetime discount'
    }
    if (this.item.durationType === 'Once') {
      return 'off your next purchase'
    }
    if (
      this.item.durationType === 'MultipleMonths' &&
      this.item.durationMonths
    ) {
      return `${this.item.durationMonths} month discount`
    }
    return ''
  }

  get discountValue() {
    return this.item.discountType === 'PercentageOff'
      ? `${this.item.percentageOff}%`
      : `${this.item.fixedAmountCurrency} ${this.item.fixedAmount}`
  }

  updateLocalItem(item: Partial<ListingDiscountItem>) {
    this.item = { ...this.item, ...item }
  }

  prepareDataForSaving(): ApiRequestParams {
    const params: ApiRequestParams = {}
    ListingDiscountItemInputs.forEach((key) => {
      const val = this.item[key as keyof ListingDiscountItem]
      if (
        typeof val === 'string' ||
        typeof val === 'number' ||
        typeof val === 'boolean'
      )
        params[key] = val
    })
    return params
  }

  async save(): Promise<ListingDiscountModel | null> {
    if (this.item?.id) {
      return this.update()
    }
    return this.create()
  }

  async create(): Promise<ListingDiscountModel | null> {
    return ListingDiscountModel.returnApiSingle(
      await request({
        url: `/api/listings/${this.item.listingId}/discounts/create`,
        method: 'POST',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async update(): Promise<ListingDiscountModel | null> {
    return ListingDiscountModel.returnApiSingle(
      await request({
        url: `/api/listings/${this.item.listingId}/discounts/${this.item.id}/update`,
        method: 'PUT',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async delete(): Promise<boolean> {
    const res = await request<boolean>({
      url: `/api/listings/${this.item.listingId}/discounts/${this.item.id}/delete`,
      method: 'DELETE',
    })
    return !res.error
  }

  static returnApiSingle(
    res: ApiResponse<ListingDiscountItem>
  ): ListingDiscountModel | null {
    if (!res.error && res.data) {
      const item: ListingDiscountItem = res.data
      return new ListingDiscountModel(item)
    }
    return null
  }

  static returnApiMany(
    res: ApiResponse<ListingDiscountItem[]>
  ): ListingDiscountModel[] {
    if (!res.error && res.data) {
      const listings: ListingDiscountItem[] = res.data
      return listings.map(
        (item: ListingDiscountItem) => new ListingDiscountModel(item)
      )
    }
    return []
  }

  static async getListingDiscounts(
    listingId: string
  ): Promise<ListingDiscountModel[]> {
    return ListingDiscountModel.returnApiMany(
      await request({
        url: `/api/listings/${listingId}/discounts/list`,
        method: 'GET',
        params: {},
      })
    )
  }

  static async getListingDiscount(
    listingId: string,
    listingDiscountId: string
  ): Promise<ListingDiscountModel | null> {
    return ListingDiscountModel.returnApiSingle(
      await request({
        url: `/api/listings/${listingId}/discounts/${listingDiscountId}`,
        method: 'GET',
        params: {},
      })
    )
  }
}
export { ListingDiscountModel }
