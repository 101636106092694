import { SelectChoiceType } from '@/components/form'

/* eslint-disable @typescript-eslint/no-unsafe-return */
export const serialize = (obj: any) => {
  // const { dob, ...rest } = person
  return {
    ...obj,
  }
}
/* eslint-enable @typescript-eslint/no-unsafe-return */

export const truncateChars = (
  str: string,
  chars: number,
  ignoreDots?: boolean
) => {
  if (str.length <= chars) return str
  return `${str.slice(0, Math.max(0, chars))}${!ignoreDots ? '...' : ''}`
}

export const range = (
  size: number,
  startAt: number = 0
): ReadonlyArray<number> => {
  return [...Array.from({ length: size }).keys()].map((i) => i + startAt)
}

export const removeItem = <T>(arr: Array<T>, value: T): Array<T> => {
  const index = arr.indexOf(value)
  if (index > -1) {
    arr.splice(index, 1)
  }
  return arr
}

export const pluralize = (word: string, count: number, suffix = 's') =>
  `${word}${count !== 1 ? suffix : ''}`

export const defaultIfNone = <T>(
  val: T | undefined | null,
  defaultVal: T
): T => {
  return val ? val : defaultVal
}

export const strToBool = (val: string): Boolean => {
  if (
    val.toLowerCase() === 'true' ||
    val === '1' ||
    val.toLowerCase() === 'yes'
  )
    return true
  return false
}

export const numberKMFormatter = (num: number) => {
  const digits = 1
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.\d*[1-9])0+$/
  let item = [...lookup].reverse().find((i) => {
    return num >= i.value
  })
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0'
}

export const formatNumberDP = (num: number, numberOfZeros: number) => {
  return Number((Math.round(num * 100) / 100).toFixed(numberOfZeros))
}

export const numberWithCommas = (x: number, numberOfZeros?: number) => {
  let returnNum = x
  if (numberOfZeros) {
    returnNum = formatNumberDP(returnNum, numberOfZeros)
  }
  return returnNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const addSpaceBeforeCapitalLetter = (str: string) => {
  return str.replace(/([A-Z])/g, ' $1').trim()
}

export const capitalizeFirstLetter = (str: string) => {
  return str.slice(0, 1).toUpperCase() + str.slice(1)
}

export const titleCase = (str: string) => {
  const ary = str.toLowerCase().split(' ')
  for (let i = 0; i < ary.length; i += 1) {
    ary[i] = ary[i].charAt(0).toUpperCase() + ary[i].slice(1)
  }
  return ary.join(' ')
}

export const addParamToUrl = (url: string, key: string, value: string) => {
  let _url = new URL(url)
  _url.searchParams.set(key, value)
  return _url.toString()
}

export const displayUrl = (url: string) => {
  // Removes the http protocol and www if needed, to create a cleaner display version
  return url.replace('http://', '').replace('https://', '').replace('www.', '')
}

export const industryList = [
  { code: 47, groups: 'corp, fin', name: 'Accounting' },
  { code: 94, groups: 'man, tech, tran', name: 'Airlines/Aviation' },
  {
    code: 120,
    groups: 'leg, org',
    name: 'Alternative Dispute Resolution',
  },
  { code: 125, groups: 'hlth', name: 'Alternative Medicine' },
  { code: 127, groups: 'art, med', name: 'Animation' },
  { code: 19, groups: 'good', name: 'Apparel & Fashion' },
  { code: 50, groups: 'cons', name: 'Architecture & Planning' },
  { code: 111, groups: 'art, med, rec', name: 'Arts and Crafts' },
  { code: 53, groups: 'man', name: 'Automotive' },
  { code: 52, groups: 'gov, man', name: 'Aviation & Aerospace' },
  { code: 41, groups: 'fin', name: 'Banking' },
  { code: 12, groups: 'gov, hlth, tech', name: 'Biotechnology' },
  { code: 36, groups: 'med, rec', name: 'Broadcast Media' },
  { code: 49, groups: 'cons', name: 'Building Materials' },
  {
    code: 138,
    groups: 'corp, man',
    name: 'Business Supplies and Equipment',
  },
  { code: 129, groups: 'fin', name: 'Capital Markets' },
  { code: 54, groups: 'man', name: 'Chemicals' },
  { code: 90, groups: 'org, serv', name: 'Civic & Social Organization' },
  { code: 51, groups: 'cons, gov', name: 'Civil Engineering' },
  {
    code: 128,
    groups: 'cons, corp, fin',
    name: 'Commercial Real Estate',
  },
  { code: 118, groups: 'tech', name: 'Computer & Network Security' },
  { code: 109, groups: 'med, rec', name: 'Computer Games' },
  { code: 3, groups: 'tech', name: 'Computer Hardware' },
  { code: 5, groups: 'tech', name: 'Computer Networking' },
  { code: 4, groups: 'tech', name: 'Computer Software' },
  { code: 48, groups: 'cons', name: 'Construction' },
  { code: 24, groups: 'good, man', name: 'Consumer Electronics' },
  { code: 25, groups: 'good, man', name: 'Consumer Goods' },
  { code: 91, groups: 'org, serv', name: 'Consumer Services' },
  { code: 18, groups: 'good', name: 'Cosmetics' },
  { code: 65, groups: 'agr', name: 'Dairy' },
  { code: 1, groups: 'gov, tech', name: 'Defense & Space' },
  { code: 99, groups: 'art, med', name: 'Design' },
  { code: 69, groups: 'edu', name: 'Education Management' },
  { code: 132, groups: 'edu, org', name: 'E-Learning' },
  {
    code: 112,
    groups: 'good, man',
    name: 'Electrical/Electronic Manufacturing',
  },
  { code: 28, groups: 'med, rec', name: 'Entertainment' },
  { code: 86, groups: 'org, serv', name: 'Environmental Services' },
  { code: 110, groups: 'corp, rec, serv', name: 'Events Services' },
  { code: 76, groups: 'gov', name: 'Executive Office' },
  { code: 122, groups: 'corp, serv', name: 'Facilities Services' },
  { code: 63, groups: 'agr', name: 'Farming' },
  { code: 43, groups: 'fin', name: 'Financial Services' },
  { code: 38, groups: 'art, med, rec', name: 'Fine Art' },
  { code: 66, groups: 'agr', name: 'Fishery' },
  { code: 34, groups: 'rec, serv', name: 'Food & Beverages' },
  { code: 23, groups: 'good, man, serv', name: 'Food Production' },
  { code: 101, groups: 'org', name: 'Fund-Raising' },
  { code: 26, groups: 'good, man', name: 'Furniture' },
  { code: 29, groups: 'rec', name: 'Gambling & Casinos' },
  { code: 145, groups: 'cons, man', name: 'Glass, Ceramics & Concrete' },
  { code: 75, groups: 'gov', name: 'Government Administration' },
  { code: 148, groups: 'gov', name: 'Government Relations' },
  { code: 140, groups: 'art, med', name: 'Graphic Design' },
  {
    code: 124,
    groups: 'hlth, rec',
    name: 'Health, Wellness and Fitness',
  },
  { code: 68, groups: 'edu', name: 'Higher Education' },
  { code: 14, groups: 'hlth', name: 'Hospital & Health Care' },
  { code: 31, groups: 'rec, serv, tran', name: 'Hospitality' },
  { code: 137, groups: 'corp', name: 'Human Resources' },
  { code: 134, groups: 'corp, good, tran', name: 'Import and Export' },
  {
    code: 88,
    groups: 'org, serv',
    name: 'Individual & Family Services',
  },
  { code: 147, groups: 'cons, man', name: 'Industrial Automation' },
  { code: 84, groups: 'med, serv', name: 'Information Services' },
  {
    code: 96,
    groups: 'tech',
    name: 'Information Technology and Services',
  },
  { code: 42, groups: 'fin', name: 'Insurance' },
  { code: 74, groups: 'gov', name: 'International Affairs' },
  {
    code: 141,
    groups: 'gov, org, tran',
    name: 'International Trade and Development',
  },
  { code: 6, groups: 'tech', name: 'Internet' },
  { code: 45, groups: 'fin', name: 'Investment Banking' },
  { code: 46, groups: 'fin', name: 'Investment Management' },
  { code: 73, groups: 'gov, leg', name: 'Judiciary' },
  { code: 77, groups: 'gov, leg', name: 'Law Enforcement' },
  { code: 9, groups: 'leg', name: 'Law Practice' },
  { code: 10, groups: 'leg', name: 'Legal Services' },
  { code: 72, groups: 'gov, leg', name: 'Legislative Office' },
  {
    code: 30,
    groups: 'rec, serv, tran',
    name: 'Leisure, Travel & Tourism',
  },
  { code: 85, groups: 'med, rec, serv', name: 'Libraries' },
  {
    code: 116,
    groups: 'corp, tran',
    name: 'Logistics and Supply Chain',
  },
  { code: 143, groups: 'good', name: 'Luxury Goods & Jewelry' },
  { code: 55, groups: 'man', name: 'Machinery' },
  { code: 11, groups: 'corp', name: 'Management Consulting' },
  { code: 95, groups: 'tran', name: 'Maritime' },
  { code: 97, groups: 'corp', name: 'Market Research' },
  { code: 80, groups: 'corp, med', name: 'Marketing and Advertising' },
  {
    code: 135,
    groups: 'cons, gov, man',
    name: 'Mechanical or Industrial Engineering',
  },
  { code: 126, groups: 'med, rec', name: 'Media Production' },
  { code: 17, groups: 'hlth', name: 'Medical Devices' },
  { code: 13, groups: 'hlth', name: 'Medical Practice' },
  { code: 139, groups: 'hlth', name: 'Mental Health Care' },
  { code: 71, groups: 'gov', name: 'Military' },
  { code: 56, groups: 'man', name: 'Mining & Metals' },
  {
    code: 35,
    groups: 'art, med, rec',
    name: 'Motion Pictures and Film',
  },
  {
    code: 37,
    groups: 'art, med, rec',
    name: 'Museums and Institutions',
  },
  { code: 115, groups: 'art, rec', name: 'Music' },
  { code: 114, groups: 'gov, man, tech', name: 'Nanotechnology' },
  { code: 81, groups: 'med, rec', name: 'Newspapers' },
  {
    code: 100,
    groups: 'org',
    name: 'Non-Profit Organization Management',
  },
  { code: 57, groups: 'man', name: 'Oil & Energy' },
  { code: 113, groups: 'med', name: 'Online Media' },
  { code: 123, groups: 'corp', name: 'Outsourcing/Offshoring' },
  { code: 87, groups: 'serv, tran', name: 'Package/Freight Delivery' },
  { code: 146, groups: 'good, man', name: 'Packaging and Containers' },
  { code: 61, groups: 'man', name: 'Paper & Forest Products' },
  { code: 39, groups: 'art, med, rec', name: 'Performing Arts' },
  { code: 15, groups: 'hlth, tech', name: 'Pharmaceuticals' },
  { code: 131, groups: 'org', name: 'Philanthropy' },
  { code: 136, groups: 'art, med, rec', name: 'Photography' },
  { code: 117, groups: 'man', name: 'Plastics' },
  { code: 107, groups: 'gov, org', name: 'Political Organization' },
  { code: 67, groups: 'edu', name: 'Primary/Secondary Education' },
  { code: 83, groups: 'med, rec', name: 'Printing' },
  {
    code: 105,
    groups: 'corp',
    name: 'Professional Training & Coaching',
  },
  { code: 102, groups: 'corp, org', name: 'Program Development' },
  { code: 79, groups: 'gov', name: 'Public Policy' },
  {
    code: 98,
    groups: 'corp',
    name: 'Public Relations and Communications',
  },
  { code: 78, groups: 'gov', name: 'Public Safety' },
  { code: 82, groups: 'med, rec', name: 'Publishing' },
  { code: 62, groups: 'man', name: 'Railroad Manufacture' },
  { code: 64, groups: 'agr', name: 'Ranching' },
  { code: 44, groups: 'cons, fin, good', name: 'Real Estate' },
  {
    code: 40,
    groups: 'rec, serv',
    name: 'Recreational Facilities and Services',
  },
  { code: 89, groups: 'org, serv', name: 'Religious Institutions' },
  {
    code: 144,
    groups: 'gov, man, org',
    name: 'Renewables & Environment',
  },
  { code: 70, groups: 'edu, gov', name: 'Research' },
  { code: 32, groups: 'rec, serv', name: 'Restaurants' },
  { code: 27, groups: 'good, man', name: 'Retail' },
  {
    code: 121,
    groups: 'corp, org, serv',
    name: 'Security and Investigations',
  },
  { code: 7, groups: 'tech', name: 'Semiconductors' },
  { code: 58, groups: 'man', name: 'Shipbuilding' },
  { code: 20, groups: 'good, rec', name: 'Sporting Goods' },
  { code: 33, groups: 'rec', name: 'Sports' },
  { code: 104, groups: 'corp', name: 'Staffing and Recruiting' },
  { code: 22, groups: 'good', name: 'Supermarkets' },
  { code: 8, groups: 'gov, tech', name: 'Telecommunications' },
  { code: 60, groups: 'man', name: 'Textiles' },
  { code: 130, groups: 'gov, org', name: 'Think Tanks' },
  { code: 21, groups: 'good', name: 'Tobacco' },
  {
    code: 108,
    groups: 'corp, gov, serv',
    name: 'Translation and Localization',
  },
  { code: 92, groups: 'tran', name: 'Transportation/Trucking/Railroad' },
  { code: 59, groups: 'man', name: 'Utilities' },
  {
    code: 106,
    groups: 'fin, tech',
    name: 'Venture Capital & Private Equity',
  },
  { code: 16, groups: 'hlth', name: 'Veterinary' },
  { code: 93, groups: 'tran', name: 'Warehousing' },
  { code: 133, groups: 'good', name: 'Wholesale' },
  { code: 142, groups: 'good, man, rec', name: 'Wine and Spirits' },
  { code: 119, groups: 'tech', name: 'Wireless' },
  { code: 103, groups: 'art, med, rec', name: 'Writing and Editing' },
]

export const currencyCodes = [
  { currency: 'Afghan Afghani', code: 'AFN' },
  { currency: 'Albanian Lek', code: 'ALL' },
  { currency: 'Algerian Dinar', code: 'DZD' },
  { currency: 'Angolan Kwanza', code: 'AOA' },
  { currency: 'Argentine Peso', code: 'ARS' },
  { currency: 'Armenian Dram', code: 'AMD' },
  { currency: 'Aruban Florin', code: 'AWG' },
  { currency: 'Australian Dollar', code: 'AUD' },
  { currency: 'Azerbaijani Manat', code: 'AZN' },
  { currency: 'Bahamian Dollar', code: 'BSD' },
  { currency: 'Bangladeshi Taka', code: 'BDT' },
  { currency: 'Barbadian Dollar', code: 'BBD' },
  { currency: 'Belize Dollar', code: 'BZD' },
  { currency: 'Bermudian Dollar', code: 'BMD' },
  { currency: 'Bolivian Boliviano', code: 'BOB' },
  { currency: 'Bosnia & Herzegovina Convertible Mark', code: 'BAM' },
  { currency: 'Botswana Pula', code: 'BWP' },
  { currency: 'Brazilian Real', code: 'BRL' },
  { currency: 'British Pound', code: 'GBP' },
  { currency: 'Brunei Dollar', code: 'BND' },
  { currency: 'Bulgarian Lev', code: 'BGN' },
  { currency: 'Burundian Franc', code: 'BIF' },
  { currency: 'Cambodian Riel', code: 'KHR' },
  { currency: 'Canadian Dollar', code: 'CAD' },
  { currency: 'Cape Verdean Escudo', code: 'CVE' },
  { currency: 'Cayman Islands Dollar', code: 'KYD' },
  { currency: 'Central African Cfa Franc', code: 'XAF' },
  { currency: 'Cfp Franc', code: 'XPF' },
  { currency: 'Chilean Peso', code: 'CLP' },
  { currency: 'Chinese Renminbi Yuan', code: 'CNY' },
  { currency: 'Colombian Peso', code: 'COP' },
  { currency: 'Comorian Franc', code: 'KMF' },
  { currency: 'Congolese Franc', code: 'CDF' },
  { currency: 'Costa Rican Colón', code: 'CRC' },
  { currency: 'Croatian Kuna', code: 'HRK' },
  { currency: 'Czech Koruna', code: 'CZK' },
  { currency: 'Danish Krone', code: 'DKK' },
  { currency: 'Djiboutian Franc', code: 'DJF' },
  { currency: 'Dominican Peso', code: 'DOP' },
  { currency: 'East Caribbean Dollar', code: 'XCD' },
  { currency: 'Egyptian Pound', code: 'EGP' },
  { currency: 'Ethiopian Birr', code: 'ETB' },
  { currency: 'Euro', code: 'EUR' },
  { currency: 'Falkland Islands Pound', code: 'FKP' },
  { currency: 'Fijian Dollar', code: 'FJD' },
  { currency: 'Gambian Dalasi', code: 'GMD' },
  { currency: 'Georgian Lari', code: 'GEL' },
  { currency: 'Gibraltar Pound', code: 'GIP' },
  { currency: 'Guatemalan Quetzal', code: 'GTQ' },
  { currency: 'Guinean Franc', code: 'GNF' },
  { currency: 'Guyanese Dollar', code: 'GYD' },
  { currency: 'Haitian Gourde', code: 'HTG' },
  { currency: 'Honduran Lempira', code: 'HNL' },
  { currency: 'Hong Kong Dollar', code: 'HKD' },
  { currency: 'Hungarian Forint', code: 'HUF' },
  { currency: 'Icelandic Króna', code: 'ISK' },
  { currency: 'Indian Rupee', code: 'INR' },
  { currency: 'Indonesian Rupiah', code: 'IDR' },
  { currency: 'Israeli New Sheqel', code: 'ILS' },
  { currency: 'Jamaican Dollar', code: 'JMD' },
  { currency: 'Japanese Yen', code: 'JPY' },
  { currency: 'Kazakhstani Tenge', code: 'KZT' },
  { currency: 'Kenyan Shilling', code: 'KES' },
  { currency: 'Kyrgyzstani Som', code: 'KGS' },
  { currency: 'Lao Kip', code: 'LAK' },
  { currency: 'Lebanese Pound', code: 'LBP' },
  { currency: 'Lesotho Loti', code: 'LSL' },
  { currency: 'Liberian Dollar', code: 'LRD' },
  { currency: 'Macanese Pataca', code: 'MOP' },
  { currency: 'Macedonian Denar', code: 'MKD' },
  { currency: 'Malagasy Ariary', code: 'MGA' },
  { currency: 'Malawian Kwacha', code: 'MWK' },
  { currency: 'Malaysian Ringgit', code: 'MYR' },
  { currency: 'Maldivian Rufiyaa', code: 'MVR' },
  { currency: 'Mauritanian Ouguiya', code: 'MRO' },
  { currency: 'Mauritian Rupee', code: 'MUR' },
  { currency: 'Mexican Peso', code: 'MXN' },
  { currency: 'Moldovan Leu', code: 'MDL' },
  { currency: 'Mongolian Tögrög', code: 'MNT' },
  { currency: 'Moroccan Dirham', code: 'MAD' },
  { currency: 'Mozambican Metical', code: 'MZN' },
  { currency: 'Myanmar Kyat', code: 'MMK' },
  { currency: 'Namibian Dollar', code: 'NAD' },
  { currency: 'Nepalese Rupee', code: 'NPR' },
  { currency: 'Netherlands Antillean Gulden', code: 'ANG' },
  { currency: 'New Taiwan Dollar', code: 'TWD' },
  { currency: 'New Zealand Dollar', code: 'NZD' },
  { currency: 'Nicaraguan Córdoba', code: 'NIO' },
  { currency: 'Nigerian Naira', code: 'NGN' },
  { currency: 'Norwegian Krone', code: 'NOK' },
  { currency: 'Pakistani Rupee', code: 'PKR' },
  { currency: 'Panamanian Balboa', code: 'PAB' },
  { currency: 'Papua New Guinean Kina', code: 'PGK' },
  { currency: 'Paraguayan Guaraní', code: 'PYG' },
  { currency: 'Peruvian Nuevo Sol', code: 'PEN' },
  { currency: 'Philippine Peso', code: 'PHP' },
  { currency: 'Polish Złoty', code: 'PLN' },
  { currency: 'Qatari Riyal', code: 'QAR' },
  { currency: 'Romanian Leu', code: 'RON' },
  { currency: 'Russian Ruble', code: 'RUB' },
  { currency: 'Rwandan Franc', code: 'RWF' },
  { currency: 'São Tomé and Príncipe Dobra', code: 'STD' },
  { currency: 'Saint Helenian Pound', code: 'SHP' },
  { currency: 'Salvadoran Colón', code: 'SVC' },
  { currency: 'Samoan Tala', code: 'WST' },
  { currency: 'Saudi Riyal', code: 'SAR' },
  { currency: 'Serbian Dinar', code: 'RSD' },
  { currency: 'Seychellois Rupee', code: 'SCR' },
  { currency: 'Sierra Leonean Leone', code: 'SLL' },
  { currency: 'Singapore Dollar', code: 'SGD' },
  { currency: 'Solomon Islands Dollar', code: 'SBD' },
  { currency: 'Somali Shilling', code: 'SOS' },
  { currency: 'South African Rand', code: 'ZAR' },
  { currency: 'South Korean Won', code: 'KRW' },
  { currency: 'Sri Lankan Rupee', code: 'LKR' },
  { currency: 'Surinamese Dollar', code: 'SRD' },
  { currency: 'Swazi Lilangeni', code: 'SZL' },
  { currency: 'Swedish Krona', code: 'SEK' },
  { currency: 'Swiss Franc', code: 'CHF' },
  { currency: 'Tajikistani Somoni', code: 'TJS' },
  { currency: 'Tanzanian Shilling', code: 'TZS' },
  { currency: 'Thai Baht', code: 'THB' },
  { currency: 'Tongan Paʻanga', code: 'TOP' },
  { currency: 'Trinidad and Tobago Dollar', code: 'TTD' },
  { currency: 'Turkish Lira', code: 'TRY' },
  { currency: 'Ugandan Shilling', code: 'UGX' },
  { currency: 'Ukrainian Hryvnia', code: 'UAH' },
  { currency: 'United Arab Emirates Dirham', code: 'AED' },
  { currency: 'United States Dollar', code: 'USD' },
  { currency: 'Uruguayan Peso', code: 'UYU' },
  { currency: 'Uzbekistani Som', code: 'UZS' },
  { currency: 'Vanuatu Vatu', code: 'VUV' },
  { currency: 'Vietnamese Đồng', code: 'VND' },
  { currency: 'West African Cfa Franc', code: 'XOF' },
  { currency: 'Yemeni Rial', code: 'YER' },
  { currency: 'Zambian Kwacha', code: 'ZMW' },
]

export const countries = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea, Democratic People'S Republic of", code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People'S Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
]

export const companySize = [
  { name: '0-1 employees', code: '0-1-employees' },
  { name: '2-10 employees', code: '2-10-employees' },
  { name: '11-50 employees', code: '11-50-employees' },
  { name: '51-200 employees', code: '51-200-employees' },
  { name: '201-500 employees', code: '201-500-employees' },
  { name: '501-1,000 employees', code: '501-1000-employees' },
  { name: '1,001-5,000 employees', code: '1001-5000-employees' },
  { name: '5,001-10,000 employees', code: '5001-10000-employees' },
  { name: '10,001+ employees', code: '10001-plus-employees' },
]

export const companyTypes = [
  { name: 'Educational', code: 'educational' },
  { name: 'Government', code: 'government' },
  { name: 'Non Profit', code: 'non-profit' },
  { name: 'Partnership', code: 'partnership' },
  { name: 'Privately Held', code: 'privately-held' },
  { name: 'Public Company', code: 'public-company' },
  { name: 'Self Employed', code: 'self-employed' },
  { name: 'Self Owned', code: 'self-owned' },
]

export const industriesSelectList = industryList.map<SelectChoiceType>(
  (item) => ({
    id: item.code,
    name: item.name,
  })
)
export const industriesByCode: { [key: string]: string } = Object.fromEntries(
  industryList.map((item) => [item.code, item.name])
)

export const currencyCodesSelectList = currencyCodes.map<SelectChoiceType>(
  (item) => ({
    id: item.code,
    name: item.currency,
  })
)

export const countriesByCode: { [key: string]: string } = Object.fromEntries(
  countries.map((item) => [item.code, item.name])
)
export const countriesSelectList = countries.map<SelectChoiceType>((item) => ({
  id: item.code,
  name: item.name,
}))

export const companyTypesSelectList = companyTypes.map<SelectChoiceType>(
  (item) => ({
    id: item.code,
    name: item.name,
  })
)

export const companySizeSelectList = companySize.map<SelectChoiceType>(
  (item) => ({
    id: item.code,
    name: item.name,
  })
)
