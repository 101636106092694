import {
  useEffect,
  useState,
  useMemo,
  useCallback,
  createContext,
  useContext,
} from 'react'
import { useRouter } from 'next/router'
import { SupabaseClient } from '@supabase/supabase-js'
import { useAsyncEffect } from 'use-async-effect'
import { Session, ErrorType } from '@/utils/typings/app'
import Web3Modal from 'web3modal'
import { getWeb3Modal } from '@/utils/web3/wallet'

import {
  getActiveSession,
  onUserStateChange,
  resetWalletSession,
  removeWalletSession,
} from '@/utils/auth/session-client'

type RequestWalletOptions = {
  title?: string
  description?: string
  descriptionSuccess?: string
  descriptionError?: string
  autoCloseOnSuccess?: boolean
  onSuccess?: () => void
  onError?: () => void
}

export type SessionState = {
  refreshSession: () => Promise<Session>
  session: Session | null
  error?: ErrorType
  sessionLoaded: boolean
  walletRequested: RequestWalletOptions | null
  requestWallet: (options: RequestWalletOptions) => void
  cancelRequestWallet: () => void
  setActiveWallet: (web3Modal?: Web3Modal) => Promise<void>
  logout?: () => void
}

const UserContext = createContext<SessionState | undefined>(undefined)

export interface SessionProviderProps {
  supabaseClient: SupabaseClient
  [propName: string]: any
}

export const SessionProvider = (props: SessionProviderProps) => {
  const { supabaseClient } = props
  const [session, setSession] = useState<Session | null>(null)
  const [sessionLoaded, setSessionLoaded] = useState<boolean>(false)
  const [walletRequested, setWalletRequested] =
    useState<RequestWalletOptions | null>(null)
  const [error, setError] = useState<ErrorType>()
  const router = useRouter()
  const [web3Modal, setWeb3Modal] = useState<Web3Modal | null>(null)

  const refreshSession = useCallback(async (): Promise<Session> => {
    const res = await getActiveSession(supabaseClient)
    setError(res.error)

    if (!session || JSON.stringify(res.session) !== JSON.stringify(session)) {
      setSession(res.session)
      setSessionLoaded(true)
    }
    return res.session
  }, [supabaseClient, session])

  const logout = useCallback(async () => {
    await supabaseClient.auth.signOut()
    setSession(null)
    await router.push('/')
  }, [router, supabaseClient.auth])

  const onSessionStateChange = async () => {
    if (sessionLoaded) await refreshSession()
  }

  /* ------------ ---
  WEB3 WALLET METHODS
  -----------------*/
  const connectWallet = async () => {
    if (session && web3Modal) {
      const instance: Web3Modal = await web3Modal.connect()
      await setupWalletEventListeners(instance) // eslint-disable-line @typescript-eslint/no-use-before-define
      // // await instance.enable()
      await resetWalletSession(session, instance)
      await refreshSession()
    } else {
      await removeWalletSession()
      await refreshSession()
    }
  }

  const setupWalletEventListeners = async (instance: Web3Modal) => {
    if (instance?.on) {
      instance.on('close', async () => {
        await removeWalletSession()
        await refreshSession()
      })
      instance.on('accountsChanged', async (accounts: string[]) => {
        if (accounts.length === 0) {
          await removeWalletSession()
          await refreshSession()
        } else await connectWallet()
      })
      instance.on('chainChanged', (chainId: number) => {
        console.log('chainChanged', chainId)
        // const networkId = await web3.eth.net.getId()
        // await this.setState({ chainId, networkId })
      })

      instance.on('networkChanged', (networkId: number) => {
        console.log('networkChanged', networkId)
        // const chainId = await web3.eth.chainId()
        // await this.setState({ chainId, networkId })
        // await this.getAccountAssets()
      })
    }
  }

  const initWallet = async () => {
    setWeb3Modal(getWeb3Modal())
  }

  const requestWallet = useCallback((options: RequestWalletOptions) => {
    setWalletRequested(options)
  }, [])

  const cancelRequestWallet = useCallback(() => {
    setWalletRequested(null)
  }, [])

  const setActiveWallet = useCallback(async (_web3Modal?: Web3Modal) => {
    if (_web3Modal) {
      setWeb3Modal(_web3Modal)
    }
  }, [])

  useAsyncEffect(async () => {
    if (web3Modal && sessionLoaded) {
      await connectWallet()
    }
  }, [session?.wallet?.address, sessionLoaded, web3Modal])

  // Get cached user on every page render.
  useAsyncEffect(async () => {
    await refreshSession()
    // await onSessionStateChange()
  }, [router.pathname])

  useAsyncEffect(async () => {
    if (sessionLoaded && session?.wallet) {
      await initWallet()
    }
  }, [sessionLoaded, session])

  useEffect(() => {
    const { data: authListener } = supabaseClient.auth.onAuthStateChange(
      (event, _session) => {
        ;(async () => {
          console.log('onAuthStateChange', event)
          const { error: err } = await onUserStateChange(event, _session)
          if (err) setError(err)
          // await refreshSession()
        })().catch(() => {})
      }
    )

    return () => {
      authListener?.unsubscribe()
    }
  }, [])

  const value = useMemo(
    () => ({
      refreshSession,
      sessionLoaded,
      session,
      logout,
      walletRequested,
      requestWallet,
      cancelRequestWallet,
      setActiveWallet,
      error,
    }),
    [
      refreshSession,
      sessionLoaded,
      session,
      logout,
      walletRequested,
      requestWallet,
      cancelRequestWallet,
      setActiveWallet,
      error,
    ]
  )
  return <UserContext.Provider value={value} {...props} />
}

export const useSession = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useSession must be used within a SessionProvider.`)
  }
  return context
}
