import { Fragment } from 'react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Menu, Transition } from '@headlessui/react'
import { AwesomeIcon } from './AwesomeIcon'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type EllipsisMenuProps = {
  menuItems: {
    title: string
    href?: string
    callback?: () => Promise<void> | void
  }[]
  className?: string
}

const EllipsisMenu = (props: EllipsisMenuProps) => {
  const { menuItems, className } = props
  if (!menuItems) return <></>
  return (
    <Menu as="div" className="relative inline-block text-left z-30">
      <div>
        <Menu.Button
          className={classNames(
            className
              ? className
              : 'bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500'
          )}
        >
          <span className="sr-only">Open options</span>
          <AwesomeIcon icon={solid('ellipsis')} className="h-4" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="p-1">
            {menuItems.map((item) => (
              <Menu.Item key={item.title}>
                {({ active }) => (
                  <>
                    {item.href ? (
                      <a
                        href="#"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm hover:bg-gray-100 rounded-md'
                        )}
                      >
                        {item.title}
                      </a>
                    ) : (
                      <button
                        type="button"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block w-full text-left px-3 py-1.5 text-sm hover:bg-indigo-10 rounded-md'
                        )}
                        onClick={async () => {
                          if (item.callback) await item.callback()
                        }}
                      >
                        {item.title}
                      </button>
                    )}
                  </>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export { EllipsisMenu }
