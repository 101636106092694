import { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button } from '@/components/ui'
import { TextInput } from '@/components/form'
import { NewsletterSubscriberModel } from '@/models'

type FormInputs = {
  email: string
}

const SubscribeToNewsletter = () => {
  const [saving, setSaving] = useState<boolean>(false)
  const [subscribed, setSubscribed] = useState<boolean>(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormInputs>()

  const onSubmitForm: SubmitHandler<FormInputs> = async (
    formData
  ): Promise<boolean> => {
    setSaving(true)
    const res = await NewsletterSubscriberModel.createNewsletterSubscriber(
      formData.email
    )
    if (res) setSubscribed(true)
    setSaving(false)
    reset()
    return true
  }

  return (
    <form className="mt-4" onSubmit={handleSubmit(onSubmitForm)}>
      <label htmlFor="email-address" className="sr-only">
        Email address
      </label>
      <div className="sm:flex sm:max-w-md">
        <div className="min-w-0 w-full">
          <TextInput
            name="email"
            type="email"
            label=""
            placeholder="Enter your email"
            register={register}
            errors={errors}
            required={true}
          />
        </div>
        <div className="ml-2 mt-1">
          <Button
            text={`${
              saving ? 'Subscribing' : subscribed ? 'Subscribed!' : 'Subscribe'
            }`}
            buttonType="submit"
            showloadingIcon={saving}
            icon={subscribed ? duotone('circle-check') : undefined}
            size="MD"
            style="PRIMARY"
          />
        </div>
      </div>
    </form>
  )
}
export { SubscribeToNewsletter }
