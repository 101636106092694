import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { AwesomeIcon } from '@/components/ui'
import { SubscribeToNewsletter } from '@/components/marketing/SubscribeToNewsletter'

const navigation = {
  explore: [
    { name: 'All Listings', href: '/explore' },
    { name: 'Create an NFT', href: '/account/listings' },
  ],
  company: [
    { name: 'Founding Members', href: '/floor3/founding-member' },
    { name: 'Affiliate Program', href: '/floor3/affiliate-club' },
  ],
  legal: [
    { name: 'Privacy', href: '/legal/privacy-policy' },
    { name: 'Terms', href: '/legal/terms-of-service' },
    { name: 'Cookie Policy', href: '/legal/cookie-policy' },
  ],
  social: [
    {
      name: 'Facebook',
      href: '#',
      icon: (
        <AwesomeIcon icon={brands('facebook')} className="h-5 w-5 text-white" />
      ),
    },
    {
      name: 'Instagram',
      href: '#',
      icon: (
        <AwesomeIcon
          icon={brands('instagram')}
          className="h-5 w-5 text-white"
        />
      ),
    },
    {
      name: 'Twitter',
      href: '#',
      icon: (
        <AwesomeIcon icon={brands('twitter')} className="h-5 w-5 text-white" />
      ),
    },
  ],
}

const Footer = () => {
  return (
    <footer className="bg-gray-800" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-indigo-300 tracking-wider uppercase">
                  Explore
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.explore.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className="text-sm font-semibold text-indigo-300 tracking-wider uppercase">
                  Company
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-indigo-300 tracking-wider uppercase">
                  Legal
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-12 xl:mt-0">
            <h3 className="text-sm font-semibold text-indigo-300 tracking-wider uppercase">
              Subscribe to our newsletter
            </h3>
            <p className="mt-4 text-base text-gray-300">
              Be one of the first to hear about new and trending NFT listings.
            </p>
            <SubscribeToNewsletter />
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-gray-400 hover:text-gray-300"
              >
                <span className="sr-only">{item.name}</span>
                {item.icon}
              </a>
            ))}
          </div>
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            &copy; {new Date().getFullYear()} Floor3 Ltd. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
