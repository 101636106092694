import { useState, useEffect } from 'react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { PaginationOptions } from '@/utils/typings/db'
import { range } from '@/utils/misc'
import { AwesomeIcon } from './AwesomeIcon'

type PaginationProps = {
  pagination: PaginationOptions
  onPageChanged?: (page: number) => void
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const Pagination = ({ pagination, onPageChanged }: PaginationProps) => {
  const [pages, setPages] = useState<number>(2)
  const [showingRangeMin, setShowingRangeMin] = useState<number>(0)
  const [showingRangeMax, setShowingRangeMax] = useState<number>(0)

  useEffect(() => {
    if (pagination) {
      const rangeMin =
        pagination.page === 1 ? 1 : pagination.page * pagination.perPage
      const rangeMax =
        pagination.page === 1
          ? pagination.perPage
          : pagination.page * pagination.perPage + pagination.perPage - 1

      setShowingRangeMin(rangeMin)
      setShowingRangeMax(
        pagination.totalRecords && rangeMax > pagination.totalRecords
          ? pagination.totalRecords
          : rangeMax
      )
      setPages(
        pagination.totalRecords
          ? Math.ceil(pagination.totalRecords / pagination.perPage)
          : 1
      )
    }
  }, [pagination])

  if (!pagination) return <></>
  return (
    <div className="mt-8 py-3 flex items-center justify-between border-t border-gray-50">
      <div className="flex-1 flex justify-between sm:hidden">
        <a
          href="#"
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Previous
        </a>
        <a
          href="#"
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Next
        </a>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-400">
            Showing{' '}
            <span className="font-medium text-gray-500">{showingRangeMin}</span>{' '}
            to{' '}
            <span className="font-medium text-gray-500">{showingRangeMax}</span>{' '}
            of{' '}
            <span className="font-medium text-gray-500">
              {pagination.totalRecords}
            </span>{' '}
            results
          </p>
        </div>
        <div>
          {pagination?.totalRecords &&
            pagination.totalRecords > pagination.perPage && (
              <nav
                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                aria-label="Pagination"
              >
                {pagination.page > 1 && (
                  <a
                    href="#"
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border-transparent border bg-indigo-10 text-sm font-medium text-indigo-500 hover:bg-indigo-50"
                  >
                    <span className="sr-only">Previous</span>
                    <AwesomeIcon
                      icon={solid('angle-left')}
                      className="w-5 text-indigo-500"
                    />
                  </a>
                )}
                {range(pages).map((page) => (
                  <a
                    key={page + 1}
                    href="#"
                    aria-current="page"
                    className={classNames(
                      'z-10 text-indigo-500 relative inline-flex items-center px-4 py-2 border-indigo-50 border-l text-sm hover:bg-indigo-50',
                      page + 1 === range(pages).length
                        ? 'border-r rounded-r-md'
                        : '',
                      page + 1 === pagination.page
                        ? 'bg-indigo-50 font-semibold'
                        : 'bg-indigo-10 font-medium',
                      page + 1 === 1 ? 'rounded-l-md' : ''
                    )}
                    onClick={() => {
                      if (onPageChanged) onPageChanged(page + 1)
                    }}
                  >
                    {page + 1}
                  </a>
                ))}
                {pagination.page < range(pages).length && (
                  <a
                    href="#"
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border-transparent border bg-indigo-10 text-sm font-medium text-indigo-500 hover:bg-indigo-50"
                  >
                    <span className="sr-only">Next</span>
                    <AwesomeIcon
                      icon={solid('angle-right')}
                      className="w-5 text-indigo-500"
                    />
                  </a>
                )}
              </nav>
            )}
        </div>
      </div>
    </div>
  )
}
export { Pagination }

/*



<a
  href="#"
  className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
>
  2
</a>
<a
  href="#"
  className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
>
  3
</a>
<span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
  ...
</span>
<a
  href="#"
  className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
>
  8
</a>
<a
  href="#"
  className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
>
  9
</a>
<a
  href="#"
  className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
>
  10
</a>
*/
