import {
  SupabaseClient,
  AuthChangeEvent,
  Session as SupabaseSession,
} from '@supabase/supabase-js'
import Web3Modal from 'web3modal'
import { ethers } from 'ethers'
import {
  Session,
  SessionUser,
  ErrorType,
  ActiveAffiliateResponse,
  SessionWallet,
  SessionResponse,
} from '@/utils/typings/app'
import { request } from '@/utils/http'
import { UserItem } from '@/models'

type UserFetcher = (
  url: string
) => Promise<{ user: SessionUser | null; accessToken: string | null }>

const userFetcher: UserFetcher = async (url) => {
  const response = await fetch(url)
  return response.ok ? response.json() : { user: null, accessToken: null }
}

export const removeWalletSession = async () => {
  await request({
    url: '/api/auth/wallet',
    method: 'DELETE',
    params: {},
  })
}

export const resetWalletSession = async (
  session: Session,
  instance: Web3Modal
) => {
  const signer = new ethers.providers.Web3Provider(
    instance as ethers.providers.ExternalProvider
  ).getSigner()
  const walletAddress = await signer.getAddress()
  if (
    walletAddress &&
    (!session?.wallet || session?.wallet?.address !== walletAddress)
  ) {
    // Create a server cookie for this wallet
    await request({
      url: '/api/auth/wallet',
      method: 'POST',
      params: { address: walletAddress },
    })
  } else if (!walletAddress) {
    await removeWalletSession()
  }
}

const getActiveWallet = async (): Promise<SessionWallet | null> => {
  // Is there an active session wallet?
  const res = await request<{
    address: string
    name?: string
    email?: string
    profileImage?: string
  }>({
    url: '/api/auth/wallet',
    method: 'GET',
    params: {},
  })
  if (res.data) {
    return res.data
  }
  return null
}

const getActiveAffiliate =
  async (): Promise<ActiveAffiliateResponse | null> => {
    // Is there an active session affiliate?
    const res = await request<ActiveAffiliateResponse>({
      url: '/api/auth/affiliate',
      method: 'GET',
      params: {},
    })
    if (res.data?.id) {
      return res.data
    }
    return null
  }

const getLoggedInUser = async (
  supabaseClient: SupabaseClient
): Promise<{
  user: SessionUser | null
  error: ErrorType | null
}> => {
  let user: SessionUser
  let error: ErrorType = {}
  let accessToken: string = ''

  try {
    const sb = await userFetcher('/api/auth/user')
    if (sb.accessToken) {
      accessToken = sb.accessToken
      supabaseClient.auth.setAuth(accessToken)
    }

    if (sb.user) {
      const profile = await request<UserItem>({
        url: `/api/users/${sb.user.id}`,
        method: 'GET',
      })
      if (profile.data) {
        const profileImageUrl = profile.data.profileImage
          ? `${process.env.NEXT_PUBLIC_SUPABASE_PUBLIC_STORAGE_URL}/profile-images/${sb.user.id}/${profile.data.profileImage}`
          : ''

        user = {
          id: sb.user.id,
          aud: sb.user.aud,
          role: sb.user.role,
          email: sb.user.email,
          name: profile.data.name ? profile.data.name : '',
          profileImage: profileImageUrl,
          last_sign_in_at: sb.user.last_sign_in_at,
          created_at: sb.user.created_at,
          updated_at: sb.user.updated_at,
        }
        return { user, error: null }
      }
    }
  } catch (err) {
    console.error(err)
    error = { title: 'The request to /api/auth/user failed' }
  }
  return { user: null, error }
}

export const getActiveSession = async (
  supabaseClient: SupabaseClient
): Promise<SessionResponse> => {
  const session: Session = { user: null, wallet: null, affiliate: null }
  const error: ErrorType = {}

  // First, check if we have a user logged in
  const sb = await getLoggedInUser(supabaseClient)
  if (sb.user) session.user = sb.user

  // Next, check if they have a wallet connected
  const wallet = await getActiveWallet()
  if (wallet) session.wallet = wallet

  // Next, check if they have an affiliate connected
  const affiliate = await getActiveAffiliate()
  if (affiliate?.id) session.affiliate = affiliate

  return { session, error }
}

export const onUserStateChange = async (
  event: AuthChangeEvent,
  session: SupabaseSession | null
): Promise<{ error?: ErrorType }> => {
  // Forward session from client to server where it is set in a Cookie.
  // NOTE: this will eventually be removed when the Cookie can be set differently.
  await fetch('/api/auth/callback', {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'same-origin',
    body: JSON.stringify({ event, session }),
  }).then((res) => {
    if (!res.ok)
      return { error: { title: 'The request to /api/auth/callback failed' } }
    return {}
  })
  return {}
}
