import { TypedDataUtils, TypedData, TypedDataTypes } from 'ethers-eip712'
import { ethers, ContractFunction, ContractTransaction } from 'ethers'
import { Floor3NFT, Floor3NFT__factory } from '@/contracts/typings'

export type NFTVoucher = {
  tokenId: string
  listingId: string
  nftId: string
  minPrice: any
}

export type VoucherMeta = {
  floor3Id: string
  uri: string
}

const SIGNING_DOMAIN_NAME = 'Floor3-Voucher'
const SIGNING_DOMAIN_VERSION = '1.0'

class LazyMinter {
  contract: ethers.Contract
  contractAddress: string
  signer: ethers.Signer
  types: TypedDataTypes
  _domain: object | null

  constructor(contractAddress: string, signer: ethers.Signer) {
    this.contractAddress = contractAddress
    this.signer = signer
    this.contract = this._getContractInstance()
    this.types = {
      EIP712Domain: [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
        { name: 'verifyingContract', type: 'address' },
      ],
      ListingVoucher: [
        { name: 'brandId', type: 'string' },
        { name: 'listingId', type: 'string' },
        { name: 'supply', type: 'uint256' },
        { name: 'minPrice', type: 'uint256' },
        { name: 'adminFeePerc', type: 'uint128' },
        { name: 'royaltyPercentage', type: 'uint128' },
        { name: 'affiliatePercentage', type: 'uint128' },
        { name: 'affiliateUpFrontPercentage', type: 'uint128' },
      ],
      NFTVoucher: [
        { name: 'tokenId', type: 'uint256' },
        { name: 'listingId', type: 'string' },
        { name: 'nftId', type: 'string' },
        { name: 'minPrice', type: 'uint256' },
      ],
    }
    this._domain = null
  }

  _getContractInstance() {
    return Floor3NFT__factory.connect(this.contractAddress, this.signer)
  }

  async _signingDomain() {
    if (this._domain !== null) {
      return this._domain
    }
    const chainId = await this.signer.getChainId()
    this._domain = {
      name: SIGNING_DOMAIN_NAME,
      version: SIGNING_DOMAIN_VERSION,
      verifyingContract: this.contractAddress,
      chainId,
    }
    return this._domain
  }

  async _formatListingVoucher(
    voucher: Floor3NFT.ListingVoucherStruct
  ): Promise<TypedData> {
    const domain = await this._signingDomain()
    return {
      domain,
      types: this.types,
      primaryType: 'ListingVoucher',
      message: voucher,
    }
  }

  async _formatNFTVoucher(voucher: NFTVoucher): Promise<TypedData> {
    const domain = await this._signingDomain()
    return {
      domain,
      types: this.types,
      primaryType: 'NFTVoucher',
      message: voucher,
    }
  }

  async createListingVoucher(voucher: Floor3NFT.ListingVoucherStruct) {
    const typedData = await this._formatListingVoucher(voucher)
    const digest = TypedDataUtils.encodeDigest(typedData)
    const signature = await this.signer.signMessage(digest)
    return {
      voucher,
      signature,
      digest,
    }
  }

  async createNFTVoucher(voucher: NFTVoucher) {
    const typedData = await this._formatNFTVoucher(voucher)
    const digest = TypedDataUtils.encodeDigest(typedData)
    const signature = await this.signer.signMessage(digest)
    return {
      voucher,
      signature,
      digest,
    }
  }

  async redeemVoucher(
    voucher: Floor3NFT.ListingVoucherStruct,
    signature: any,
    price: number,
    meta: VoucherMeta,
    affiliateAddress?: string
  ): Promise<ContractTransaction> {
    const buyerAddress = await this.signer.getAddress()
    const priceParsed = ethers.utils.parseUnits(price.toString(), 'ether')

    const func: ContractFunction = this.contract.redeemVoucher
    const res: ContractTransaction = await func(
      buyerAddress,
      affiliateAddress
        ? affiliateAddress
        : '0x0000000000000000000000000000000000000000',
      voucher,
      signature,
      meta,
      {
        value: priceParsed,
      }
    )
    return res
  }

  async redeemNFTVoucher(
    tokenId: string,
    voucher: NFTVoucher,
    signature: any,
    price: number,
    meta: VoucherMeta,
    affiliateAddress?: string
  ): Promise<ContractTransaction> {
    const buyerAddress = await this.signer.getAddress()
    const priceParsed = ethers.utils.parseUnits(price.toString(), 'ether')

    const func: ContractFunction = this.contract.redeemNFTVoucher
    const res: ContractTransaction = await func(
      tokenId,
      buyerAddress,
      affiliateAddress
        ? affiliateAddress
        : '0x0000000000000000000000000000000000000000',
      voucher,
      signature,
      meta,
      {
        value: priceParsed,
      }
    )
    return res
  }
}

export { LazyMinter }

export const getLazyMinter = (signer: ethers.Signer): LazyMinter => {
  return new LazyMinter(process.env.NEXT_PUBLIC_FLOOR3_CONTRACT, signer)
}
