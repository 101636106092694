import { ListingContent } from '@prisma/client'
import { request } from '@/utils/http'
import { ApiResponse, ApiRequestParams } from '@/utils/types'
import { verifyAccess } from '@/utils/web3/contract'
import { ListingItem } from '@/utils/typings/models'
import { NFTContentLogModel } from './NFTContentLogModel'
import { NFTModel } from './NFTModel'

export type UnlockedContent = {
  body: string
  data: any
  url: string
}

export interface ListingContentItem extends ListingContent {
  listing: ListingItem
}
export const ListingContentItemInputs: string[] = [
  'listingId',
  'title',
  'contentType',
  'contentRawText',
  'contentFileURL',
  'contentUrlEndpoint',
]

class ListingContentModel {
  item: ListingContentItem = {} as any

  constructor(item?: Partial<ListingContentItem>) {
    if (item) this.item = { ...this.item, ...item }
  }

  updateLocalItem(item: Partial<ListingContentItem>) {
    this.item = { ...this.item, ...item }
  }

  prepareDataForSaving(): ApiRequestParams {
    const params: ApiRequestParams = {}
    ListingContentItemInputs.forEach((key) => {
      const val = this.item[key as keyof ListingContentItem]
      if (
        typeof val === 'string' ||
        typeof val === 'number' ||
        typeof val === 'boolean'
      )
        params[key] = val
    })
    return params
  }

  async getLockedContent<T extends UnlockedContent>(
    nft: NFTModel,
    refererPath?: string
  ): Promise<T | null> {
    if (await verifyAccess(nft)) {
      let unlockedContent: T = {} as T

      if (this.item.contentType === 'RawText' && this.item.contentRawText) {
        unlockedContent.body = this.item.contentRawText
      } else if (this.item.contentType === 'File' && this.item.contentFileURL) {
        unlockedContent.url = this.item.contentFileURL
      } else if (
        this.item.contentType === 'UrlEndpoint' &&
        this.item.contentUrlEndpoint
      ) {
        const res = await request<ListingContent>({
          url: this.item.contentUrlEndpoint,
          method: 'POST',
          params: { nftId: nft.item.id, ref: refererPath ? refererPath : '' },
        })
        if (!res.error && res.responseCode === 200 && res.data) {
          const content: ListingContent = res.data
          if (res.contentType && res.contentType.includes('json')) {
            unlockedContent.data = content
          } else {
            unlockedContent.body = content.toString()
          }
        } else {
          return null
        }
      }
      if (Object.keys(unlockedContent).length === 0) return null
      return unlockedContent
    }
    return null
  }

  async testContentUrlEndpoint(): Promise<boolean> {
    if (!this.item.contentUrlEndpoint) return false
    const res = await request({
      url: this.item.contentUrlEndpoint,
      method: 'POST',
      params: {},
    })
    return !res.error && res.responseCode === 200
  }

  async logView(nftId: string, ownerAddress: string) {
    await NFTContentLogModel.saveNFTContentLog(
      nftId,
      this.item.id,
      ownerAddress
    )
  }

  async save(): Promise<ListingContentModel | null> {
    if (this.item?.id) {
      return this.update()
    }
    return this.create()
  }

  async create(): Promise<ListingContentModel | null> {
    return ListingContentModel.returnApiSingle(
      await request({
        url: `/api/listings/${this.item.listingId}/content/create`,
        method: 'POST',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async update(): Promise<ListingContentModel | null> {
    return ListingContentModel.returnApiSingle(
      await request({
        url: `/api/listings/${this.item.listingId}/content/${this.item.id}/update`,
        method: 'PUT',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async delete(): Promise<boolean> {
    const res = await request<boolean>({
      url: `/api/listings/${this.item.listingId}/content/${this.item.id}/delete`,
      method: 'DELETE',
    })
    return !res.error
  }

  static returnApiSingle(
    res: ApiResponse<ListingContentItem>
  ): ListingContentModel | null {
    if (!res.error && res.data) {
      const item: ListingContentItem = res.data
      return new ListingContentModel(item)
    }
    return null
  }

  static returnApiMany(
    res: ApiResponse<ListingContentItem[]>
  ): ListingContentModel[] {
    if (!res.error && res.data) {
      const listings: ListingContentItem[] = res.data
      return listings.map(
        (item: ListingContentItem) => new ListingContentModel(item)
      )
    }
    return []
  }

  static async getListingContentItems(
    listingId: string
  ): Promise<ListingContentModel[]> {
    return ListingContentModel.returnApiMany(
      await request({
        url: `/api/listings/${listingId}/content/list`,
        method: 'GET',
        params: {},
      })
    )
  }

  static async getListingContentItem(
    listingId: string,
    contentItemId: string
  ): Promise<ListingContentModel | null> {
    return ListingContentModel.returnApiSingle(
      await request({
        url: `/api/listings/${listingId}/content/${contentItemId}`,
        method: 'GET',
        params: {},
      })
    )
  }
}
export { ListingContentModel }
