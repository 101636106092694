import { Affiliate } from '@prisma/client'
import { request } from '@/utils/http'
import { ApiResponse, ApiRequestParams } from '@/utils/types'
import { NftItem } from '@/utils/typings/models'
import { NFTModel } from './NFTModel'
import { UnlockedContent } from './ListingContentModel'

export interface UnlockedAffiliateUrlContent extends UnlockedContent {
  data: {
    index: { url: string; short: string }
    referer: { url: string; short: string }
  }
}

export interface AffiliateItem extends Affiliate {
  nft: NftItem
}
export const AffiliateItemInputs: string[] = ['nftId']

class AffiliateModel {
  item: AffiliateItem = {} as AffiliateItem

  constructor(item?: Partial<AffiliateItem>) {
    if (item) this.item = { ...this.item, ...item }
  }

  updateLocalItem(item: Partial<AffiliateItem>) {
    this.item = { ...this.item, ...item }
  }

  prepareDataForSaving(): ApiRequestParams {
    const params: ApiRequestParams = {}
    AffiliateItemInputs.forEach((key) => {
      const val = this.item[key as keyof AffiliateItem]
      if (
        typeof val === 'string' ||
        typeof val === 'number' ||
        typeof val === 'boolean'
      )
        params[key] = val
    })
    return params
  }

  async save(): Promise<AffiliateModel | null> {
    if (this.item?.id) {
      return this.update()
    }
    return this.create()
  }

  async create(): Promise<AffiliateModel | null> {
    return AffiliateModel.returnApiSingle(
      await request({
        url: '/api/affiliates/create',
        method: 'POST',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async update(): Promise<AffiliateModel | null> {
    return AffiliateModel.returnApiSingle(
      await request({
        url: `/api/affiliates/${this.item.id}/update`,
        method: 'PUT',
        params: this.prepareDataForSaving(),
      })
    )
  }

  static returnApiSingle(
    res: ApiResponse<AffiliateItem>
  ): AffiliateModel | null {
    if (!res.error && res.data) {
      const item: AffiliateItem = res.data
      return new AffiliateModel(item)
    }
    return null
  }

  static returnApiMany(res: ApiResponse<AffiliateItem[]>): AffiliateModel[] {
    if (!res.error && res.data) {
      const listings: AffiliateItem[] = res.data
      return listings.map((item: AffiliateItem) => new AffiliateModel(item))
    }
    return []
  }

  static async getAffiliate(
    affiliateId: string
  ): Promise<AffiliateModel | null> {
    return AffiliateModel.returnApiSingle(
      await request<AffiliateItem>({
        url: `/api/affiliates/${affiliateId}`,
        method: 'GET',
        params: {},
      })
    )
  }
}
export { AffiliateModel }
