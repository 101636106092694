import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import { IncomingMessage } from 'node:http'
import { handleError } from '@/utils/errors'
import { ApiResponse, ApiRequestParams } from './types'

type requestProp = {
  url: string
  method: 'GET' | 'PUT' | 'POST' | 'DELETE'
  params?: ApiRequestParams | FormData
  useFormData?: boolean
  req?: IncomingMessage
}

export const request = async <T>({
  url,
  method,
  params,
  useFormData,
  req,
}: requestProp): Promise<ApiResponse<T>> => {
  let urlToQuery = url
  let config: AxiosRequestConfig<any> = {}
  const res: ApiResponse<T> = {
    data: null,
    error: null,
    success: false,
    responseCode: null,
    contentType: '',
  }
  let http_res: AxiosResponse

  if (!urlToQuery.includes('https://') && !urlToQuery.includes('http://'))
    urlToQuery =
      process.env.NEXT_PUBLIC_HTTP_PROTOCOL +
      process.env.NEXT_PUBLIC_ROOT_DOMAIN +
      urlToQuery

  if (useFormData) config.headers = { 'Content-Type': 'multipart/form-data' }

  try {
    switch (method) {
      case 'POST':
        config.withCredentials = true
        http_res = await axios.post<object>(urlToQuery, params, config)
        break
      case 'GET':
        config.params = params
        config.withCredentials = true

        if (req && req?.headers?.cookie) {
          config.headers = {
            Cookie: req.headers.cookie,
          }
        }

        http_res = await axios.get(urlToQuery, config)
        break
      case 'PUT':
        config.withCredentials = true
        http_res = await axios.put<object>(urlToQuery, params, config)
        break
      case 'DELETE':
        http_res = await axios.delete(
          urlToQuery,
          params as AxiosRequestConfig<any>
        )
        break
      default:
        config.params = params
        http_res = await axios.get(urlToQuery, config)
        break
    }

    // Prepare the response
    res.success = http_res.statusText === 'OK' ? true : false
    res.responseCode = http_res.status
    res.contentType = http_res.headers['content-type']
      ? http_res.headers['content-type']
      : 'text/plain'

    if (http_res && http_res.status === 200) {
      res.data = http_res.data
    } else if (http_res) {
      res.error = { code: http_res.status }
    }
  } catch (err: any) {
    if (err && axios.isAxiosError(err)) {
      if (err.response?.status === 500) handleError(err)

      /* eslint-disable @typescript-eslint/no-unsafe-member-access */
      res.error = {
        code: 500,
        title: err.response?.statusText,
        description: 'Please try again in a few minutes or contact support.',
      }
      /* eslint-enable @typescript-eslint/no-unsafe-member-access */
    } else {
      handleError(err)

      res.error = {
        code: 500,
        title: 'Unhandled error',
        description: 'Please try again in a few minutes or contact support.',
      }
    }
  }
  return res
}

export const encodeQueryData = (data: URLSearchParams): string => {
  const ret: string[] = []
  data.forEach((val: string, key: string) => {
    ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
  })
  return ret.join('&')
}

/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access */
export const getParams = (url: string) => {
  if (!url) return {}

  const params: any = {}

  new URL(url).searchParams.forEach((val: string, key: string) => {
    if (params[key] !== undefined) {
      if (!Array.isArray(params[key])) {
        params[key] = [params[key]]
      }
      params[key].push(val)
    } else {
      params[key] = val
    }
  })

  return params
}
/* eslint-enable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access */
