import { create as ipfsHttpClient, urlSource } from 'ipfs-http-client'
import { Dictionary, AppResponse } from '@/utils/types'

const ipfsClient = ipfsHttpClient({ url: process.env.NEXT_PUBLIC_IPFS_API_URL })

export type IpfsResponse = {
  url: string
  path: string
}

export const uploadUrlToIPFS = async (
  fileUrl: string
): Promise<AppResponse<IpfsResponse>> => {
  try {
    const resp = await ipfsClient.add(urlSource(fileUrl), {
      progress: (prog) => console.log(`received: ${prog}`),
    })
    const url = `${
      process.env.NEXT_PUBLIC_IPFS_CLIENT_URL
    }/${resp.cid.toString()}`
    return { data: { url: url, path: resp.cid.toString() } }
  } catch {
    return { data: null, error: { title: 'Error uploading URL to IPFS' } }
  }
}

export const uploadBufferToIPFS = async (
  buffer: Buffer
): Promise<AppResponse<IpfsResponse>> => {
  try {
    const resp = await ipfsClient.add(buffer)
    const url = `${process.env.NEXT_PUBLIC_IPFS_CLIENT_URL}/${resp.path}`
    return { data: { url: url, path: resp.path } }
  } catch {
    return { data: null, error: { title: 'Error uploading file to IPFS' } }
  }
}

export const uploadFileToIPFS = async (
  file: File
): Promise<AppResponse<IpfsResponse>> => {
  try {
    const resp = await ipfsClient.add(file)
    const url = `${process.env.NEXT_PUBLIC_IPFS_CLIENT_URL}/${resp.path}`
    return { data: { url: url, path: resp.path } }
  } catch {
    return { data: null, error: { title: 'Error uploading file to IPFS' } }
  }
}

export const addDataToIPFS = async (
  data: Dictionary<string>
): Promise<AppResponse<IpfsResponse>> => {
  try {
    const resp = await ipfsClient.add(JSON.stringify(data))
    const url = `${process.env.NEXT_PUBLIC_IPFS_CLIENT_URL}/${resp.path}`
    return { data: { url: url, path: resp.path } }
  } catch {
    return { data: null, error: { title: 'Error uploading data to IPFS' } }
  }
}
