import { request } from '@/utils/http'
import { Session } from '@/utils/typings/app'
import { ApiResponse, ApiRequestParams } from '@/utils/types'
import { CommentItem } from '@/utils/typings/models'

export const NftLogItemInputs: string[] = []

class CommentModel {
  item: CommentItem = {} as any

  constructor(item?: Partial<CommentItem>) {
    if (item) this.item = { ...this.item, ...item }
  }

  updateLocalItem(item: Partial<CommentItem>) {
    this.item = { ...this.item, ...item }
  }

  prepareDataForSaving(): ApiRequestParams {
    const params: ApiRequestParams = {}
    NftLogItemInputs.forEach((key) => {
      const val = this.item[key as keyof CommentItem]
      if (
        typeof val === 'string' ||
        typeof val === 'number' ||
        typeof val === 'boolean'
      )
        params[key] = val
    })
    return params
  }

  static returnApiSingle(res: ApiResponse<CommentItem>): CommentModel | null {
    if (!res.error && res.data) {
      const item: CommentItem = res.data
      return new CommentModel(item)
    }
    return null
  }

  static returnApiMany(res: ApiResponse<CommentItem[]>): CommentModel[] {
    if (!res.error && res.data) {
      const listings: CommentItem[] = res.data
      return listings.map((item: CommentItem) => new CommentModel(item))
    }
    return []
  }

  static async getComments(
    entity: 'listing' | 'nft',
    entityId: string
  ): Promise<{ comments: CommentModel[]; walletVotes: string[] } | null> {
    const res = await request<{
      comments: CommentItem[]
      walletVotes: string[]
    }>({
      url: `/api/comments/list`,
      method: 'GET',
      params: { entity, entityId },
    })

    if (res.data) {
      const comments = res.data.comments.map(
        (item: CommentItem) => new CommentModel(item)
      )
      return { comments, walletVotes: res.data.walletVotes }
    }
    return null
  }

  static async postComment(
    session: Session,
    entity: 'listing' | 'nft',
    entityId: string,
    body: string,
    editCommentId: number | null,
    replyToId: number | null
  ): Promise<{
    comment: CommentModel
    totalComments: number | null
  } | null> {
    if (session.wallet?.address) {
      const res = await request<{
        comment: CommentItem
        totalComments: number | null
      }>({
        url: `/api/comments/post`,
        method: 'POST',
        params: {
          entity,
          entityId,
          body,
          editCommentId: editCommentId ? editCommentId : '',
          replyToId: replyToId ? replyToId : '',
        },
      })
      if (res.data)
        return {
          comment: new CommentModel(res.data.comment),
          totalComments: res.data.totalComments,
        }
    }
    return null
  }

  async delete(): Promise<{ success: boolean; totalComments: number }> {
    const res = await request<{ success: boolean; totalComments: number }>({
      url: `/api/comments/${this.item.uuid}/delete`,
      method: 'DELETE',
    })
    if (!res.error && res.data) return res.data
    return { success: false, totalComments: 0 }
  }

  // Voting
  async analyticsVotesLog(
    session: Session,
    downVote?: boolean
  ): Promise<number | null> {
    if (session.wallet?.address) {
      const res = await request<{ totalVotes: number }>({
        url: `/api/analytics/votes/log`,
        method: 'POST',
        params: {
          entity: 'comment',
          entityId: this.item.id,
          downVote: downVote !== undefined ? downVote : false,
        },
      })
      if (res.data) return res.data.totalVotes
    }
    return null
  }

  async analyticsVotesStatus(): Promise<{ voted: boolean }> {
    const res = await request<{ voted: boolean }>({
      url: `/api/analytics/votes/status`,
      method: 'GET',
      params: { entity: 'comment', entityId: this.item.id },
    })
    if (res.data) return res.data
    return { voted: false }
  }
}
export { CommentModel }
