import Link from 'next/link'
import { useState, useEffect } from 'react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useRouter } from 'next/router'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { AwesomeIcon } from '@/components/ui'
import { useSession } from '@/context'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type NavSidebarDashboardProps = {
  mode?: 'COLLAPSE' | ''
}

type Navigation = {
  name: string
  href: string
  icon: IconDefinition
  current: boolean
  visible: boolean
  onClick?: () => void
}

const NavSidebarDashboard = ({ mode = '' }: NavSidebarDashboardProps) => {
  const router = useRouter()
  const { session, logout } = useSession()
  const [navigation, setNavigation] = useState<Navigation[]>()

  useEffect(() => {
    const _navigation = [
      {
        name: 'Dashboard',
        href: '/account/dashboard',
        icon: regular('home'),
        current: false,
        visible: session?.user ? true : false,
      },
      {
        name: 'Portfolio',
        href: '/account/portfolio',
        icon: regular('bag-shopping'),
        current: false,
        visible: true,
      },
      {
        name: 'Affiliate Revenue',
        href: '/account/affiliate-revenue',
        icon: regular('envelope-open-dollar'),
        current: false,
        visible: true,
      },
      {
        name: 'Brands',
        href: '/account/brands',
        icon: regular('building'),
        current: false,
        visible: session?.user ? true : false,
      },
      {
        name: 'Listings',
        href: '/account/listings',
        icon: regular('ticket'),
        current: false,
        visible: session?.user ? true : false,
      },
      {
        name: 'My Profile',
        href: '/account/profile',
        icon: regular('user'),
        current: false,
        visible: true,
      },
      {
        name: 'Sign out',
        href: '#',
        icon: regular('lock'),
        current: false,
        onClick: logout,
        visible: true,
      },
    ]
    const currentNavItem = _navigation.find(
      (obj) => obj.href === router.pathname
    )

    if (currentNavItem) {
      currentNavItem.current = true
    } else {
      _navigation[0].current = true
    }
    setNavigation(_navigation)
  }, [logout, router.pathname, session?.user])

  return (
    <>
      <div
        className={`mt-5 flex-1 ${
          mode === 'COLLAPSE' ? 'h-0 overflow-y-auto' : 'flex flex-col'
        }`}
      >
        <nav
          className={
            mode === 'COLLAPSE'
              ? 'px-2 space-y-1'
              : 'flex-1 px-2 pb-4 space-y-1'
          }
        >
          {navigation && (
            <>
              {navigation
                .filter((item) => {
                  return item.visible
                })
                .map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    onClick={item.onClick}
                    className={`${classNames(
                      item.current
                        ? 'bg-indigo-800 text-white'
                        : 'text-indigo-100 hover:bg-indigo-600',
                      'group flex items-center px-2 py-2 font-medium rounded-md'
                    )} ${classNames(
                      mode === 'COLLAPSE' ? 'text-base' : 'text-sm'
                    )}`}
                  >
                    <div
                      className={classNames(
                        'w-4',
                        mode === 'COLLAPSE' ? 'mr-4' : 'mr-3'
                      )}
                    >
                      <AwesomeIcon
                        icon={item.icon}
                        className="flex-shrink-0 text-indigo-300"
                        aria-hidden="true"
                      />
                    </div>
                    {item.name}
                  </a>
                ))}
            </>
          )}
        </nav>
      </div>
      <div className="flex-shrink-0 flex bg-indigo-800 p-4">
        <Link href="/">
          <a className="flex-shrink-0 group block">
            <div className="flex items-center">
              <div>
                <div className="bg-indigo-700 rounded-lg p-2 hover:bg-indigo-600">
                  <AwesomeIcon
                    icon={regular('chevron-left')}
                    className="w-6 text-indigo-300"
                  />
                </div>
              </div>
              <div className="ml-3">
                <p className="text-base font-medium text-indigo-100">
                  Back to Marketplace
                </p>
              </div>
            </div>
          </a>
        </Link>
      </div>
    </>
  )
}

export default NavSidebarDashboard
