import Link from 'next/link'
import { Menu, Transition, Disclosure } from '@headlessui/react'
import { Fragment } from 'react'
import { useSession } from '@/context'
import { Button, HeroIcon } from '@/components/ui'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type NavUserDropdownProps = {
  mode: string
}

const NavUserDropdown = ({ mode = '' }: NavUserDropdownProps) => {
  const { session, logout } = useSession()
  const userNavigation = [
    { name: 'Dashboard', href: '/account/dashboard', visible: session?.user },
    { name: 'Portfolio', href: '/account/portfolio', visible: true },
    {
      name: 'Affiliate Revenue',
      href: '/account/affiliate-revenue',
      visible: true,
    },
    { name: 'Listings', href: '/account/listings', visible: session?.user },
    { name: 'My Profile', href: '/account/profile', visible: true },
    { name: 'Sign out', href: '#', onClick: logout, visible: true },
  ]

  if (!session?.user && !session?.wallet)
    return (
      <>
        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <Link href="/account/sign-in">
            <a className="whitespace-nowrap text-sm font-medium text-gray-500 hover:text-gray-900">
              Sign in
            </a>
          </Link>
          <Button
            text="Sign up"
            href="/account/sign-up"
            className="ml-8 whitespace-nowrap"
            size="SM"
          />
        </div>
      </>
    )
  if (mode === 'COLLAPSE') {
    return (
      <>
        <div className="flex items-center px-4">
          <div className="flex-shrink-0">
            <img
              className="h-10 w-10 rounded-full"
              src={session?.user?.profileImage}
              alt="Avatar"
            />
          </div>
        </div>
        <div className="mt-3 space-y-1">
          {userNavigation
            .filter((item) => {
              return item.visible
            })
            .map((item) => (
              <Disclosure.Button
                key={item.name}
                as="a"
                href={item.href}
                onClick={item.onClick}
                className="block px-4 py-2 text-base font-medium text-gray-500 whitespace-nowrap hover:text-gray-800 hover:bg-gray-10"
              >
                {item.name}
              </Disclosure.Button>
            ))}
        </div>
      </>
    )
  }
  return (
    <>
      <Menu as="div" className="ml-3 relative">
        <div>
          <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-lg focus:outline-none focus:ring-transparent">
            <span className="sr-only">Open user menu</span>
            {session?.user?.profileImage ? (
              <img
                className="h-8 w-8 rounded-lg"
                src={session?.user?.profileImage}
                alt="Avatar"
              />
            ) : session?.wallet?.profileImage ? (
              <img
                className="h-8 w-8 rounded-lg"
                src={session?.wallet?.profileImage}
                alt="Avatar"
              />
            ) : (
              <HeroIcon
                name="UserIcon"
                className="h-5 w-5 text-indigo-500"
                containerBackground="bg-indigo-50"
                containerRounded="rounded-lg"
                containerPadding="p-2"
              />
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg p-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            {userNavigation
              .filter((item) => {
                return item.visible
              })
              .map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <a
                      href={item.href}
                      className={classNames(
                        active ? 'bg-indigo-25' : '',
                        'block px-3 py-1.5 text-sm text-gray-700 rounded-md whitespace-nowrap'
                      )}
                      onClick={item.onClick}
                    >
                      {item.name}
                    </a>
                  )}
                </Menu.Item>
              ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  )
}

export default NavUserDropdown
