import { useState, useEffect, useRef, useCallback } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { supabase } from '@/utils/supabaseClient'
import { Button, HeroIcon } from '@/components/ui'
import { useApp } from '@/context'
import { concatFolderFilename } from '@/utils/image'
import { uploadFileToIPFS } from '@/utils/web3/ipfs'
import { useFormContext, get } from 'react-hook-form'
import {
  UseFormRegister,
  UseFormResetField,
  FieldErrors,
  FieldError,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form/dist/types'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'

interface FileInputProps<T extends FieldValues> {
  name: Path<T>
  label: string
  placeholder?: string
  url?: PathValue<T, Path<T>>
  storage: 's3' | 'ipfs'
  bucket?: string
  folder?: string
  register: UseFormRegister<T>
  errors: FieldErrors<T>
  resetField: UseFormResetField<T>
  required?: boolean
  hideErrorMessage?: boolean
  onUpload: (filePath: string) => void
}

const INPUT_CLASS_NORMAL =
  'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
const INPUT_CLASS_ERROR =
  'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'

const FileInput = <T extends FieldValues>({
  name,
  label,
  placeholder = '',
  url,
  storage,
  bucket,
  folder = '',
  register,
  errors,
  resetField,
  required,
  hideErrorMessage = false,
  onUpload,
}: FileInputProps<T>) => {
  const registerProps: RegisterOptions = {}
  const [fieldErrorMessage, setfieldErrorMessage] = useState<string>('')
  const [inputClass, setInputClass] = useState<string>(INPUT_CLASS_NORMAL)
  const [uploading, setUploading] = useState<boolean>(false)
  const [originalUrl, setOriginalUrl] = useState<string>('')
  const [activeUrl, setActiveUrl] = useState<string>('')
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const methods = useFormContext()
  const error: FieldError = get(errors || methods.formState.errors, name)
  const { showError } = useApp()

  // Set the registerProps
  if (required) registerProps.required = required

  const checkError = useCallback(() => {
    switch (error?.type) {
      case 'required':
        setfieldErrorMessage('This field is required')
        break
      default:
        if (error?.message) setfieldErrorMessage(error?.message)
        else {
          setfieldErrorMessage('')
        }
        break
    }
  }, [error])

  const handleBrowseClick = () => {
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.click()
    }
  }

  const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setUploading(true)

      if (!event.target.files || event.target.files.length === 0) {
        throw new Error('You must select an image to upload.')
      }

      const file = event.target.files[0]

      if (storage === 's3' && bucket) {
        // Are we replacing the image? If so we can delete the old one
        if (originalUrl) {
          await supabase.storage
            .from(bucket)
            .remove([concatFolderFilename(folder, originalUrl)])
        }

        const fileExt = file.name.split('.').pop()
        const fileName = `${Math.random()}.${fileExt}`
        const filePath = `${fileName}`
        const { error: sbError } = await supabase.storage
          .from(bucket)
          .upload(concatFolderFilename(folder, filePath), file)
        if (sbError) throw sbError
        onUpload(filePath)
      } else if (storage === 'ipfs') {
        const res = await uploadFileToIPFS(file)
        if (res.data) {
          setActiveUrl(res.data.url)
          resetField(name, { defaultValue: res.data.url })
          onUpload(res.data.url)
        }
      }
    } catch (err: any) {
      if (err instanceof Error) {
        showError({
          title: 'Error uploading image',
          description: error.message
            ? error.message
            : 'There was a problem uploading this image. Please try again or contact support.',
        })
      }
      console.error(err)
    } finally {
      setUploading(false)
    }
  }

  useEffect(() => {
    if (url) {
      if (!originalUrl) setOriginalUrl(url)
      setActiveUrl(url)
    }
  }, [url, originalUrl])

  useEffect(() => {
    checkError()
  }, [error, checkError])

  useEffect(() => {
    if (fieldErrorMessage) setInputClass(INPUT_CLASS_ERROR)
    else setInputClass(INPUT_CLASS_NORMAL)
  }, [fieldErrorMessage])

  return (
    <>
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-900 flex items-center"
      >
        <span className="flex-1 flex items-center">{label}</span>

        {activeUrl && (
          <a
            href={activeUrl}
            target="_blank"
            className="inline-flex items-center text-xs text-indigo-500"
            rel="noreferrer"
          >
            <HeroIcon name="LinkIcon" className="mr-1 h-3 w-4" />
            Preview
          </a>
        )}
      </label>
      <div className="mt-1 relative rounded-md">
        <input
          style={{
            visibility: 'hidden',
            position: 'absolute',
          }}
          type="file"
          ref={hiddenFileInput}
          onChange={uploadFile}
          disabled={uploading}
        />
        <input
          type="url"
          className={`block w-full sm:text-sm rounded-md ${inputClass} pr-20`}
          defaultValue={activeUrl}
          placeholder={placeholder}
          {...register(name, registerProps)}
        />

        <Button
          text={uploading ? 'Uploading ...' : 'Browse'}
          size="XS"
          style="WHITE"
          className="absolute inset-y-2 right-2 text-xs flex items-center"
          onClick={handleBrowseClick}
        />

        {fieldErrorMessage && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>

      {fieldErrorMessage && !hideErrorMessage && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {fieldErrorMessage}
        </p>
      )}
    </>
  )
}
export { FileInput }
