import { useState, useEffect, ReactElement, useCallback } from 'react'
import { HeroIcon, Tooltip } from '@/components/ui'
import { useFormContext, get, Controller } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'
import {
  UseFormRegister,
  FieldErrors,
  FieldError,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form/dist/types'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'

type TextInputProps<T extends FieldValues> = {
  name: Path<T>
  label: string
  placeholder?: string
  helpText?: string
  innerLabelRight?: any
  innerIconRight?: ReactElement
  type?:
    | 'text'
    | 'number'
    | 'url'
    | 'email'
    | 'textarea'
    | 'textarea-autoresize'
  step?: number
  value?: PathValue<T, Path<T>>
  pattern?: RegExp
  patternErrorMessage?: string
  register: UseFormRegister<T>
  control?: any
  errors: FieldErrors<T>
  required?: boolean
  disabled?: boolean
  minLength?: number
  maxLength?: number
  min?: number
  max?: number
  hideErrorMessage?: boolean
}

const INPUT_CLASS_NORMAL =
  'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
const INPUT_CLASS_ERROR =
  'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'

const TextInput = <T extends FieldValues>({
  name,
  label,
  placeholder = '',
  helpText = '',
  type = 'text',
  value,
  step,
  pattern,
  patternErrorMessage = 'Please enter a valid value',
  innerLabelRight,
  innerIconRight,
  register,
  control,
  errors,
  required,
  disabled,
  minLength,
  maxLength,
  min,
  max,
  hideErrorMessage = false,
}: TextInputProps<T>) => {
  const registerProps: RegisterOptions = {}
  const methods = useFormContext()
  const error: FieldError = get(errors || methods.formState.errors, name)
  const [fieldErrorMessage, setfieldErrorMessage] = useState<string>('')
  const [inputClass, setInputClass] = useState<string>(INPUT_CLASS_NORMAL)

  // Set the registerProps
  if (required) registerProps.required = required
  if (minLength) registerProps.minLength = minLength
  if (maxLength) registerProps.maxLength = maxLength
  if (min) registerProps.min = min
  if (max) registerProps.max = max

  if (pattern) {
    registerProps.pattern = {
      value: pattern,
      message: patternErrorMessage,
    }
  } else if (type === 'email') {
    registerProps.pattern = {
      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: 'Please enter a valid email address',
    }
  } else if (type === 'url') {
    registerProps.pattern = {
      value:
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\w$&+,:;=\-]+@)?[\d.A-Za-z\-]+|(?:www\.|[\w$&+,:;=\-]+@)[\d.A-Za-z\-]+)((?:\/[\w%+./~\-]*)?\??[\w%&+.;=@\-]*#?[\w!./\\]*)?)/,
      message: 'Please enter a valid URL (with either http:// or https://)',
    }
  }

  const checkError = useCallback(() => {
    switch (error?.type) {
      case 'required':
        setfieldErrorMessage('This field is required')
        break
      case 'minLength':
        if (minLength) {
          setfieldErrorMessage(
            `This field should be at-least ${minLength} characters`
          )
        } else {
          setfieldErrorMessage(`This field is not long enough`)
        }
        break
      case 'maxLength':
        if (maxLength) {
          setfieldErrorMessage(
            `This field should be no longer than ${maxLength} characters`
          )
        } else {
          setfieldErrorMessage(`This field is too long`)
        }
        break
      case 'min':
        setfieldErrorMessage(`This should be more than ${min}`)
        break
      case 'max':
        setfieldErrorMessage(`This should be less than ${max}`)
        break
      case 'pattern':
        setfieldErrorMessage(error.message as string)
        break
      default:
        if (error?.message) setfieldErrorMessage(error?.message)
        else {
          setfieldErrorMessage('')
        }
        break
    }
  }, [error, max, min, minLength, maxLength])

  useEffect(() => {
    checkError()
  }, [error, checkError])

  useEffect(() => {
    if (fieldErrorMessage) setInputClass(INPUT_CLASS_ERROR)
    else setInputClass(INPUT_CLASS_NORMAL)
  }, [fieldErrorMessage])

  return (
    <>
      <div className="flex">
        <label
          htmlFor={name}
          className="text-sm font-medium text-gray-900 flex-1"
        >
          {label}
        </label>
        {helpText && (
          <Tooltip text={helpText} position="left" className="sm:col-span-2">
            <HeroIcon
              name="ExclamationCircleIcon"
              className="h-4 w-4 text-indigo-400 hover:text-indigo-700"
            />
          </Tooltip>
        )}
      </div>
      <div className="mt-1 relative rounded-md">
        {type === 'textarea' ? (
          <textarea
            rows={4}
            className={`block w-full sm:text-sm rounded-md ${inputClass} ${
              innerLabelRight ? 'pr-12' : ''
            }`}
            placeholder={placeholder}
            defaultValue={value}
            disabled={disabled}
            {...register(name, registerProps)}
          />
        ) : type === 'textarea-autoresize' ? (
          <Controller
            name={name}
            control={control}
            defaultValue={value}
            rules={{ required: required }}
            render={({ field: { onChange, value: val } }) => (
              <TextareaAutosize
                id="comment"
                name="comment"
                value={val}
                minRows={4}
                className={`block w-full sm:text-sm rounded-md ${inputClass} ${
                  innerLabelRight ? 'pr-12' : ''
                }`}
                placeholder={placeholder}
                disabled={disabled}
                maxRows={15}
                onChange={(e) => {
                  if (onChange) onChange(e)
                }}
              />
            )}
          />
        ) : (
          <input
            type={type}
            className={`block w-full sm:text-sm rounded-md ${inputClass} ${
              innerLabelRight ? 'pr-12' : ''
            }`}
            defaultValue={value}
            step={step ? step : ''}
            placeholder={placeholder}
            disabled={disabled}
            {...register(name, registerProps)}
          />
        )}

        {innerLabelRight && !fieldErrorMessage && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">{innerLabelRight}</span>
          </div>
        )}

        {innerIconRight && !fieldErrorMessage && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            {innerIconRight}
          </div>
        )}

        {fieldErrorMessage && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <HeroIcon
              name="ExclamationCircleIcon"
              className="h-5 w-5 text-red-500"
            />
          </div>
        )}
      </div>
      {fieldErrorMessage && !hideErrorMessage && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {fieldErrorMessage}
        </p>
      )}
    </>
  )
}
export { TextInput }
