import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { AwesomeIcon } from './AwesomeIcon'

type ColouredSectionProps = {
  theme: 'gray-50' | 'gradient-purple-indigo' | 'gradient-indigo'
  title?: string
  titleIcon?: IconDefinition
  children: React.ReactNode
  titleRight?: React.ReactNode
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}
const ColouredSection = ({
  theme,
  title,
  titleIcon,
  children,
  titleRight,
}: ColouredSectionProps) => {
  return (
    <>
      <div
        className={classNames(
          theme === 'gray-50'
            ? 'bg-gray-50'
            : theme === 'gradient-purple-indigo'
            ? 'bg-gradient-to-b from-purple-700 to-indigo-500'
            : theme === 'gradient-indigo'
            ? 'bg-gradient-to-b from-indigo-500 via-indigo-700 to-indigo-900'
            : 'bg-white'
        )}
      >
        <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
          <div className="relative z-10 flex items-center justify-between mb-6">
            {title && (
              <h3
                className={classNames(
                  theme === 'gray-50'
                    ? 'text-gray-800'
                    : theme === 'gradient-purple-indigo' ||
                      theme === 'gradient-indigo'
                    ? 'text-white'
                    : 'text-gray-800',
                  'text-3xl font-extrabold flex items-center'
                )}
              >
                {titleIcon && (
                  <div className="flex items-center justify-center h-12 w-12 mr-4 rounded-full bg-indigo-100">
                    <AwesomeIcon
                      icon={titleIcon}
                      className="h-6 w-6 text-indigo-600"
                    />
                  </div>
                )}

                {title}
              </h3>
            )}

            {titleRight && (
              <div className="flex items-center">{titleRight}</div>
            )}
          </div>
          {children}
        </div>
      </div>
    </>
  )
}

export { ColouredSection }
