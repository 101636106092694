import { request } from '@/utils/http'
import { ApiResponse, ApiRequestParams } from '@/utils/types'
import { Session, SessionUser } from '@/utils/typings/app'
import { BrandItem } from '@/utils/typings/models'
import { industriesByCode, countriesByCode } from '@/utils/misc'
import { IncomingMessage } from 'node:http'

export const BrandItemInputs: string[] = [
  'ownerId',
  'name',
  'slug',
  'url',
  'socialUrlTwitter',
  'socialUrlFacebook',
  'socialUrlLinkedIn',
  'socialUrlInstagram',
  'socialUrlTikTok',
  'description',
  'industry',
  'country',
  'founded',
  'companyType',
  'companySize',
  'logoUrl',
  'logoUrlSquare',
]

class BrandModel {
  item: BrandItem = {} as any

  constructor(item?: Partial<BrandItem>) {
    if (item) this.item = { ...this.item, ...item }
  }

  public get relativeURL() {
    return `/${this.item.slug}`
  }

  public get absoluteURL() {
    return `${process.env.NEXT_PUBLIC_HTTP_PROTOCOL}${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/${this.item.slug}`
  }

  prepareDataForSaving(): ApiRequestParams {
    const params: ApiRequestParams = {}
    BrandItemInputs.forEach((key) => {
      const val = this.item[key as keyof BrandItem]
      if (
        typeof val === 'string' ||
        typeof val === 'number' ||
        typeof val === 'boolean'
      )
        params[key] = val
    })
    return params
  }

  public get logoUrlFull() {
    if (this.item.logoUrl) {
      return `${process.env.NEXT_PUBLIC_SUPABASE_PUBLIC_STORAGE_URL}/brands/${this.item.id}/${this.item.logoUrl}`
    }
    return ''
  }

  public get logoUrlSquareFull() {
    if (this.item.logoUrlSquare) {
      return `${process.env.NEXT_PUBLIC_SUPABASE_PUBLIC_STORAGE_URL}/brands/${this.item.id}/${this.item.logoUrlSquare}`
    }
    return ''
  }

  public get industryName() {
    return industriesByCode[this.item.industry]
  }

  public get countryName() {
    return countriesByCode[this.item.country]
  }

  // Analytics
  async analyticsViewsLog(): Promise<{ views: number }> {
    const res = await request<{ views: number }>({
      url: `/api/analytics/views/log`,
      method: 'POST',
      params: { entity: 'brand', entityId: this.item.id },
    })
    if (res.data) return res.data
    return { views: 0 }
  }
  async analyticsViewsStatus(): Promise<{ views: number }> {
    const res = await request<{ views: number }>({
      url: `/api/analytics/views/status`,
      method: 'GET',
      params: { entity: 'brand', entityId: this.item.id },
    })
    if (res.data) return res.data
    return { views: 0 }
  }

  // Following
  async analyticsFollowLog(
    session: Session,
    unFollow?: boolean
  ): Promise<number | null> {
    if (session.wallet?.address) {
      const res = await request<{ totalFollowers: number }>({
        url: `/api/analytics/follow/log`,
        method: 'POST',
        params: {
          entity: 'brand',
          entityId: this.item.id,
          unFollow: unFollow !== undefined ? unFollow : false,
        },
      })
      if (res.data) return res.data.totalFollowers
    }
    return null
  }

  async analyticsFollowStatus(): Promise<{ following: boolean }> {
    const res = await request<{ following: boolean }>({
      url: `/api/analytics/follow/status`,
      method: 'GET',
      params: { entity: 'brand', entityId: this.item.id },
    })
    if (res.data) return res.data
    return { following: false }
  }

  updateLocalItem(item: Partial<BrandItem>) {
    this.item = { ...this.item, ...item }
  }

  async save(): Promise<BrandModel | null> {
    if (this.item?.id) {
      return this.update()
    }
    return this.create()
  }

  async create(): Promise<BrandModel | null> {
    return BrandModel.returnApiSingle(
      await request({
        url: '/api/brands/create',
        method: 'POST',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async update(): Promise<BrandModel | null> {
    return BrandModel.returnApiSingle(
      await request({
        url: `/api/brands/${this.item.id}/update`,
        method: 'PUT',
        params: this.prepareDataForSaving(),
      })
    )
  }

  async delete(): Promise<ApiResponse<boolean>> {
    return request<boolean>({
      url: `/api/brands/${this.item.id}/delete`,
      method: 'DELETE',
    })
  }

  async updatePage(formData: FormData): Promise<BrandModel | null> {
    return BrandModel.returnApiSingle(
      await request({
        url: `/api/brands/${this.item.id}/page/update`,
        method: 'PUT',
        params: formData,
        useFormData: true,
      })
    )
  }

  // STATIC METHODS
  static returnApiSingle(res: ApiResponse<BrandItem>): BrandModel | null {
    if (!res.error && res.data) {
      const item: BrandItem = res.data
      return new BrandModel(item)
    }
    return null
  }

  static returnApiMany(res: ApiResponse<BrandItem[]>): BrandModel[] {
    if (!res.error && res.data) {
      const listings: BrandItem[] = res.data
      return listings.map((item: BrandItem) => new BrandModel(item))
    }
    return []
  }

  static async getBrand(brandId: string): Promise<BrandModel | null> {
    return BrandModel.returnApiSingle(
      await request<BrandItem>({
        url: `/api/brands/${brandId}`,
        method: 'GET',
        params: {},
      })
    )
  }

  static async getUserBrands(user: SessionUser): Promise<BrandModel[]> {
    if (user) {
      return BrandModel.returnApiMany(
        await request({
          url: '/api/brands/list',
          method: 'GET',
          params: { ownerId: user.id },
        })
      )
    }
    return []
  }

  static async getUserBrandsRaw(
    session: Session,
    req?: IncomingMessage
  ): Promise<BrandItem[]> {
    if (session.user) {
      const res = await request<BrandItem[]>({
        url: '/api/brands/list',
        method: 'GET',
        params: { ownerId: session.user.id },
        req: req,
      })
      if (res.data) return res.data
    }
    return []
  }

  static async getBrandCountries(): Promise<{ country: string }[] | null> {
    const res = await request<{ country: string }[]>({
      url: '/api/brands/countries',
      method: 'GET',
      params: {},
    })
    return res.data
  }

  // Gets the brand by the slug
  static async getBrandBySlug(brandSlug: string): Promise<BrandModel | null> {
    return BrandModel.returnApiSingle(
      await request<BrandItem>({
        url: `/api/brands/by-slug/${brandSlug}`,
        method: 'GET',
        params: {},
      })
    )
  }

  static async getBrandBySlugRaw(brandSlug: string): Promise<BrandItem | null> {
    const res = await request<BrandItem>({
      url: `/api/brands/by-slug/${brandSlug}`,
      method: 'GET',
      params: {},
    })
    if (!res.error && res.data) {
      return res.data
    }
    return null
  }
}
export { BrandModel }
