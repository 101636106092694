import { Fragment, useState, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { useApp } from '@/context'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { AwesomeIcon } from './AwesomeIcon'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const Notification = () => {
  const [show, setShow] = useState(false)
  const { notification } = useApp()

  useEffect(() => {
    if (
      notification &&
      notification.title &&
      (!notification.style || notification.style === 'NOTIFICATION')
    ) {
      setShow(true)
      if (!notification.sticky) {
        setTimeout(() => {
          setShow(false)
        }, 5000)
      }
    } else setShow(false)
  }, [notification])

  const getIcon = () => {
    if (notification.icon === 'SUCCESS') {
      return (
        <AwesomeIcon
          icon={solid('circle-check')}
          className="h-5 w-5 text-green-400"
        />
      )
    }
    if (notification.icon === 'ERROR') {
      return (
        <AwesomeIcon
          icon={solid('circle-exclamation')}
          className="h-5 w-5 text-red-500"
        />
      )
    }
    return (
      <AwesomeIcon
        icon={solid('circle-info')}
        className="h-5 w-5 text-indigo-500"
      />
    )
  }

  return (
    <>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-4 z-50 pointer-events-none sm:p-4 sm:items-end"
      >
        <div
          className={classNames(
            notification.size === 'NORMAL' ? '' : '',
            'flex flex-row items-center space-y-4 sm:items-end'
          )}
        >
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-700 transition"
            enterFrom="translate-y-2 opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={classNames(
                notification.size === 'NORMAL' ? '' : '',
                'bg-white shadow-2xl rounded-lg pointer-events-auto overflow-hidden'
              )}
            >
              <div className="p-2">
                <div className="flex items-center">
                  <div className="flex-shrink-0 flex items-center">
                    {getIcon()}
                  </div>
                  <div
                    className={classNames(
                      notification.size === 'NORMAL' ? '' : '',
                      'ml-1.5 flex-1'
                    )}
                  >
                    <p className="text-sm font-medium text-gray-900">
                      {notification.title}
                    </p>
                    {notification.description && (
                      <p className="mt-1 text-sm text-gray-500">
                        {notification.description}
                      </p>
                    )}
                  </div>
                  {notification.sticky && (
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        type="button"
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => {
                          setShow(false)
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}
export { Notification }
