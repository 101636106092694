import { useState, useEffect } from 'react'
import { SelectInput, SelectChoiceType } from '@/components/form'
import { useApp } from '@/context'

const FIAT_SYMBOLS = process.env.FIAT_SYMBOLS
  ? process.env.FIAT_SYMBOLS.split(',')
  : []
const CRYPTO_SYMBOLS = process.env.CRYPTO_SYMBOLS
  ? process.env.CRYPTO_SYMBOLS.split(',')
  : []

type SymbolSelectorProps = {
  className?: string
  style?: 'normal' | 'muted'
  size?: 'md' | 'sm'
}

const SymbolSelector = ({
  className = '',
  style = 'normal',
  size = 'md',
}: SymbolSelectorProps) => {
  const [symbols, setSymbols] = useState<SelectChoiceType[]>([])
  const { setFxRate } = useApp()

  useEffect(() => {
    const _symbols: SelectChoiceType[] = []

    CRYPTO_SYMBOLS.forEach((symbol) => {
      _symbols.push({ id: symbol, name: symbol })
    })

    FIAT_SYMBOLS.forEach((symbol) => {
      _symbols.push({ id: symbol, name: symbol })
    })

    setSymbols(_symbols)
  }, [])

  const onChange = (item: SelectChoiceType) => {
    setFxRate(item.id.toString())
  }

  return (
    <SelectInput
      name="symbol"
      label=""
      style={style}
      size={size}
      className={className}
      value={CRYPTO_SYMBOLS[0]}
      choices={symbols}
      onValueChange={onChange}
    />
  )
}
export { SymbolSelector }
