type DividerProps = {
  children: React.ReactNode
}

const List = ({ children = '' }: DividerProps) => {
  return (
    <div className="bg-white shadow sm:rounded-md">
      <ul className="divide-y divide-gray-100">{children}</ul>
    </div>
  )
}

export { List }
