type DividerProps = {
  className?: string
  borderClass?: string
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const Divider = ({
  className = '',
  borderClass = 'border-gray-100',
}: DividerProps) => {
  return (
    <div className={classNames(className, 'flex-grow border-t', borderClass)} />
  )
}

export { Divider }
