import Web3Modal from 'web3modal'
import WalletConnect from '@walletconnect/web3-provider'
import { ethers } from 'ethers'
import { getChainData } from './chains'

export const getProviderOptions = () => {
  const infuraId = process.env.NEXT_PUBLIC_INFURA_KEY
  const providerOptions = {
    walletconnect: {
      package: WalletConnect,
      options: {
        infuraId,
      },
    },
  }
  return providerOptions
}

export const getWeb3Modal = (): Web3Modal => {
  const chain = getChainData(process.env.NODE_ENV === 'production' ? 1 : 3)
  return new Web3Modal({
    network: chain.network,
    disableInjectedProvider: true,
    cacheProvider: true,
    providerOptions: getProviderOptions(),
  })
}

export const getProvider = async (
  providerId?: string
): Promise<{
  web3Modal: Web3Modal | null
  provider: ethers.providers.Web3Provider | null
}> => {
  const web3Modal = getWeb3Modal()
  let provider: ethers.providers.JsonRpcFetchFunc | null = null

  if (providerId) {
    provider = await web3Modal.connectTo(providerId)
  } else if (web3Modal.cachedProvider) {
    provider = await web3Modal.connect()
  }

  if (provider) {
    return { web3Modal, provider: new ethers.providers.Web3Provider(provider) }
  }
  return { web3Modal: null, provider: null }
}

export const getWallet = async (
  providerId?: string
): Promise<{
  web3Modal?: Web3Modal
  signer?: ethers.providers.JsonRpcSigner
  provider?: ethers.providers.Web3Provider
}> => {
  try {
    const { web3Modal, provider } = await getProvider(providerId)
    if (provider && web3Modal) {
      const signer = provider.getSigner()
      return { web3Modal, signer, provider }
    }
  } catch (err) {
    console.error(err)
    return {}
  }
  return {}
}

export const getWalletAddress = async (): Promise<string> => {
  const { signer } = await getWallet()
  if (signer) return signer.getAddress()
  return ''
}

export const getWalletBalance = async (): Promise<number> => {
  const { signer } = await getWallet()
  if (signer) return Number(ethers.utils.formatEther(await signer.getBalance()))
  return 0
}

export const walletHasEnoughBalance = async (amountRequired: number) => {
  const balance = await getWalletBalance()
  return balance >= amountRequired
}
